import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import close_icon from "../assets/img/close.svg";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ReactComponent as FilterIcon } from "../assets/img/filter.svg";
import Customdate from "../Customcomponents/Customdate";
import { getTask } from "../api/service";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Outlinebutton from "../Customcomponents/Outlinebutton";
import { taskStatuses } from "../components/utils";
import Custompagenation from "../Customcomponents/Custompagenation";
import TaskHistory from "../salesportal/TaskHistory";
import { ReactComponent as ViewIcon } from "../assets/img/view.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const ProjectTracking = () => {
  const location = useLocation();
  const projectId = location?.state?.projectId;
  const customerFname = location?.state?.customerFname;
  const customerLname = location?.state?.customerLname;

  const navigate = useNavigate();
  const [tableData, setTableData] = useState(null);

  const [selectedTask, setSelectedTask] = useState(null);

  const [showView, setShowView] = useState(false);
  const handleCloseView = () => {
    setShowView(false);
    setSelectedTask(null);
  };
  const handleShowView = () => setShowView(true);

  const [dropdownVisibleIndex, setDropdownVisibleIndex] = useState(-1);
  const dropdownRefs = useRef([]);

  const handleToggleDropdown = (index) => {
    setDropdownVisibleIndex(index === dropdownVisibleIndex ? -1 : index);
  };

  const handleLinkClick = () => {
    setDropdownVisibleIndex(-1);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          if (index === dropdownVisibleIndex) {
            setDropdownVisibleIndex(-1);
          }
        }
      });
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisibleIndex]);

  //filters
  const [updatedFilters, setUpdatedFilters] = useState({
    status: "",
    start_date: "",
    end_date: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function apiCall() {
    if (projectId) {
      getTask({ project: projectId, ...updatedFilters })
        .then((res) => {
          setTableData(res);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      navigate("/customerdashboard");
    }
  }

  useEffect(() => {
    apiCall();
  }, [updatedFilters]);

  function FilterModal() {
    const [filters, setFilters] = useState(updatedFilters);
    function handleFilterChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setFilters({ ...filters, [name]: value });
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Filter</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-4">
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Status</option>
                  {taskStatuses.map((taskStatus) => {
                    return <option value={taskStatus}>{taskStatus}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Starting Date</Form.Label>
                <Customdate
                  name="start_date"
                  value={filters.start_date}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Ending Date</Form.Label>
                <Customdate
                  name="end_date"
                  value={filters.end_date}
                  onChange={handleFilterChange}
                />
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-center align-items-start gap-2">
              <input
                type="button"
                value="Cancel"
                class="outline_btn"
                onClick={handleClose}
              />

              <input
                type="button"
                value="Apply"
                className="signup_button"
                onClick={() => {
                  setUpdatedFilters(filters);
                  handleClose();
                }}
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  function TaskViewModal() {
    const [payload, setPayload] = useState({
      id: selectedTask?.id,
      project: selectedTask?.project,
      assigned_to: selectedTask?.assigned_to,
      title: selectedTask?.title ?? "",
      start_date: selectedTask?.start_date ?? "",
      end_date: selectedTask?.end_date ?? "",
      created_by: selectedTask?.created_by?.id,
    });

    return (
      <Modal
        show={showView}
        onHide={handleCloseView}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">View Task</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleCloseView}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group className="col-lg-6 col-md-12 mb-2">
              <Form.Label className="disabled-label">Project</Form.Label>
              <Form.Select
                className="inputfield"
                name="project"
                disabled={true}
                required
              >
                <option>
                  {`Proj ${payload?.project?.id}_${payload?.project?.customer?.first_name}_${payload?.project?.customer?.last_name}`}
                </option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="col-lg-6 col-md-12 mb-2">
              <Form.Label className={true ? "disabled-label" : ""}>
                Assignee to
              </Form.Label>
              <Form.Select
                className="inputfield"
                name="assigned_to"
                disabled={true}
                required
              >
                <option>{payload?.assigned_to?.username}</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="col-lg-12 col-md-12 mb-2">
              <Form.Label className={true ? "disabled-label" : ""}>
                Task
              </Form.Label>
              <Form.Control
                placeholder="Enter task"
                type="task"
                className="inputfield"
                name="title"
                value={payload.title}
                disabled={true}
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              className="col-lg-6 col-md-12 mb-2"
              controlId="formGridEmail"
            >
              <Form.Label className={true ? "disabled-label" : ""}>
                Start Date
              </Form.Label>
              <Form.Control
                type="date"
                className="inputfield form-control date-control"
                name="start_date"
                value={payload.start_date}
                disabled={true}
                max={payload?.end_date}
                required
              />
            </Form.Group>

            <Form.Group
              className="col-lg-6 col-md-12 mb-2"
              controlId="formGridPassword"
            >
              <Form.Label className={true ? "disabled-label" : ""}>
                End Date
              </Form.Label>
              <Form.Control
                type="date"
                name="end_date"
                value={payload.end_date}
                className="inputfield form-control date-control"
                min={payload?.start_date}
                disabled={true}
                required
              />
            </Form.Group>
          </Row>
          <hr />

          <Row className="mb-4">
            <Form.Group className="col-md-12">
              <div className="table_section">
                <div className="table-responsive">
                  <TaskHistory taskid={payload.id} />
                </div>
              </div>
            </Form.Group>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <ul>
              <li>
                <h6 className="breadcrumb_main_heading">
                  <Link to="/customerdashboard">Projects</Link>
                </h6>
              </li>
              <li>
                <span className="breadcrumb_paragraph"> Project Tracking</span>
              </li>
            </ul>
          </div>
          <div>
            <div className="d-flex justify-content-end list-group-item">
              <div className="d-flex justify-content-end pt-1 pb-1 gap-2 ">
                <div>
                  <Link to="/customerdashboard">
                    <Outlinebutton name="Back" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div>
            <label classname="breadcrumb_description">
              Project Id:{" "}
              <span style={{ fontWeight: "400" }}>{`Proj ${projectId}`}</span>
            </label>
          </div>
          <hr />
          <div class="d-flex flex-wrap align-items-center mb-3 gap-2">
            <button
              className="add_employee_btn d-flex flex-row"
              onClick={handleShow}
            >
              <FilterIcon />
              <span>Add Filter</span>
            </button>
            <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
              {Object.keys(updatedFilters).map((key) => {
                console.log("key is", updatedFilters[key]);
                if (updatedFilters[key] !== "") {
                  return (
                    <div class="chip ms-1 me-1">
                      <div class="chip-content">{updatedFilters[key]}</div>
                      <div
                        class="chip-close"
                        onClick={() => {
                          setUpdatedFilters({ ...updatedFilters, [key]: "" });
                        }}
                      >
                        <svg
                          class="chip-svg"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                        </svg>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">
                      <div class="">
                        <div>Task</div>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="">
                        <div>Starting Date</div>
                      </div>
                    </th>
                    <th scope="col">
                      <div>
                        <div>Ending Date</div>
                      </div>
                    </th>
                    <th scope="col">
                      <div>Status</div>
                    </th>
                    <th scope="col">
                      <div>Assignee</div>
                    </th>
                    <th scope="col">
                      <div>Created By</div>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results.map((row, index) => {
                    return (
                      <tr>
                        <td>{row.title}</td>
                        <td>{row.start_date}</td>
                        <td>{row.end_date}</td>
                        <td>{row.status}</td>
                        <td>{row.assigned_to.username}</td>
                        <td>{row.created_by.username}</td>
                        <td className="text-center">
                          <div
                            ref={(ref) => (dropdownRefs.current[index] = ref)}
                          >
                            <span onClick={() => handleToggleDropdown(index)}>
                              <LightTooltip
                                title="Options"
                                arrow
                                placement="bottom"
                              >
                                <MenuIcon alt="no_img" className="edit_icon" />
                              </LightTooltip>
                            </span>
                            <ul
                              className={`dropdown-menu custom_header  ${
                                dropdownVisibleIndex === index ? "show" : ""
                              }`}
                            >
                              <li
                                style={{ borderBottom: "1px solid #CBCBCB" }}
                                className="pt-2 pb-2"
                                onClick={handleLinkClick}
                              >
                                <button
                                  class="dropdown-item d-flex projects_dropdownlinks"
                                  type="button"
                                  onClick={() => {
                                    setSelectedTask(row);
                                    handleShowView();
                                  }}
                                >
                                  <ViewIcon
                                    style={{ height: "18px", width: "18px" }}
                                  />
                                  <span className="ms-2 d-flex">View</span>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Custompagenation data={tableData} setData={setTableData} />
              <FilterModal />
              <TaskViewModal />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectTracking;
