import React, { useState } from "react";
import delete_icon from "../assets/img/delete.svg";
import edit_icon from "../assets/img/edit.svg";
import Custompagenation from "../Customcomponents/Custompagenation";
import { ReactComponent as DownloadViewIcon } from "../assets/img/downloadviewicon.svg";
import { ReactComponent as CancelIcon } from "../assets/img/cancel.svg";
import sampleview_img from "../assets/img/sampleview.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import close_icon from "../assets/img/close.svg";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
// import Button from "react-bootstrap/Button";
import search_icon from "../assets/img/search.svg";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ReactComponent as ViewIcon } from "../assets/img/edit.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Actualprocurement = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showview, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Actual procurement</h4>
          </div>
        </div>
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div className="table_section">
            <div className="table-responsive-lg">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">
                      <div>Created on</div>
                    </th>
                    <th scope="col">
                      <div>Created by</div>
                    </th>

                    <th scope="col">
                      <div>Quotation Document</div>
                    </th>
                    <th scope="col">
                      <div>Quotation Amount</div>
                    </th>
                    <th scope="col">
                      <div>Customer Response Date</div>
                    </th>
                    <th scope="col">
                      <div>Customer Remarks</div>
                    </th>
                    <th scope="col">
                      <div>Customer Response</div>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>19-02-2024</td>
                    <td>Santosh_ce</td>

                    <td className="active-links">
                      <span onClick={handleShowView} class="footer_link">
                        View
                      </span>
                    </td>

                    <td>11000</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-center">
                      <div class="btn-group">
                        <span data-bs-toggle="dropdown" aria-expanded="false">
                          <LightTooltip
                            title="Options"
                            arrow
                            placement="bottom"
                          >
                            <MenuIcon alt="no_img" className="edit_icon" />
                          </LightTooltip>
                        </span>
                        <ul class="dropdown-menu">
                          <li className="pt-2 pb-2">
                            <Link to="#/">
                              <button
                                class="dropdown-item d-flex projects_dropdownlinks"
                                type="button"
                                onClick={handleShow}
                              >
                                <ViewIcon
                                  style={{ height: "18px", width: "18px" }}
                                />
                                <span className="ms-2 d-flex">Update</span>
                              </button>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">Update Response</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-md-12 col-lg-6 mb-2 disable-state"
                  >
                    <Form.Label>Assigned by</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield"
                      disabled
                    >
                      <option>Sujeet sales</option>
                      <option>one</option>
                      <option>two</option>
                      <option>three</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-md-12 col-lg-6 mb-2 disable-state"
                  >
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="2000"
                      className="inputfield"
                      value="2000"
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group
                    as={Col}
                    controlId="formGridEmail"
                    className="col-md-6 mb-2"
                  >
                    <Form.Label>Response</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield"
                    >
                      <option>Accept</option>
                      <option>Reject</option>
                      <option>Re-assign</option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} className="col-md-12">
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      className="inputfield"
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group
                    as={Col}
                    className="col-md-12 pb-3"
                    style={{ lineHeight: "16px" }}
                  >
                    <Form.Label>Quotation from Civil Team</Form.Label>

                    <div className="active-links">
                      <label
                        for="file-upload"
                        class="footer_link d-flex flex-row active-links"
                        style={{ cursor: "pointer" }}
                        onClick={handleShowView}
                      >
                        <a>View Document</a>
                      </label>
                    </div>
                  </Form.Group>
                </Row>
                <hr />

                <div className="d-flex justify-content-start align-items-start gap-2">
                  <input
                    type="button"
                    value="Cancel"
                    className="outline_btn"
                    onClick={handleClose}
                  ></input>
                  <input
                    type="button"
                    value="Submit"
                    className="signup_button"
                    onClick={handleClose}
                  ></input>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={showview}
            onHide={handleCloseView}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
          >
            <Modal.Header>
              <h6 className="view_header mt-2">image.png</h6>
              <span onClick={handleCloseView}>
                <DownloadViewIcon className="ms-4 download_icon" />
              </span>
              <CancelIcon
                onClick={handleCloseView}
                style={{ cursor: "pointer" }}
                className="close_icon"
              />
            </Modal.Header>
            <Modal.Body
              className="d-flex justify-content-center align-items-center pb-5"
              style={{ background: "rgba(241, 241, 241, 1)" }}
            >
              <img
                src={sampleview_img}
                alt="no_img"
                style={{ width: "60%", maxWidth: "100%" }}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Actualprocurement;
