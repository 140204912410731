import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Backicon } from "../assets/img/backbutton.svg";
import { postCheck_validity, postReset_password } from "../api/service";
import { toast } from "react-toastify";
import { isPasswordValid } from "../components/utils";

const Setpassword = () => {
  const params = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (params.token !== "") {
      postCheck_validity({ token: params.token })
        .then((res) => {
          toast.success(res);
        })
        .catch((err) => {
          // toast.error(err.message);
          navigate("/invalidtoken");
        });
    } else {
    }
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    if (password === "") {
      toast.error("Please enter the password");
      return;
    } else if (!isPasswordValid(password)) {
      toast.error(
        "Password should have 8 characters at least 1 Capital letter, 1 Small letter, 1 number and 1 symbol"
      );
      return;
    } else if (confirmPassword === "") {
      toast.error("Please enter confirm password");
      return;
    } else if (!isPasswordValid(confirmPassword)) {
      toast.error(
        "Confirm Password should have 8 characters at least 1 Capital letter, 1 Small letter, 1 number and 1 symbol"
      );
      return;
    } else if (password !== confirmPassword) {
      toast.error("Password and confirm password didn't match");
      return;
    } else {
      postReset_password({
        token: params.token,
        password: password,
      })
        .then((res) => {
          toast.success(res);
          navigate("/");
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  return (
    <React.Fragment>
      <div class="login_section">
        <div class="content">
          <div id="form-main-container">
            <div id="form-area">
              <h4 class="headingfour mb-3 text-center text-white">
                Set New Password
              </h4>
              <p className="paragraph_text mb-4">Minimum 8 characters</p>
              <div id="form-body">
                <Form onSubmit={handleClick}>
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <span className="text-white login_label_text">
                      Password
                    </span>

                    <div style={{ position: "relative" }}>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        value={password}
                        className="login_formcontrol mt-1"
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ paddingRight: "40px" }}
                        required
                      />

                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: "3%",
                          cursor: "pointer",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <VisibilityIcon
                            style={{ fontSize: "20px", color: "#C8C8C8" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            style={{ fontSize: "20px", color: "#C8C8C8" }}
                          />
                        )}
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <span className="text-white login_label_text">
                      Confirm Password
                    </span>

                    <div style={{ position: "relative" }}>
                      <Form.Control
                        className="login_formcontrol mt-1"
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        style={{ paddingRight: "40px" }}
                        required
                      />

                      <div
                        className="loginpassword_field"
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: "3%",
                          cursor: "pointer",
                        }}
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? (
                          <VisibilityIcon
                            style={{ fontSize: "20px", color: "#C8C8C8" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            style={{ fontSize: "20px", color: "#C8C8C8" }}
                          />
                        )}
                      </div>
                    </div>
                  </Form.Group>

                  <div className="d-flex justify-content-center mt-5 mb-3">
                    <input
                      type="submit"
                      value="Reset Password"
                      className="signup_button"
                    />
                  </div>
                  <Form.Group
                    className="mb-4 text-center text-white"
                    controlId="formBasicCheckbox"
                  >
                    <Link to="/" className="label_text forgot_password_text">
                      <Backicon className="me-2" />
                      Back to Login
                    </Link>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Setpassword;
