import React, { useEffect, useState } from "react";
import { getSupplier_estimate_pay_history } from "../api/service";
import { toast } from "react-toastify";
import { conDateToReadable } from "../components/utils";
import DocumentViewModal from "../Customcomponents/DocumentViewModal";
export default function PriceEstimatationHistory({ estimationId }) {
  const [paymentsHistory, setPaymentsHistory] = useState(null);

  const [showView, setShowView] = useState(false);
  const [selPriceEstimation, setSelPriceEstimation] = useState(null);
  useEffect(() => {
    getSupplier_estimate_pay_history({ estimation: estimationId })
      .then((res) => {
        setPaymentsHistory(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);
  return (
    <>
      <table className="table table-bordered align-middle">
        <thead className="table_head_bg">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Amount paid</th>

            <th scope="col">Payment type</th>
            <th scope="col">Description</th>
            <th scope="col">Image</th>
          </tr>
        </thead>
        <tbody>
          {paymentsHistory?.results.map((paymentHistory) => {
            return (
              <tr>
                <td>{conDateToReadable(paymentHistory.created_at)}</td>
                <td>{paymentHistory.amt_paid}</td>
                <td>{paymentHistory.payment_type}</td>
                <td>{paymentHistory.description}</td>

                <td class="active-links">
                  <span
                    class="footer_link"
                    onClick={() => {
                      setSelPriceEstimation(paymentHistory);
                      setShowView(true);
                    }}
                  >
                    View
                  </span>
                </td>
              </tr>
            );
          })}
          {paymentsHistory?.count === 0 && (
            <tr>
              <td colSpan={5} style={{ textAlign: "center" }}>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <DocumentViewModal
        show={showView}
        onHide={() => {
          setShowView(false);
          setSelPriceEstimation(null);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="custom-modal-xl"
        handleCloseView={() => {
          setShowView(false);
          setSelPriceEstimation(null);
        }}
        links={selPriceEstimation?.docs}
      />
    </>
  );
}
