import React, { useState } from "react";
import search_icon from "../assets/img/search.svg";
import another_icon from "../assets/img/view.svg"; // Import another icon
import Form from "react-bootstrap/Form";

const Customsearch = ({ searchValue, setSearchValue }) => {
  const handleInputChange = (e) => {
    if (setSearchValue) {
      setSearchValue(e.target.value);
    }
  };

  const handleSearchClick = () => {
    // Handle the search click action, e.g., navigate to search results
  };

  return (
    <React.Fragment>
      <Form.Group controlId="formGridCity">
        <div
          style={{
            position: "relative",
            cursor: "pointer", // Set cursor to pointer for the entire field
          }}
          onClick={handleSearchClick}
        >
          <Form.Control
            placeholder="Search by keywords"
            type="text"
            className="inputfield"
            style={{ borderRadius: "50px", paddingLeft: "75px" }}
            value={searchValue}
            onChange={handleInputChange}
          />
          <div
            style={{
              position: "absolute",
              top: "47%",
              transform: "translateY(-50%)",
              left: "5%",
              paddingLeft: "32px",
            }}
          >
            {/* Change the icon based on the searchValue */}
            <img
              src={search_icon}
              alt="icon"
              style={{ cursor: "pointer" }} // Set cursor to pointer for the icon
            />
          </div>
        </div>
      </Form.Group>
    </React.Fragment>
  );
};

export default Customsearch;
