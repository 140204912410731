import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";
import Customdate from "../Customcomponents/Customdate";
import CustomPagenation from "../Customcomponents/Custompagenation";
import Outlinebutton from "../Customcomponents/Outlinebutton";
import { getProject_payment } from "../api/service";
import close_icon from "../assets/img/close.svg";
import { ReactComponent as FilterIcon } from "../assets/img/filter.svg";
import { paymentCategories, paymentMethods } from "../components/utils";

const PaymentDetails = () => {
  const location = useLocation();
  const projectId = location?.state?.projectId;
  const customerFname = location?.state?.customerFname;
  const customerLname = location?.state?.customerLname;

  const [tableData, setTableData] = useState(null);

  //filters
  const [updatedFilters, setUpdatedFilters] = useState({
    payment_date: "",
    category: "",
    payment__mode: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function apiCall() {
    getProject_payment({
      active: "True",
      project: projectId,
      ...updatedFilters,
    })
      .then((res) => {
        setTableData(res);
        console.log("response is", res);
      })
      .catch((err) => {
        console.log("err", err.message);
      });
  }
  useEffect(() => {
    apiCall();
  }, [updatedFilters]);
  function FilterModal() {
    const [filters, setFilters] = useState(updatedFilters);
    function handleFilterChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setFilters({ ...filters, [name]: value });
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Filter</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-4">
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Payment Date</Form.Label>
                <Customdate
                  name="payment_date"
                  value={filters.payment_date}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="category"
                  value={filters.category}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Category</option>
                  {paymentCategories.map((paymentCategory) => {
                    return (
                      <option value={paymentCategory}>{paymentCategory}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Payment Type</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="payment__mode"
                  value={filters.payment__mode}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select payment type</option>
                  {paymentMethods.map((paymentMethod) => {
                    return (
                      <option value={paymentMethod}>{paymentMethod}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-center align-items-start gap-2">
              <input
                type="button"
                value="Cancel"
                class="outline_btn"
                onClick={handleClose}
              />

              <input
                type="button"
                value="Apply"
                className="signup_button"
                onClick={() => {
                  setUpdatedFilters(filters);
                  handleClose();
                }}
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <React.Fragment>
      <div className="banner-section">
      <div className="d-flex justify-content-between align-items-center">
          <div>
            <ul>
              <li>
                <h6 className="breadcrumb_main_heading">
                  <Link to="/customerdashboard">Projects</Link>
                </h6>
              </li>
              <li>
                <span className="breadcrumb_paragraph">   Payment Details</span>
              </li>
            </ul>
          </div>
          <div>
            <div className="d-flex justify-content-end list-group-item">
              <div className="d-flex justify-content-end pt-1 pb-1 gap-2 ">
                <div>
                <Link to="/customerdashboard">
              <Outlinebutton name="Back" />
            </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="d-flex justify-content-between align-items-center">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item headingfour">
                  <Link to="/customerdashboard">{`Proj ${projectId}_${customerFname}_${customerLname}`}</Link>
                </li>
                <li
                  class="breadcrumb-item active m-auto"
                  aria-current="page"
                  style={{ fontSize: "18px" }}
                >
                  Payment Details
                </li>
              </ol>
            </nav>
          </div>
          <Link to="/customerdashboard">
            <Outlinebutton name="Back" />
          </Link>
        </div> */}
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">

        <div>
            <label classname="breadcrumb_description">
              Project Id:{" "}
              <span style={{ fontWeight: "400" }}>{`Proj ${projectId}`}</span>
            </label>
          </div>
          <hr/>
          <div className="d-flex flex-wrap align-items-center mb-3 gap-2">
            <button
              className="add_employee_btn d-flex flex-row"
              onClick={handleShow}
            >
              <FilterIcon />
              <span>Add Filter</span>
            </button>
            <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
              {Object.keys(updatedFilters).map((key) => {
                if (key !== "active" && updatedFilters[key] !== "")
                  return (
                    <div class="chip ms-1 me-1">
                      <div class="chip-content">{updatedFilters[key]}</div>
                      <div
                        class="chip-close"
                        onClick={() => {
                          setUpdatedFilters({ ...updatedFilters, [key]: "" });
                        }}
                      >
                        <svg
                          class="chip-svg"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                        </svg>
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">
                      <div class="">
                        <div>Date</div>
                      </div>
                    </th>

                    <th scope="col">
                      <div>
                        <div>Category</div>
                      </div>
                    </th>
                    <th scope="col">
                      <div>Amount</div>
                    </th>
                    <th scope="col">
                      <div>Payment type</div>
                    </th>
                    <th scope="col">
                      <div>Details</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results.map((row) => {
                    return (
                      <tr>
                        <td>{row.payment_date}</td>
                        <td>{row.category}</td>
                        <td>{row.payment.amount}</td>
                        <td>{row.payment.mode}</td>
                        <td>{row.payment.comments}</td>
                      </tr>
                    );
                  })}

                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={10} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <CustomPagenation data={tableData} setData={setTableData} />
            </div>
          </div>
          <FilterModal />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentDetails;
