import React, { useState } from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import CloseIcon from "../assets/img/close.svg";
import { endPointExport_as } from "../api/service";

export default function ExportAsModal({
  showExport,
  handleCloseExport,
  url,
  fileName,
}) {
  const token = localStorage.getItem("token");
  const exportMethods = ["excel", "csv"];
  const [payload, setPayload] = useState({
    url: url,
    format: "",
  });
  function handleInputChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    setPayload({ ...payload, [name]: value });
  }
  function handleFormSubmit(event) {
    event.preventDefault();
    fetch(endPointExport_as, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { Authorization: token, "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blobData) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blobData);
        a.download = fileName;
        a.click();
        handleCloseExport();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  return (
    <Modal
      show={showExport}
      onHide={handleCloseExport}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h1 class="headingfour">Export As</h1>
        <img
          src={CloseIcon}
          alt="close"
          onClick={handleCloseExport}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Row className="mb-4">
            <Col>
              <Form.Label>Format</Form.Label>
              <Form.Select
                className="inputfield"
                required
                name="format"
                onChange={handleInputChange}
              >
                <option value="" hidden>
                  Select the export format
                </option>
                {exportMethods.map((exportMethod) => {
                  return <option value={exportMethod}>{exportMethod}</option>;
                })}
              </Form.Select>
            </Col>
          </Row>
          <hr />
          <Row>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <button class="add_employee_btn" style={{ width: "120px" }}>
                Create
              </button>
            </div>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
