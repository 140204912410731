import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import Custompagenation from "../Customcomponents/Custompagenation";
import { ReactComponent as Activeicon } from "../assets//img/active.svg";
import close_icon from "../assets/img/close.svg";
import deactiveicon, {
  ReactComponent as Deactiveicon,
} from "../assets/img/deactive.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import Custombuttons from "../Customcomponents/Custombuttons";
import Customsearch from "../Customcomponents/Customsearch";
import { ReactComponent as Add } from "../assets/img/add.svg";

import {
  createUpdateUserProfile,
  deleteUsersProfile,
  getProfile,
} from "../api/service";
import { headAndTeamMembers, isPasswordValid } from "../components/utils";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));
const Teammembers = () => {
  const currentRole = localStorage.getItem("role");
  const showAddNewMember =
    Object.keys(headAndTeamMembers).includes(currentRole);
  const teamMember = headAndTeamMembers[currentRole] ?? currentRole;

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [showview, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  const [showassign, setShowAssign] = useState(false);
  const handleCloseAssign = () => setShowAssign(false);
  const handleShowAssign = () => setShowAssign(true);

  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [tableData, setTableData] = useState({});

  const [username, setUsername] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [addUserHeading, setaddUserHeading] = useState("Add New Employee");
  const [userId, setUserId] = useState("");
  const [profileId, setProfileId] = useState(null);

  const [isUpdateAPI, setIsUpdateAPI] = useState(false);
  const [active, setActive] = useState("True");
  const [selectedUser, setSelectedUser] = useState(null);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const deleteButtonRef = useRef(null); // Reference to the delete button

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      deleteButtonRef.current.click(); // Trigger click event on the delete button
    }
  };

  useEffect(() => {
    if (deleteshow) {
      document.addEventListener("keydown", handleKeyPress);
      // Set focus on the delete button when the modal is shown
      deleteButtonRef.current.focus();
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [deleteshow]); // Run effect when deleteshow changes

  const EmployeeActions = ({ data }) => {
    const updateData = () => {
      setFirstname(data?.user?.first_name);
      setLastname(data?.user?.last_name);
      setUsername(data?.user?.username);
      setEmail(data?.user?.email);
      setMobile(data?.mobile_no);
      setProfileId(data?.id);
      setUserId(data?.user.id);
      setEmployeeId(data.employee_id);
      setaddUserHeading("Edit Employee");
      setIsUpdateAPI(true);
      handleShow();
    };
    return (
      <div className="btn-group dropend">
        <span data-bs-toggle="dropdown" aria-expanded="false">
          <LightTooltip title="Options" arrow placement="bottom">
            <MenuIcon alt="no_img" className="edit_icon" />
          </LightTooltip>
        </span>
        <ul className="dropdown-menu">
          <li
            style={{ borderBottom: "1px solid #CBCBCB" }}
            className="pt-2 pb-2"
          >
            <Link to="#/">
              <button
                className="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                type="button"
                onClick={updateData}
              >
                <EditIcon style={{ height: "16px", width: "16px" }} />
                <span className="ms-2 ">Edit</span>
              </button>
            </Link>
          </li>
          <li className="pt-2 pb-2">
            <div>
              <button
                className="dropdown-item d-flex projects_dropdownlinks"
                type="button"
                onClick={(event) => {
                  if (data.active) {
                    updateCurrentUser(data);
                    handleDeleteShow();
                  } else {
                    deleteUser(data);
                  }
                }}
              >
                {data.active ? (
                  <Deactiveicon style={{ height: "18px", width: "18px" }} />
                ) : (
                  <Activeicon style={{ height: "18px", width: "18px" }} />
                )}
                <span className="ms-2 d-flex">
                  {data.active ? "Deactivate" : "Activate"}
                </span>
              </button>
            </div>
          </li>
          {/* <li className="pt-2 pb-2">
            <Link to="#/">
              <button
                className="dropdown-item d-flex projects_dropdownlinks"
                type="button"
                onClick={handleShowView}
              >
                <ViewIcon style={{ height: "18px", width: "18px" }} />
                <span className="ms-2 d-flex">View</span>
              </button>
            </Link>
          </li> */}
        </ul>
      </div>
    );
  };

  const handleClose = () => {
    setShow(false);
    setaddUserHeading("Add Employee");
    setFirstname("");
    setLastname("");
    setUsername("");
    setEmail("");
    setMobile("");
    setPassword("");
    setConfirmPassword("");
    setEmployeeId("");
    setIsUpdateAPI(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (username === "") {
      toast.error("Email is required");
      return;
    } else if (firstname === "" || lastname === "") {
      toast.error("Firstname and Lastname are required");
      return;
    } else if (mobile === "") {
      toast.error("mobile number is required");
      return;
    } else if (
      isUpdateAPI == false &&
      (password === "" || confirmPassword === "")
    ) {
      toast.error("Password and confirm password are required");
      return;
    } else if (isUpdateAPI === false && !isPasswordValid(password)) {
      toast.error(
        "Password should have 8 characters at least 1 Capital letter, 1 Small letter, 1 number and 1 symbol"
      );
      return;
    } else if (isUpdateAPI === false && !isPasswordValid(confirmPassword)) {
      toast.error(
        "Confirm should have 8 characters at least 1 Capital letter, 1 Small letter, 1 number and 1 symbol"
      );
      return;
    } else if (isUpdateAPI == false && password !== confirmPassword) {
      toast.error("Password and Confirm Password didn't match");
      return;
    } else if (employeeId === "") {
      toast.error("Emp ID is required");
      return;
    }
    const formData = {
      username,
      firstname,
      lastname,
      email,
      mobile,
      password,
      confirmPassword,
      userId,
      profileId,
      employeeId,
      role: teamMember,
    };

    if (!isFormSubmitted) {
      setIsFormSubmitted(true);
      createUpdateUserProfile(formData, isUpdateAPI)
        .then((res) => {
          const api_type = isUpdateAPI ? "Updated" : "Created";
          toast.success(`${api_type} User Successfully`);
          setData();
          handleClose();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsFormSubmitted(false);
        });
    }
  };

  const deleteUser = (data) => {
    const payload = {
      id: data?.id,
      role: data?.role,
      user: data?.user?.id,
      active: !data.active,
    };
    deleteUsersProfile(payload)
      .then((res) => {
        setData();
        handleDeleteClose();
        toast.success(
          `${data.user.username} is ${
            data.active ? "deactivated" : "activated"
          }`
        );
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const setData = () => {
    getProfile({
      active: active,
      role: teamMember,
      search: searchValue,
    }).then((res) => {
      setTableData({ ...res });
    });
  };
  useEffect(() => {
    setData();
  }, [active, searchValue]);

  function updateCurrentUser(data) {
    setSelectedUser(data);
  }

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchClick = () => {
    // Handle the search click action, e.g., navigate to search results
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Team Members</h4>
          </div>
          <div>
            {showAddNewMember && (
              <Custombuttons
                label={"Add New Member"}
                buttonIcon={Add}
                className="add_employee_btn"
                onClick={handleShow}
              />
            )}
          </div>
        </div>
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div className="d-flex justify-content-between align-items-center mb-3 gap-2 employee_buttons">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
            <div>
              <div className="tabs">
                <label className="tab">
                  <input
                    type="radio"
                    name="tab-input"
                    className="tab-input"
                    checked={active === "True"}
                    onChange={() => setActive("True")}
                  />
                  <div className="tab-box">Active</div>
                </label>
                <div className="line"></div>
                <label className="tab">
                  <input
                    type="radio"
                    name="tab-input"
                    className="tab-input"
                    checked={active === "False"}
                    onChange={() => setActive("False")}
                  />
                  <div className="tab-box">Inactive</div>
                </label>
              </div>
            </div>
          </div>
          <div className="table_section">
            <Table bordered responsive="lg">
              <thead className="table_head_bg">
                <tr>
                  <th scope="col">Emp ID</th>
                  <th scope="col">Employee Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Role</th>
                  <th scope="col">Status</th>
                  {showAddNewMember && <th scope="col"></th>}
                </tr>
              </thead>
              <tbody>
                {tableData.results?.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td>{user.employee_id}</td>
                      <td>
                        {user.user.first_name} {user.user.last_name}
                      </td>
                      <td>{user.user.username}</td>
                      <td>{user.mobile_no}</td>
                      <td>{user.role}</td>
                      <td className="text-center">
                        {user.active ? "Active" : "Inactive"}
                      </td>
                      {showAddNewMember && (
                        <td className="text-center">
                          <EmployeeActions data={user} />
                        </td>
                      )}
                    </tr>
                  );
                })}
                {tableData?.count === 0 && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <Custompagenation setData={setTableData} data={tableData} />
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 className="headingfour">
                {isUpdateAPI ? "Edit" : "Add"} Team Member
              </h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleFormSubmit} autoComplete="off">
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Emp ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter employee ID"
                      className="inputfield"
                      value={employeeId}
                      onChange={(e) => {
                        setEmployeeId(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Firstname</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter first name"
                      className="inputfield"
                      value={firstname}
                      onChange={(e) => {
                        setFirstname(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>Lastname</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter last name"
                      className="inputfield"
                      value={lastname}
                      onChange={(e) => {
                        setLastname(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  {" "}
                  <Form.Group
                    as={Col}
                    className="mb-2"
                    controlId="formGridAddress1"
                  >
                    <Form.Label className={isUpdateAPI ? "disabled-label" : ""}>
                      Email
                    </Form.Label>
                    <Form.Control
                      placeholder="Enter email id"
                      type="email"
                      className="inputfield"
                      value={username}
                      disabled={isUpdateAPI}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                      autoComplete="new-email"
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    className="mb-2"
                    controlId="formGridAddress2"
                  >
                    <Form.Label>Mobile no.</Form.Label>
                    <Form.Control
                      placeholder="Enter mobile number"
                      type="tel"
                      className="inputfield"
                      value={mobile}
                      maxLength={10}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const regex = /^[0-9]*$/;
                        if (regex.test(inputValue) || inputValue === "") {
                          setMobile(inputValue);
                        }
                      }}
                      onKeyDown={(e) => {
                        const allowedChars = /[0-9]/;
                        const allowedKeyCombinations = {
                          "Control+c": true,
                          "Control+v": true,
                          "Control+a": true,
                        };

                        // Allow Backspace and Tab keys without restrictions
                        if (
                          e.key === "Backspace" ||
                          e.key === "Tab" ||
                          e.key === "ArrowLeft" ||
                          e.key === "ArrowRight" ||
                          e.key === "Escape"
                        ) {
                          return;
                        }

                        if (
                          !(
                            allowedChars.test(e.key) ||
                            allowedKeyCombinations[
                              `${
                                e.ctrlKey ? "Control+" : ""
                              }${e.key.toLowerCase()}`
                            ]
                          )
                        ) {
                          e.preventDefault(); // Prevent input if key is not allowed
                        }
                      }}
                      onPaste={(e) => {
                        const pastedText = e.clipboardData
                          .getData("text/plain")
                          .replace(/\D/g, ""); // Remove non-numeric characters
                      }}
                      required
                      pattern="[0-9]{10}"
                    />
                  </Form.Group>
                </Row>

                {!isUpdateAPI && (
                  <Row className="mb-2">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Password</Form.Label>

                      <div style={{ position: "relative" }}>
                        <Form.Control
                          placeholder="Enter password"
                          type={showPassword ? "text" : "password"}
                          className="inputfield"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          style={{ paddingRight: "40px" }}
                          autoComplete="new-password"
                          required
                        />

                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: "3%",
                            cursor: "pointer",
                          }}
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <VisibilityIcon
                              className="icon"
                              style={{ fontSize: "20px", color: "#1F1F1F" }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              className="icon"
                              style={{ fontSize: "20px", color: "#1F1F1F" }}
                            />
                          )}
                        </div>
                      </div>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>Confirm Password</Form.Label>
                      <div style={{ position: "relative" }}>
                        <Form.Control
                          placeholder="Re-enter Password"
                          className="inputfield"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          style={{ paddingRight: "40px" }}
                          required
                        />

                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: "3%",
                            cursor: "pointer",
                          }}
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ? (
                            <VisibilityIcon
                              className="icon"
                              style={{ fontSize: "20px", color: "#1F1F1F" }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              className="icon"
                              style={{ fontSize: "20px", color: "#1F1F1F" }}
                            />
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </Row>
                )}
                <hr />

                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="add_employee_btn"
                    style={{ width: "120px" }}
                  >
                    {isUpdateAPI ? "Update" : "Create"}
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={showassign}
            onHide={handleCloseAssign}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 className="headingfour">Assign Projects</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleCloseAssign}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Form.Group
                    className="col-lg-12 col-md-12 mb-2"
                    controlId="formGridEmail"
                  >
                    <Form.Label>Project</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield"
                    >
                      <option>Select Projects</option>
                      <option>one</option>
                      <option>two</option>
                      <option>three</option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <hr />

                <div className="d-flex justify-content-start align-items-center gap-2 pt-2">
                  <input
                    type="button"
                    value="Cancel"
                    className="outline_btn"
                    onClick={handleCloseAssign}
                  ></input>
                  <input
                    type="button"
                    value="Submit"
                    className="signup_button"
                    onClick={handleCloseAssign}
                  ></input>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={deleteshow}
            onHide={handleDeleteClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="deletebody">
              <div>
                <div className="">
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <div className="media d-flex">
                        <img
                          className="align-self-start mr-3"
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          src={deactiveicon}
                          alt="no_img"
                        />
                        <div
                          className="media-body ms-3"
                          style={{
                            position: "relative",
                          }}
                        >
                          <h5 className="mt-0 alerts-heading text-start">
                            Are you sure?
                          </h5>
                          <p className="text-start">
                            Do you really want to deactivate these record ?
                          </p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-end">
                      <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                        <input
                          type="button"
                          value="Cancel"
                          className="outline_btn"
                          onClick={handleDeleteClose}
                        ></input>
                        <input
                          type="button"
                          value="Deactivate"
                          className="delete_button"
                          ref={deleteButtonRef}
                          onClick={() => {
                            deleteUser(selectedUser);
                          }}
                        ></input>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Teammembers;
