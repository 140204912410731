import AddIcon from "@mui/icons-material/Add";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Custombuttons from "../Customcomponents/Custombuttons";
import Customdate from "../Customcomponents/Customdate";
import Custompagenation from "../Customcomponents/Custompagenation";
import DocumentViewModal from "../Customcomponents/DocumentViewModal";
import Uploadbutton from "../Customcomponents/Uploadbutton";
import {
  getProfile,
  getProject,
  getProject_payment,
  getSubCategories,
  postDrivem,
  postProject_payment,
  postPayments,
} from "../api/service";
import { ReactComponent as Add } from "../assets/img/add.svg";
import close_icon from "../assets/img/close.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as FilterIcon } from "../assets/img/filter.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import {
  conDateToReadable,
  paymentMethods,
  paymentTypes,
  paymentCategories,
  getIds,
} from "../components/utils";
import CustomFileSelector from "../Customcomponents/CustomFileSelector";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Incomeandexpenses = () => {
  const [record, setRecord] = useState(false);
  const [showedit, setShowEdit] = useState(false);

  const [showDocView, setShowDocView] = useState(false);
  const handleCloseView = () => setShowDocView(false);
  const handleFilterShowDocView = () => setShowDocView(true);

  const [showFilter, setShowFilter] = useState(false);
  const [updatedFilters, setUpdatedFilters] = useState({
    payment__category: "",
    project: "",
    payment_date: "",
    category: "",
    payment__created_by: "",
    payment__receiver: "",
    payment__mode: "",
  });
  const handleCloseFilter = () => setShowFilter(false);
  const handleFilterShow = () => setShowFilter(true);

  const handleCloseRecord = () => {
    setRecord(false);
    setIsEditing(false);
    setSelectedPettyCash(null);
  };
  const handleShowRecord = () => setRecord(true);

  const currentUserId = localStorage.getItem("userid");
  const [categories, setCategories] = useState(null);

  const [tableData, setTableData] = useState(null);
  const [selectedPettyCash, setSelectedPettyCash] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  function getUserName(userId) {
    for (let index = 0; index < users.length; index++) {
      if (users[index].user.id == userId) {
        return `${users[index].user?.username}`;
      }
    }
    return users.find((user) => user.id === userId);
  }

  //filters
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);

  function apiCall() {
    getProject_payment({
      active: "True",
      payment__type: "petty-cash",
      ...updatedFilters,
    }).then((res) => {
      setTableData(res);
    });
  }

  function getProjectsAndUsers() {
    let projectPrm = getProject({ active: "True", pagination: false });
    let usersPrm = getProfile({ active: "True", pagination: false });
    Promise.all([projectPrm, usersPrm])
      .then(([projectRes, usersRes]) => {
        setProjects(projectRes);
        setUsers(usersRes);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function getCategoryAndSubCategory() {
    getSubCategories({ category__name: "SubContractor", name: "Estimates" })
      .then((res) => {
        if (res?.results.length > 0) {
          setCategories(res.results[0]);
        } else {
          toast.error("Add SubContractor and Estimates in admin panel");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  useEffect(() => {
    getCategoryAndSubCategory();
    getProjectsAndUsers();
  }, []);
  useEffect(() => {
    apiCall();
  }, [updatedFilters]);

  function AddEditPettyCashModal() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [prevDocuments, setPrevDocuments] = useState([
      ...(selectedPettyCash?.docs ?? []),
    ]);
    const [documents, setDocuments] = useState([]);

    const [paymentPayload, setPaymentPayload] = useState({
      id: selectedPettyCash?.payment.id,
      type: "petty-cash",
      category: selectedPettyCash?.payment?.category ?? "",
      mode: selectedPettyCash?.payment?.mode ?? "",
      comments: selectedPettyCash?.payment?.comments ?? "",
      amount: selectedPettyCash?.payment?.amount ?? "",
      receiver: selectedPettyCash?.payment?.receiver?.id ?? "",
      created_by: selectedPettyCash?.payment?.created_by?.id ?? currentUserId,
      updated_by: selectedPettyCash?.payment?.updated_by?.id ?? currentUserId,
    });
    const [projectPaymentPayload, setProjectPaymentPayload] = useState({
      id: selectedPettyCash?.id,
      project: selectedPettyCash?.project_id ?? "",
      category: selectedPettyCash?.category ?? "",
      created_by: currentUserId,
      updated_by: currentUserId,
      payment_date:
        selectedPettyCash?.payment_date ??
        conDateToReadable(
          selectedPettyCash?.created_at ?? new Date(),
          false,
          "-",
          false
        ),
    });

    function handlePaymentPayloadChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPaymentPayload({ ...paymentPayload, [name]: value });
    }
    function handleProjectPaymentPayloadChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setProjectPaymentPayload({ ...projectPaymentPayload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (prevDocuments.length === 0 && documents.length === 0) {
        toast.error("Please select files");
        return;
      }
      if (!isFormSubmitted) {
        setIsFormSubmitted(true);
        let documentsPrms = [];
        for (let index = 0; index < documents?.length; index++) {
          let form = new FormData();
          form.append("file", documents[index]);
          form.append("categories", categories.category?.id);
          form.append("subcategories", categories?.id);
          documentsPrms.push(postDrivem(form));
        }
        Promise.all(documentsPrms).then((documentsRes) => {
          let docIds = documentsRes.map((documentRes) => {
            return documentRes.id;
          });
          let prevDocIds = getIds(prevDocuments);
          postPayments(paymentPayload)
            .then((res) => {
              postProject_payment({
                ...projectPaymentPayload,
                payment: res.id,
                docs: [...docIds, ...prevDocIds],
              })
                .then((res) => {
                  toast.success(
                    `Petty cash is ${isEditing ? "updated" : "added"}`
                  );
                  handleCloseRecord();
                  apiCall();
                })
                .catch((err) => {
                  toast.error(err.message);
                })
                .finally(() => {
                  setIsFormSubmitted(false);
                });
            })
            .catch((err) => {
              toast.error(err.message);
            });
        });
      }
    }
    return (
      <Modal
        show={record}
        onHide={handleCloseRecord}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">{isEditing ? "Edit" : "Add New"} Record</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleCloseRecord}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row className="mb-2">
              <Form.Group
                controlId="formGridEmail"
                className="col-lg-6 col-md-12 mb-2"
              >
                <Form.Label>Type</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="category"
                  value={paymentPayload.category}
                  onChange={handlePaymentPayloadChange}
                  required
                >
                  <option hidden value="">
                    Select Type
                  </option>
                  {paymentTypes.map((paymentType) => {
                    return <option value={paymentType}>{paymentType}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                controlId="formGridEmail"
                className="col-lg-6 col-md-12 mb-2"
              >
                <Form.Label>Project</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="project"
                  value={projectPaymentPayload.project}
                  onChange={handleProjectPaymentPayloadChange}
                  required
                >
                  <option hidden value="">
                    Select Project
                  </option>
                  {projects.map((project) => {
                    return (
                      !(
                        project?.status?.toLowerCase() === "project on hold" ||
                        project?.status?.toLowerCase() === "project closed"
                      ) && (
                        <option
                          value={project.id}
                        >{`Proj ${project.id}_${project.customer?.first_name}_${project.customer?.last_name}`}</option>
                      )
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label>Received by</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="receiver"
                  value={paymentPayload.receiver}
                  onChange={handlePaymentPayloadChange}
                  required
                >
                  <option hidden>Select Receiver</option>
                  {users?.map((user) => {
                    return (
                      <option value={user?.user?.id}>
                        {user?.user?.username}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                controlId="formGridEmail"
                className="col-lg-6 col-md-12 mb-2"
              >
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter amount"
                  className="inputfield"
                  name="amount"
                  value={paymentPayload.amount}
                  onChange={handlePaymentPayloadChange}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group
                controlId="formGridEmail"
                className="col-lg-6 col-md-12 mb-2"
              >
                <Form.Label>Payment type</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="mode"
                  value={paymentPayload.mode}
                  onChange={handlePaymentPayloadChange}
                  required
                >
                  <option hidden value="">
                    Select payment
                  </option>
                  {paymentMethods.map((paymentMethod) => {
                    return <option>{paymentMethod}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group className="col-md-12">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  name="comments"
                  value={paymentPayload.comments}
                  onChange={handlePaymentPayloadChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group className="col-md-12 pb-5">
                <Form.Label>Add Document</Form.Label>
                <CustomFileSelector
                  selectedFiles={documents}
                  setSelectedFiles={setDocuments}
                  prevSelectedFiles={prevDocuments}
                  setPrevSelectedFiles={setPrevDocuments}
                />
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-start align-items-start gap-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleCloseRecord}
              ></input>
              <input
                type="submit"
                value={isEditing ? "Save" : "Submit"}
                className="signup_button"
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  //filter modal
  function FilterPettyCashModal() {
    const [filters, setFilters] = useState(updatedFilters);
    function handleFilterChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setFilters({ ...filters, [name]: value });
    }
    return (
      <Modal
        show={showFilter}
        onHide={handleCloseFilter}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Filter</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleCloseFilter}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-4">
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="payment__category"
                  value={filters.payment__category}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Type</option>
                  {paymentTypes.map((paymentType) => {
                    return <option value={paymentType}>{paymentType}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Project</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="project"
                  value={filters.project}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Project</option>
                  {projects.map((project) => {
                    return (
                      <option
                        value={project.id}
                      >{`Proj ${project.id}_${project.customer?.first_name}_${project.customer?.last_name}`}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Payment Date</Form.Label>
                <Customdate
                  name="payment_date"
                  value={filters.payment_date}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="category"
                  value={filters.category}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Category</option>
                  {paymentCategories?.map((paymentCategory) => {
                    return (
                      <option value={paymentCategory}>{paymentCategory}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Created By</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="payment__created_by"
                  value={filters.payment__created_by}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Created by</option>
                  {users?.map((user) => {
                    return (
                      <option value={user?.user?.id}>
                        {user?.user?.username}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Received by</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="payment__receiver"
                  value={filters.payment__receiver}
                  onChange={handleFilterChange}
                  required
                >
                  <option hidden value="">
                    Select Received by
                  </option>
                  {users?.map((user) => {
                    return (
                      <option value={user?.user?.id}>
                        {user?.user?.username}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Payment Mode</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="payment__mode"
                  value={filters.payment__mode}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Payment mode</option>
                  {paymentMethods.map((paymentMethod) => {
                    return (
                      <option value={paymentMethod}>{paymentMethod}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-center align-items-start gap-3">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleCloseFilter}
              ></input>
              <input
                type="button"
                value="Apply"
                className="signup_button"
                onClick={() => {
                  setUpdatedFilters(filters);
                  handleCloseFilter();
                }}
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Petty Cash</h4>
          </div>
          <div>
            <Custombuttons
              label={"Add New Record"}
              buttonIcon={Add}
              className="add_employee_btn"
              onClick={handleShowRecord}
            />
          </div>
        </div>
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div className="d-flex flex-wrap align-items-center mb-3 gap-2">
            <button
              className="add_employee_btn d-flex flex-row"
              onClick={handleFilterShow}
            >
              <FilterIcon />
              <span>Add Filter</span>
            </button>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {Object.keys(updatedFilters).map((key) => {
                if (key !== "active" && updatedFilters[key] !== "")
                  return (
                    <div>
                      <div class="chip ms-1 me-1">
                        <div class="chip-content">
                          {key === "project"
                            ? `Proj ${updatedFilters[key]}`
                            : key === "payment__created_by" ||
                              key === "payment__receiver"
                            ? getUserName(updatedFilters[key])
                            : updatedFilters[key]}
                        </div>
                        <div
                          class="chip-close"
                          onClick={() => {
                            setUpdatedFilters({ ...updatedFilters, [key]: "" });
                          }}
                        >
                          <svg
                            class="chip-svg"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
          <div className="table_section table-responsive">
            <Table bordered>
              <thead className="table_head_bg">
                <tr>
                  <th scope="col">Payment Date</th>
                  <th scope="col">Type</th>
                  <th scope="col" style={{ width: "150px" }}>
                    Project
                  </th>
                  <th scope="col">Amount</th>
                  <th scope="col">Created_by</th>
                  <th scope="col">Received_by</th>
                  <th scope="col">Payment Mode</th>
                  <th scope="col" style={{ width: "150px" }}>
                    Details
                  </th>
                  <th scope="col">Document</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {tableData?.results.map((row) => {
                  return (
                    <tr>
                      <td>{row.payment_date}</td>
                      <td>{row?.payment?.category}</td>
                      <td>
                        <LightTooltip
                          title={`Proj ${row.project.id}_${row.project?.customer?.first_name}_${row.project?.customer?.last_name}`}
                          arrow
                          placement="bottom"
                        >
                          <div className="table_text_overflow">
                            {`Proj ${row.project.id}_${row.project?.customer?.first_name}_${row.project?.customer?.last_name}`}
                          </div>
                        </LightTooltip>
                      </td>
                      <td>{row?.payment?.amount}</td>
                      <td>{row?.payment?.created_by?.username}</td>
                      <td>{row?.payment?.receiver?.username}</td>
                      <td>{row?.payment?.mode}</td>
                      <td>
                        <LightTooltip
                          title={row?.payment?.comments}
                          arrow
                          placement="bottom"
                        >
                          <div className="table_text_overflow">
                            {row?.payment?.comments}
                          </div>
                        </LightTooltip>
                      </td>
                      <td class="active-links">
                        <span
                          class="footer_link"
                          onClick={() => {
                            setSelectedPettyCash(row);
                            setShowDocView(true);
                          }}
                        >
                          View
                        </span>
                      </td>
                      <td className="text-center">
                        {!(
                          row.project?.status?.toLowerCase() ===
                            "project on hold" ||
                          row.project?.status?.toLowerCase() ===
                            "project closed"
                        ) && (
                          <div class="btn-group dropend">
                            <span
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <LightTooltip
                                title="Options"
                                arrow
                                placement="bottom"
                              >
                                <MenuIcon alt="no_img" className="edit_icon" />
                              </LightTooltip>
                            </span>
                            <ul class="dropdown-menu">
                              <li className="pt-2 pb-2">
                                <Link
                                  onClick={() => {
                                    setIsEditing(true);
                                    setSelectedPettyCash(row);
                                    handleShowRecord();
                                  }}
                                >
                                  <button
                                    class="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                                    type="button"
                                  >
                                    <EditIcon
                                      style={{ height: "16px", width: "16px" }}
                                    />
                                    <span className="ms-2 ">Edit</span>
                                  </button>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
                {tableData?.count === 0 && (
                  <tr>
                    <td colSpan={10} style={{ textAlign: "center" }}>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <DocumentViewModal
            show={showDocView}
            onHide={() => {
              setShowDocView(false);
              setSelectedPettyCash(null);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
            handleCloseView={() => {
              setShowDocView(false);
              setSelectedPettyCash(null);
            }}
            links={selectedPettyCash?.docs}
          />

          <AddEditPettyCashModal />
          <FilterPettyCashModal />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Incomeandexpenses;
