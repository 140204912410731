import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Outlinebutton from "../Customcomponents/Outlinebutton";
import { getChecklist, postChecklist } from "../api/service";
import { toast } from "react-toastify";

const Salespreexecutionchecklist = () => {
  const currentUserRole = localStorage.getItem("role");
  const canEditedBy = ["civil_team", "civil_head"];
  const [questions, setQuestions] = useState(null);
  const location = useLocation();

  const projectId = location.state?.projectId;
  const customerFname = location.state?.customerFname;
  const customerLname = location.state?.customerLname;
  const isProjectClosed = location.state?.isProjectClosed;

  const hasEditPermission = !(
    canEditedBy.includes(currentUserRole) && isProjectClosed === false
  );

  const navigate = useNavigate();
  useEffect(() => {
    let state = location.state;
    if (state.projectId) {
      getChecklist({ project: state.projectId })
        .then((res) => {
          setQuestions(res);
        })
        .catch((err) => {});
    }
  }, []);

  const questionAndFieldMap = {
    text: createTextFieldQuestion,
    date: createDateFieldQuestion,
    "multiple choice": createSelectFieldQuestion,
    boolean: createRadioQuestion,
  };

  function handleFieldChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    let updatedQuestions = questions.results;
    for (let index = 0; index < updatedQuestions.length; index++) {
      if (name == updatedQuestions[index]["id"]) {
        updatedQuestions[index]["question_response"] = value;
        break;
      }
    }
    setQuestions({ ...questions, results: updatedQuestions });
  }

  function createRadioQuestion(id, question, question_response) {
    return (
      <Form.Group className="col-md-12 col-lg-6" controlId="formGridPassword">
        <Form.Label>{question?.query}</Form.Label>
        <div>
          {question?.choices?.map((choice) => {
            return (
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name={id}
                  id={`radio_${id}`}
                  value={choice.choice}
                  onChange={handleFieldChange}
                  checked={question_response === choice.choice}
                  disabled={hasEditPermission}
                />
                <label class="form-check-label" for="inlineRadio1">
                  {choice.choice}
                </label>
              </div>
            );
          })}
        </div>
      </Form.Group>
    );
  }

  function createTextFieldQuestion(id, question, question_response) {
    return (
      <Form.Group className="col-md-12 col-lg-6">
        <Form.Label>{question?.query}</Form.Label>
        <Form.Control
          type="text"
          className="inputfield"
          name={id}
          value={question_response ?? ""}
          onChange={handleFieldChange}
          disabled={hasEditPermission}
        />
      </Form.Group>
    );
  }

  function createSelectFieldQuestion(id, question, question_response) {
    return (
      <Form.Group as={Col} className="col-md-6">
        <Form.Label>{question?.query}</Form.Label>
        <Form.Select
          className="inputfield"
          name={id}
          value={question_response ?? ""}
          onChange={handleFieldChange}
          disabled={hasEditPermission}
        >
          {question?.choices.map((choice) => {
            return <option value={choice.choice}>{choice.choice}</option>;
          })}
        </Form.Select>
      </Form.Group>
    );
  }

  function createDateFieldQuestion(id, question, question_response) {
    return (
      <Form.Group
        as={Col}
        className="mb-2 col-md-6"
        controlId="formGridAddress1"
      >
        <Form.Label>{question.query}</Form.Label>
        <Form.Control
          type="date"
          className="inputfield form-control date-control"
          name={id}
          value={question_response ?? ""}
          onChange={handleFieldChange}
          disabled={hasEditPermission}
        />
      </Form.Group>
    );
  }
  return (
    <React.Fragment>
      <div className="banner-section">
      <div className="d-flex justify-content-between align-items-center">
          <div>
            <ul>
              <li>
                <h6 className="breadcrumb_main_heading">
                  <Link to="/salesprojects">Projects</Link>
                </h6>
              </li>
              <li>
                <span className="breadcrumb_paragraph">Pre-execution Checklist</span>
              </li>
            </ul>
          </div>
          <div>
            <div className="d-flex justify-content-end list-group-item">
              <div className="d-flex justify-content-end pt-1 pb-1 gap-2 ">
                <div>
                  <Link to="/salesprojects">
                    <Outlinebutton name="Back" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>


        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">

        <div>
            <label classname="breadcrumb_description">
              Project Id:{" "}
              <span style={{ fontWeight: "400" }}>{`Proj ${projectId}`}</span>
            </label>
          </div>
          <div>
            <label classname="breadcrumb_description" style={{ display: "block", wordWrap: "break-word" }}>
              Customer Name:{" "}
              <span
                style={{ fontWeight: "400" }}
                className="breadcrumb_description_names"
              >{`${customerFname} ${customerLname}`} </span>
            </label>
          </div>

          <hr/>
          <div className="table_section">
            <Form className="row">
              {questions?.results.map((question) => {
                return questionAndFieldMap[question.question.question_type](
                  question.id,
                  question.question,
                  question.question_response
                );
              })}
            </Form>
            {!hasEditPermission && (
              <div className="d-flex justify-content-start align-items-start gap-2 mt-5">
                <input
                  type="button"
                  value="Submit"
                  class="signup_button"
                  onClick={() => {
                    let questionsPrms = [];
                    let updatedQuestions = questions.results;
                    updatedQuestions.map((updatedQuestion) => {
                      let form = new FormData();
                      form.append("id", updatedQuestion.id);
                      form.append("project", updatedQuestion.project?.id);
                      form.append("question", updatedQuestion.question?.id);
                      form.append(
                        "question_response",
                        updatedQuestion.question_response
                      );
                      questionsPrms.push(postChecklist(form));
                    });
                    Promise.all(questionsPrms).then((questionsRes) => {
                      toast.success("questions are submitted");
                    });
                  }}
                />

                <input
                  type="button"
                  value="Cancel"
                  className="outline_btn"
                  onClick={() => {
                    navigate(-1);
                  }}
                ></input>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Salespreexecutionchecklist;
