import React from "react";

const Outlinebutton = (props) => {
  return (
    <div>
      <input type="button" value={props.name} class="outline_btn" {...props} />
    </div>
  );
};

export default Outlinebutton;
