import React, { useState, useEffect } from "react";
import delete_icon from "../assets/img/delete.svg";
import edit_icon from "../assets/img/edit.svg";
import Custompagenation from "../Customcomponents/Custompagenation";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import close_icon from "../assets/img/close.svg";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
// import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Outlinebutton from "../Customcomponents/Outlinebutton";
import Customsearch from "../Customcomponents/Customsearch";
import { ReactComponent as DownloadViewIcon } from "../assets/img/downloadviewicon.svg";
import { ReactComponent as CancelIcon } from "../assets/img/cancel.svg";
import sampleview_img from "../assets/img/sampleview.svg";
import { Table } from "react-bootstrap";

import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as ViewIcon } from "../assets/img/view.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import { getProject_queries, postQueries, getProfile } from "../api/service";
import DocumentViewModal from "../Customcomponents/DocumentViewModal";
import { toast } from "react-toastify";
import {
  conDateToReadable,
  getIds,
  headsAndTeamMemebersOnly,
} from "../components/utils";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    lineHeight: "1.3",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Civilqueries = () => {
  const [tableData, setTableData] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [users, setUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const currentUserRole = localStorage.getItem("role");
  const currentUserId = localStorage.getItem("userid");
  const displayAddNewReqBut = currentUserRole === "customer";
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const rolesstr = Object.keys(headsAndTeamMemebersOnly).join(",");

  function getUniqUsers(users, projUsers) {
    return projUsers?.filter((projUser) => {
      return !users?.some((user) => {
        return user.user.id === projUser.id;
      });
    });
  }
  const [showupdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => {
    setProjectUsers([]);
    setSelectedRequest(null);
    setIsEditing(false);
    setShowUpdate(false);
  };
  const handleShowUpdate = () => setShowUpdate(true);

  const [searchValue, setSearchValue] = useState("");

  const [showview, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  function apiCall() {
    getProject_queries({
      search: searchValue,
      queries__assigned_to:
        currentUserRole === "civil_head" ? "" : currentUserId,
    })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  useEffect(() => {
    apiCall();
  }, [searchValue]);
  useEffect(() => {
    getProfile({ pagination: false, role: rolesstr, active: "True" })
      .then((res) => {
        setUsers(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  //single modal for both add and update
  function AddNewRequestModal() {
    const [payload, setPayload] = useState({
      id: selectedRequest?.queries?.id,
      assigned_to: selectedRequest?.queries?.assigned_to?.[0]?.id,
      created_by: selectedRequest?.queries?.created_by?.id,
      updated_by: currentUserId,
      title: selectedRequest?.queries.title,
      status: selectedRequest?.queries.status,
      description: selectedRequest?.queries.description,
    });
    const [projectPayload, setProjectPayload] = useState({
      id: selectedRequest?.id,
      queries: selectedRequest?.queries?.id,
      docs: getIds(selectedRequest?.docs ?? []),
      active: true,
      created_by: selectedRequest?.created_by,
      updated_by: selectedRequest?.updated_by,
      project: selectedRequest?.project,
    });
    function handleFieldChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      postQueries({ ...payload, assigned_to: [payload.assigned_to] }).then(
        (res) => {
          toast.success("Request is updated");
          handleCloseUpdate();
          apiCall();
        }
      );
    }
    return (
      <Modal
        show={showupdate}
        onHide={handleCloseUpdate}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">{isEditing ? "Update" : "View"} Request</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleCloseUpdate}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row className="mb-2">
              <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="col-md-6 mb-2 disable-state"
              >
                <Form.Label className={true ? "disabled-label" : ""}>
                  Title
                </Form.Label>
                <Form.Control
                  className="inputfield"
                  name={"title"}
                  value={payload.title}
                  onChange={handleFieldChange}
                  disabled={true}
                ></Form.Control>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} className="col-md-12">
                <Form.Label className={true ? "disabled-label" : ""}>
                  Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  name={"description"}
                  value={payload.description}
                  onChange={handleFieldChange}
                  disabled={true}
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} className="col-md-6">
                <Form.Label className={!isEditing ? "disabled-label" : ""}>
                  Status
                </Form.Label>
                <Form.Select
                  className="inputfield"
                  name={"status"}
                  value={payload.status}
                  onChange={handleFieldChange}
                  disabled={!isEditing}
                >
                  <option hidden>Select status</option>
                  <option value={"pending"}>Pending</option>
                  <option value={"accepted"}>Accept</option>
                  <option value={"rejected"}>Reject</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} className="col-md-6">
                <Form.Label className={!isEditing ? "disabled-label" : ""}>
                  Assigned to
                </Form.Label>
                <Form.Select
                  className="inputfield"
                  value={payload.assigned_to}
                  name="assigned_to"
                  onChange={handleFieldChange}
                  disabled={!isEditing}
                >
                  <option hidden>Select assigned to</option>
                  {users?.map((user) => {
                    return (
                      <option value={user.user.id}>{user.user.username}</option>
                    );
                  })}
                  {projectUsers?.map((user) => {
                    return (
                      [
                        currentUserRole,
                        headsAndTeamMemebersOnly[currentUserRole],
                      ].includes(user?.role) && (
                        <option value={user.id}>{user.username}</option>
                      )
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>

            {isEditing && (
              <>
                <hr />
                <div className="d-flex justify-content-start gap-2">
                  <input
                    type="button"
                    value="Cancel"
                    class="outline_btn"
                    onClick={handleCloseUpdate}
                  />

                  <input type="submit" value="Submit" class="signup_button" />
                </div>
              </>
            )}
            {/* <hr />
        <Row className="mt-4 mb-5">
          <div className="table_section">
            <div className="table-responsive-lg">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">
                      <div>Date</div>
                    </th>
                    <th scope="col">
                      <div>Assigned by</div>
                    </th>
                    <th scope="col">
                      <div>Assigner Role</div>
                    </th>

                    <th scope="col">
                      <div>Assigned to</div>
                    </th>
                    <th scope="col">
                      <div>Assignee Role</div>
                    </th>

                    <th scope="col">
                      <div>Status</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>08/12/2023 04:04 PM</td>
                    <td>Naveen</td>

                    <td>Client</td>
                    <td>Santosh_CH</td>
                    <td>Civil Head</td>

                    <td>Active</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Row> */}
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center mt-3">
          <div>
            <h4 className="headingfour">New Request</h4>
          </div>
        </div>
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
          <div className="table_section">
            <div className="table_section table-responsive">
              <Table bordered>
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">
                      <div>Date</div>
                    </th>
                    <th scope="col" style={{ width: "150px" }}>
                      <div>Project</div>
                    </th>
                    <th scope="col">
                      <div>Assigned by</div>
                    </th>
                    <th scope="col">
                      <div>Assigner Role</div>
                    </th>

                    <th scope="col">
                      <div>Assigned to</div>
                    </th>
                    <th scope="col">
                      <div>Assignee Role</div>
                    </th>
                    <th scope="col">
                      <div>Title</div>
                    </th>
                    <th scope="col">
                      <div>Description</div>
                    </th>
                    <th scope="col">
                      <div>Status</div>
                    </th>
                    <th scope="col">
                      <div>Documents</div>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results?.map((row) => {
                    return (
                      <tr>
                        <td>{conDateToReadable(row.created_at)}</td>
                        <td>
                          <LightTooltip
                            title={`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>{row?.queries?.created_by?.username}</td>
                        <td>{row?.queries?.created_by?.role}</td>
                        <td>{row?.queries?.assigned_to?.[0]?.username}</td>
                        <td>{row?.queries?.assigned_to?.[0]?.role}</td>
                        <td>{row?.queries?.title}</td>
                        <td>
                          <LightTooltip
                            title={row?.queries?.description}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {row?.queries?.description}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>{row?.queries?.status}</td>
                        <td className="active-links">
                          <span
                            onClick={() => {
                              setSelectedRequest(row);
                              handleShowView();
                            }}
                            class="footer_link"
                          >
                            View
                          </span>
                        </td>
                        <td className="text-center">
                          <div class="btn-group dropend">
                            <span
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <MenuIcon alt="no_img" className="edit_icon" />
                            </span>
                            <ul class="dropdown-menu">
                              <li
                                style={{ borderBottom: "1px solid #CBCBCB" }}
                                className="pt-2 pb-2"
                              >
                                <button
                                  class="dropdown-item d-flex projects_dropdownlinks"
                                  type="button"
                                  onClick={() => {
                                    setSelectedRequest(row);
                                    setShowUpdate(true);
                                    setIsEditing(false);
                                  }}
                                >
                                  <ViewIcon
                                    style={{ height: "18px", width: "18px" }}
                                  />
                                  <span className="ms-2 d-flex">View</span>
                                </button>
                              </li>
                              {!(
                                row?.project?.status === "project on hold" ||
                                row?.project?.status === "project closed"
                              ) &&
                                row?.queries?.status?.toLowerCase() ===
                                  "pending" && (
                                  <li className="pt-2 pb-2">
                                    <button
                                      class="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                                      type="button"
                                      onClick={() => {
                                        let filteredUsers = getUniqUsers(
                                          users,
                                          row?.project?.users_assigned
                                        );
                                        setProjectUsers(filteredUsers);
                                        setSelectedRequest(row);
                                        handleShowUpdate();
                                        setIsEditing(true);
                                      }}
                                    >
                                      <EditIcon
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />
                                      <span className="ms-2 ">Update</span>
                                    </button>
                                  </li>
                                )}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={10} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <AddNewRequestModal />
          <DocumentViewModal
            show={showview}
            onHide={() => {
              setShowView(false);
              setSelectedRequest(null);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
            handleCloseView={() => {
              setShowView(false);
              setSelectedRequest(null);
            }}
            links={selectedRequest?.docs}
          />
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">View Request</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group
                    as={Col}
                    controlId="formGridEmail"
                    className="col-md-6 mb-2 disable-state"
                  >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      placeholder="Change Required"
                      className="inputfield"
                    ></Form.Control>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group as={Col} className="col-md-12 disable-state">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      placeholder="Want a little change in the design"
                      as="textarea"
                      rows={2}
                      className="inputfield"
                    />
                  </Form.Group>
                </Row>

                <Row className="mt-4 mb-5">
                  {" "}
                  <div className="table_section">
                    <div className="table-responsive-lg">
                      <table className="table table-bordered align-middle">
                        <thead className="table_head_bg">
                          <tr>
                            <th scope="col">
                              <div>Date</div>
                            </th>
                            <th scope="col">
                              <div>Assigned by</div>
                            </th>
                            <th scope="col">
                              <div>Assigner Role</div>
                            </th>

                            <th scope="col">
                              <div>Assigned to</div>
                            </th>
                            <th scope="col">
                              <div>Assignee Role</div>
                            </th>

                            <th scope="col">
                              <div>Status</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>08/12/2023 04:04 PM</td>
                            <td>Naveen</td>

                            <td>Client</td>
                            <td>Santosh_CH</td>
                            <td>Civil Head</td>

                            <td>Active</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Row>

                <hr />
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Civilqueries;
