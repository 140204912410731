import logo from "./logo.svg";
import "./App.css";
import Dashboard from "../src/components/Dashboard";
import React, { useState, useEffect } from "react";
import Routers from "./routers/Routers";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getProfile } from "./api/service";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const profileId = localStorage.getItem("profileid");
    if (profileId) {
      getProfile({ id: profileId, pagination: false })
        .then((response) => {
          let res = response[0];
          localStorage.setItem("username", res?.user?.username);
          localStorage.setItem("role", res?.user?.role);
          localStorage.setItem("profile_pic", `${res?.profile_pic}`);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <React.Fragment>
      {loading ? <div className="loading"></div> : <Routers />}
      <ToastContainer position="top-right" />
    </React.Fragment>
  );
}

export default App;
