import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Custombuttons from "../Customcomponents/Custombuttons";
import Customdate from "../Customcomponents/Customdate";
import Custompagenation from "../Customcomponents/Custompagenation";
import { ReactComponent as Add } from "../assets/img/add.svg";
import { ReactComponent as CancelIcon } from "../assets/img/cancel.svg";
import close_icon from "../assets/img/close.svg";
import cancel, { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import { ReactComponent as Download } from "../assets/img/downloadtask.svg";
import { ReactComponent as DownloadViewIcon } from "../assets/img/downloadviewicon.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as FilterIcon } from "../assets/img/filter.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import sampleview_img from "../assets/img/sampleview.svg";
import { ReactComponent as UpdateIcon } from "../assets/img/update.svg";
import { ReactComponent as Upload } from "../assets/img/upload.svg";
import { ReactComponent as ViewIcon } from "../assets/img/view.svg";
import SampleFile from "../assets/sample.xlsx";

import { toast } from "react-toastify";
import {
  getProfile,
  getProject,
  getSubCategories,
  getTask,
  postDrivem,
  postTask,
  postTask_history,
} from "../api/service";
import { headAndTeamMembers } from "../components/utils";
import TaskHistory from "./TaskHistory";
import CustomFileSelector from "../Customcomponents/CustomFileSelector";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Tasks = () => {
  const [tableData, setTableData] = useState();
  const [show, setShow] = useState(false);
  const currentUserId = localStorage.getItem("userid");

  //task status
  const status = [
    "immediate action required",
    "on-track",
    "completed",
  ];

  const [dropdownVisibleIndex, setDropdownVisibleIndex] = useState(-1);
  const dropdownRefs = useRef([]);

  const handleToggleDropdown = (index) => {
    setDropdownVisibleIndex(index === dropdownVisibleIndex ? -1 : index);
  };

  const handleLinkClick = () => {
    setDropdownVisibleIndex(-1);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          if (index === dropdownVisibleIndex) {
            setDropdownVisibleIndex(-1);
          }
        }
      });
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisibleIndex]);

  //filters
  const [updatedFilters, setUpdatedFilters] = useState({
    active: "True",
    project: "",
    start_date: "",
    end_date: "",
    assigned_to: "",
    created_by: "",
    status: "",
  });

  function getUserName(userId) {
    for (let index = 0; index < users.length; index++) {
      if (users[index].user.id == userId) {
        return `${users[index].user?.username}`;
      }
    }
    return users.find((user) => user.id === userId);
  }

  //projects and users
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const currentUserRole = localStorage.getItem("role");
  // const assignee_role = headAndTeamMembers[currentUserRole];

  const [taskCategory, setTaskCategory] = useState(null);

  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => {
    setDeleteShow(false);
    setSelectedTask(null);
  };
  const handleDeleteShow = () => setDeleteShow(true);
  const [upload, setUpload] = useState(false);
  const handleCloseUpload = () => setUpload(false);
  const handleShowUpload = () => setUpload(true);
  const [update, setUpdate] = useState(false);
  const handleShowUpdate = () => setUpdate(true);
  const handleCloseUpdate = () => {
    setSelectedTask(null);
    setUpdate(false);
  };

  const [showfilter, setShowFilter] = useState(false);

  const handleCloseFilter = () => {
    setSelectedTask(null);
    setShowFilter(false);
  };
  const handleShowFilter = () => setShowFilter(true);
  const [showviewdoc, setShowViewDoc] = useState(false);

  const handleCloseViewDoc = () => setShowViewDoc(false);
  const handleShowViewDoc = () => setShowViewDoc(true);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isEditing, setIsEditing] = useState(null);

  const deleteButtonRef = useRef(null); // Reference to the delete button

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      deleteButtonRef.current.click(); // Trigger click event on the delete button
    }
  };

  useEffect(() => {
    if (deleteshow) {
      document.addEventListener("keydown", handleKeyPress);
      // Set focus on the delete button when the modal is shown
      deleteButtonRef.current.focus();
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [deleteshow]); // Run effect when deleteshow changes

  const handleClose = () => {
    setShow(false);
    setIsEditing(null);
    setSelectedTask(null);
    setProjectUsers([]);
  };
  const handleShow = () => setShow(true);
  function apiCall() {
    getTask(updatedFilters)
      .then((res) => setTableData(res))
      .catch((err) => {
        toast.error(err.message);
      });
  }
  function getProjectsAndUsers() {
    let projectPrm = getProject({ active: "True", pagination: false });
    let usersPrm = getProfile({
      active: "True",
      pagination: false,
    });
    Promise.all([projectPrm, usersPrm])
      .then(([projectRes, usersRes]) => {
        setProjects(projectRes);
        setUsers(usersRes);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  function getCategoryAndSubCategory() {
    getSubCategories({ category__name: "Task", name: "Task History" })
      .then((res) => {
        if (res?.results.length > 0) {
          setTaskCategory(res.results[0]);
        } else {
          toast.error("Add Task and Task History in admin panel");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  useEffect(() => {
    apiCall();
  }, [updatedFilters]);

  useEffect(() => {
    apiCall();
    getProjectsAndUsers();
    getCategoryAndSubCategory();
  }, []);

  //add task modal
  function TaskModal() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [payload, setPayload] = useState({
      id: selectedTask?.id,
      project: selectedTask?.project?.id ?? "",
      assigned_to: selectedTask?.assigned_to.id ?? "",
      title: selectedTask?.title ?? "",
      start_date: selectedTask?.start_date ?? "",
      end_date: selectedTask?.end_date ?? "",
      created_by: selectedTask?.created_by?.id ?? currentUserId,
    });
    const [updatedProjectUsers, setUpdatedProjectUsers] =
      useState(projectUsers);
    function handleChangeInput(event) {
      let name = event.target.name;
      let value = event.target.value;
      if (name == "project") {
        const usersAssigned =
          event.target.selectedOptions[0].getAttribute("data-users");
        setUpdatedProjectUsers(JSON.parse(usersAssigned));
      }
      setPayload({ ...payload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (payload.project === "") {
        toast.error("Please select the project");
        return;
      } else if (payload.assigned_to === "") {
        toast.error("Please select assignee");
        return;
      } else if (payload.start_date === "") {
        toast.error("Please select start date");
        return;
      } else if (payload.end_date === "") {
        toast.error("Please select end date");
        return;
      }
      if (!isSubmitted) {
        setIsSubmitted(true);
        postTask(payload)
          .then((res) => {
            toast.success("Task is added");
            handleClose();
            setIsSubmitted(false);
            apiCall();
          })
          .catch((err) => {
            setIsSubmitted(false);
            toast.error(err.message);
          });
      }
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">
            {isEditing === null ? "Add" : isEditing ? "Edit" : "View"} Tasks
          </h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row>
              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label
                  className={isEditing === false ? "disabled-label" : ""}
                >
                  Project
                </Form.Label>
                <Form.Select
                  className="inputfield"
                  name="project"
                  value={payload.project}
                  onChange={handleChangeInput}
                  disabled={isEditing === false}
                  required
                >
                  <option hidden value="">
                    Select project
                  </option>
                  {projects?.map((project) => {
                    return (
                      !(
                        project?.status?.toLowerCase() === "project on hold" ||
                        project?.status?.toLowerCase() === "project closed"
                      ) && (
                        <option
                          value={project.id}
                          name="project"
                          onChange={handleChangeInput}
                          data-users={JSON.stringify(project.users_assigned)}
                        >
                          {`Proj ${project.id}_${project.customer?.first_name}_${project.customer?.last_name}`}
                        </option>
                      )
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label
                  className={isEditing === false ? "disabled-label" : ""}
                >
                  Assignee to
                </Form.Label>
                <Form.Select
                  className="inputfield"
                  name="assigned_to"
                  value={payload.assigned_to}
                  onChange={handleChangeInput}
                  disabled={isEditing === false}
                  required
                >
                  <option hidden value="">
                    {payload.project
                      ? "Select assignee"
                      : updatedProjectUsers.length === 0
                      ? "No users are assigned to the project"
                      : "Select the project"}
                  </option>
                  {updatedProjectUsers?.map((updatedProjectUser) => {
                    return (
                      <option value={updatedProjectUser.id}>
                        {updatedProjectUser.username}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-lg-12 col-md-12 mb-2">
                <Form.Label
                  className={isEditing === false ? "disabled-label" : ""}
                >
                  Task
                </Form.Label>
                <Form.Control
                  placeholder="Enter task"
                  type="task"
                  className="inputfield"
                  name="title"
                  value={payload.title}
                  onChange={handleChangeInput}
                  disabled={isEditing === false}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 col-md-12 mb-2"
                controlId="formGridEmail"
              >
                <Form.Label
                  className={isEditing === false ? "disabled-label" : ""}
                >
                  Start Date
                </Form.Label>
                <Form.Control
                  type="date"
                  className="inputfield form-control date-control"
                  name="start_date"
                  value={payload.start_date}
                  onChange={handleChangeInput}
                  disabled={isEditing === false}
                  max={payload?.end_date}
                  required
                />
              </Form.Group>

              <Form.Group
                className="col-lg-6 col-md-12 mb-2"
                controlId="formGridPassword"
              >
                <Form.Label
                  className={isEditing === false ? "disabled-label" : ""}
                >
                  End Date
                </Form.Label>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={payload.end_date}
                  onChange={handleChangeInput}
                  className="inputfield form-control date-control"
                  min={payload?.start_date}
                  disabled={isEditing === false}
                  required
                />
              </Form.Group>
            </Row>
            <hr />

            {isEditing === false && (
              <Row className="mb-4">
                <Form.Group className="col-md-12">
                  <div className="table_section">
                    <div className="table-responsive">
                      <TaskHistory taskid={payload.id} />
                    </div>
                  </div>
                </Form.Group>
              </Row>
            )}
            {isEditing !== false && (
              <div className="d-flex justify-content-start align-items-center gap-2 pt-2">
                <input
                  type="button"
                  value="Cancel"
                  className="outline_btn"
                  onClick={handleClose}
                ></input>
                <input
                  type="submit"
                  value="Submit"
                  className="signup_button"
                ></input>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  //filter modal
  function FilterModal() {
    const [filters, setFilters] = useState(updatedFilters);
    const filterStatus = ["new", ""];
    function handleFilterChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setFilters({ ...filters, [name]: value });
    }
    return (
      <Modal
        show={showfilter}
        onHide={handleCloseFilter}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Filter</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleCloseFilter}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-4">
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Project</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="project"
                  value={filters.project}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Project</option>
                  {projects?.map((project) => {
                    return (
                      <option
                        value={project.id}
                      >{`Proj ${project.id}_${project.customer?.first_name}_${project.customer?.last_name}`}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 col-mb-12">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  className="inputfield"
                  value={filters.status}
                  onChange={handleFilterChange}
                  name="status"
                >
                  <option hidden value="">
                    Select Status
                  </option>
                  {status.map((value) => {
                    return <option>{value}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="formGridEmail" className="col-md-12 mb-2">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  className="inputfield form-control date-control"
                  name="start_date"
                  value={filters.start_date}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group controlId="formGridEmail" className="col-md-12 mb-2">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  className="inputfield form-control date-control"
                  name="end_date"
                  value={filters.end_date}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Assignee to</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="assigned_to"
                  value={filters.assigned_to}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Assignee</option>
                  {users?.map((user) => {
                    return (
                      <option
                        value={user?.user?.id}
                      >{`${user?.user?.username}`}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Created By</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="created_by"
                  value={filters.created_by}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Employee</option>
                  {users?.map((user) => {
                    return (
                      <option
                        value={user?.user?.id}
                      >{`${user?.user?.username}`}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-center align-items-start gap-3">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleCloseFilter}
              ></input>
              <input
                type="button"
                value="Apply"
                className="signup_button"
                onClick={() => {
                  setUpdatedFilters(filters);
                  handleCloseFilter();
                }}
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  //update task modal
  function UpdateTaskModal() {
    //upload files
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [taskFiles, setTaskFiles] = useState([]);
    const [payload, setPayload] = useState({
      task: selectedTask?.id,
      status: "",
      completed: "",
      start_date: "",
      end_date: "",
      completion: "",
      comments: "",
    });
    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }
    function handleSubmitForm(event) {
      event.preventDefault();
      if (payload.status === "") {
        toast.error("Please select status");
        return;
      } else if (payload.completion === "") {
        toast.error("Enter completion percentage");
        return;
      } else if (parseInt(payload.completion) > 100) {
        toast.error("Completion percentage cant be greater than 100");
        return;
      } else if (payload.comments === "") {
        toast.error("Please give comment");
        return;
      } else if (payload.start_date === "" || payload.end_date === "") {
        toast.error("Please select start date and end date");
        return;
      } else if (!(taskFiles.length > 0)) {
        toast.error("Please select files");
        return;
      }
      if (!isSubmitted) {
        setIsSubmitted(true);
        let taskFilesPrms = [];
        for (let index = 0; index < taskFiles.length; index++) {
          let form = new FormData();
          form.append("file", taskFiles[index]);
          form.append("categories", taskCategory.category?.id);
          form.append("subcategories", taskCategory?.id);
          taskFilesPrms.push(postDrivem(form));
        }
        Promise.all(taskFilesPrms).then((taskFilesRes) => {
          let docIds = taskFilesRes.map((taskFileRes) => {
            return taskFileRes.id;
          });
          postTask_history({ ...payload, docs: docIds })
            .then((res) => {
              setIsSubmitted(false);
              apiCall();
              handleCloseUpdate();
            })
            .catch((err) => {
              setIsSubmitted(false);
              toast.error(err.message);
            });
        });
      }
    }
    return (
      <Modal
        show={update}
        onHide={handleCloseUpdate}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Update Tasks</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleCloseUpdate}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row className="mb-2">
              <Form.Group
                controlId="formGridEmail"
                className="col-lg-6 col-md-12 mb-2"
              >
                <Form.Label>Project</Form.Label>
                <Form.Select
                  className="inputfield"
                  value={selectedTask?.project.id}
                  disabled={true}
                >
                  <option value={selectedTask?.project?.id}>
                    {`Proj ${selectedTask?.project?.id}_${selectedTask?.project?.customer?.first_name}_${selectedTask?.project?.customer?.last_name}`}
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                controlId="formGridEmail"
                className="col-lg-6 cold-md-12 mb-2"
              >
                <Form.Label>Assigned to</Form.Label>
                <Form.Select
                  value={selectedTask?.assigned_to.id}
                  className="inputfield"
                  disabled
                >
                  <option
                    value={selectedTask?.assigned_to.id}
                  >{`${selectedTask?.assigned_to?.first_name} ${selectedTask?.assigned_to?.last_name}`}</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group className="mb-2" controlId="formGridAddress1">
                <Form.Label>Task</Form.Label>
                <Form.Control
                  type="task"
                  className="inputfield"
                  value={selectedTask?.title}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group controlId="formGridEmail" className="col-md-6 mb-2">
                <Form.Label>Start Date</Form.Label>
                <Customdate value={selectedTask?.start_date} disabled />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>End Date</Form.Label>
                <Customdate value={selectedTask?.end_date} disabled />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group className="col-lg-6 col-md-12">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  className="inputfield"
                  value={payload.status}
                  onChange={handleInputChange}
                  name="status"
                  required
                >
                  <option hidden value="">
                    Select Status
                  </option>
                  {status.map((value) => {
                    return <option>{value}</option>;
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="col-lg-6 col-md-12">
                <Form.Label>Completed %</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter percentage of task completed"
                  className="inputfield"
                  value={payload.completion}
                  onChange={handleInputChange}
                  name="completion"
                  required
                ></Form.Control>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group
                controlId="formGridEmail"
                className="col-lg-6 col-md-12"
              >
                <Form.Label>Actual Start Date</Form.Label>
                <Customdate
                  value={payload.start_date}
                  onChange={handleInputChange}
                  name="start_date"
                  required
                />
              </Form.Group>

              <Form.Group
                controlId="formGridPassword"
                className="col-lg-6 col-md-12"
              >
                <Form.Label>Actual End Date</Form.Label>
                <Customdate
                  value={payload.end_date}
                  onChange={handleInputChange}
                  min={payload.start_date}
                  name="end_date"
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} className="col-md-12">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  value={payload.comments}
                  onChange={handleInputChange}
                  name="comments"
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group className="col-md-12 pb-5">
                <Form.Label>Document</Form.Label>
                <CustomFileSelector
                  selectedFiles={taskFiles}
                  setSelectedFiles={setTaskFiles}
                  prevSelectedFiles={[]}
                  setPrevSelectedFiles={() => {}}
                />
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-start align-items-start gap-2">
              <input
                type="button"
                value="Cancel"
                class="outline_btn"
                onClick={handleCloseUpdate}
              />
              <input type="submit" value="Submit" class="signup_button" />
            </div>

            <hr />
            <Row className="mb-4">
              <Form.Group className="col-md-12">
                <div className="table_section">
                  <div className="table-responsive">
                    <TaskHistory taskid={selectedTask?.id} />
                  </div>
                </div>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  //upload task modal
  function UploadTaskModal() {
    //upload files
    const [uploadTaskFile, setUploadTaskFile] = useState(null);
    return (
      <Modal
        show={upload}
        onHide={handleCloseUpload}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Upload Task</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleCloseUpload}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-2">
              <Form.Group as={Col} className="col-md-12 pb-5">
                <Form.Label>Add Document</Form.Label>
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <div>
                    <input
                      type="file"
                      id="file-upload"
                      class="inputfile"
                      onChange={(event) => {
                        setUploadTaskFile(event.target.files[0]);
                      }}
                      accept=".csv,.xls,.xlsx"
                    />
                    <label
                      for="file-upload"
                      class="add_employee_btn d-flex flex-row"
                      style={{ cursor: "pointer" }}
                    >
                      <span>
                        {uploadTaskFile
                          ? `${uploadTaskFile.name} is selected`
                          : "Select file"}
                      </span>
                    </label>
                  </div>
                </div>
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-start align-items-center gap-2 pt-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleCloseUpload}
              ></input>
              <input
                type="button"
                value="Submit"
                className="signup_button"
                onClick={() => {
                  if (!uploadTaskFile) {
                    toast.error("Please select the file to upload");
                  }
                  let formData = new FormData();
                  formData.append("file", uploadTaskFile);
                  postTask(formData)
                    .then((res) => {
                      toast.success("Tasks are added");
                      apiCall();
                      handleCloseUpload();
                    })
                    .catch((err) => {
                      toast.error(err.message);
                    });
                }}
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  //download sample task file
  function downloadSampleTaskFile(event) {
    event.preventDefault();
    const link = document.createElement("a");
    link.href = SampleFile;
    link.download = "sample.xlsx";
    link.click();
  }

  function updateProjectUsers(projectId) {
    for (let index = 0; index < projects.length; index++) {
      if (projectId == projects[index]["id"]) {
        setProjectUsers(projects[index].users_assigned);
      }
    }
  }
  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Tasks</h4>
          </div>
          <div className="d-flex gap-2">
            <Custombuttons
              label={"Download Sheet Template"}
              buttonIcon={Download}
              onClick={downloadSampleTaskFile}
            />
            <Custombuttons
              label={"Upload Task"}
              buttonIcon={Upload}
              onClick={handleShowUpload}
            />
            <Custombuttons
              label={"Add Task"}
              buttonIcon={Add}
              className="add_employee_btn"
              onClick={handleShow}
            />
          </div>
        </div>
        <hr />

        <div className="banner-section-dashboard  mb-3">
          <div class="d-flex flex-wrap align-items-center mb-3 gap-2 mt-1 chips_section">
            <button
              className="add_employee_btn d-flex flex-row"
              onClick={handleShowFilter}
            >
              <FilterIcon />
              <span>Add Filter</span>
            </button>
            <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
              {Object.keys(updatedFilters).map((key) => {
                if (key !== "active" && updatedFilters[key] !== "")
                  return (
                    <div class="chip ms-1 me-1">
                      <div class="chip-content">
                        {key === "project"
                          ? `Proj ${updatedFilters[key]}`
                          : key === "assigned_to" || key === "created_by"
                          ? getUserName(updatedFilters[key])
                          : updatedFilters[key]}
                      </div>
                      <div
                        class="chip-close"
                        onClick={() => {
                          setUpdatedFilters({ ...updatedFilters, [key]: "" });
                        }}
                      >
                        <svg
                          class="chip-svg"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                        </svg>
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>

          <div className="table_section table-responsive">
            <div>
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">Task</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col" style={{ width: "150px" }}>
                      Project
                    </th>
                    <th scope="col">Assigned to</th>
                    <th scope="col">Created by</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results.map((row, index) => {
                    return (
                      <tr>
                        <td>{row.title}</td>
                        <td>{row.start_date}</td>
                        <td>{row.end_date}</td>
                        <td>
                          <LightTooltip
                            title={`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>{row.assigned_to?.username}</td>
                        <td>{row.created_by?.username}</td>
                        <td>{row.status}</td>
                        <td className="text-center">
                          <div
                            ref={(ref) => (dropdownRefs.current[index] = ref)}
                          >
                            <span onClick={() => handleToggleDropdown(index)}>
                              <LightTooltip
                                title="Options"
                                arrow
                                placement="bottom"
                              >
                                <MenuIcon alt="no_img" className="edit_icon" />
                              </LightTooltip>
                            </span>
                            <ul
                              className={`dropdown-menu custom_header  ${
                                dropdownVisibleIndex === index ? "show" : ""
                              }`}
                            >
                              <li
                                style={{ borderBottom: "1px solid #CBCBCB" }}
                                className="pt-2 pb-2"
                                onClick={handleLinkClick}
                              >
                                <button
                                  class="dropdown-item d-flex projects_dropdownlinks"
                                  type="button"
                                  onClick={() => {
                                    setIsEditing(false);
                                    setSelectedTask(row);
                                    updateProjectUsers(row.project.id);
                                    handleShow();
                                  }}
                                >
                                  <ViewIcon
                                    style={{ height: "18px", width: "18px" }}
                                  />
                                  <span className="ms-2 d-flex">View</span>
                                </button>
                              </li>
                              {!(
                                row.project.status === "project on hold" ||
                                row.project.status === "project closed"
                              ) && (
                                <>
                                  <li
                                    style={{
                                      borderBottom: "1px solid #CBCBCB",
                                    }}
                                    className="pt-2 pb-2"
                                    onClick={handleLinkClick}
                                  >
                                    <div
                                      onClick={() => {
                                        setIsEditing(true);
                                        setSelectedTask(row);
                                        updateProjectUsers(row.project.id);
                                        handleShow();
                                      }}
                                    >
                                      <button
                                        class="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                                        type="button"
                                      >
                                        <EditIcon
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                          }}
                                        />
                                        <span className="ms-2 ">Edit</span>
                                      </button>
                                    </div>
                                  </li>
                                  <li
                                    style={{
                                      borderBottom: "1px solid #CBCBCB",
                                    }}
                                    className="pt-2 pb-2"
                                    onClick={handleLinkClick}
                                  >
                                    <div
                                      onClick={() => {
                                        setSelectedTask(row);
                                        handleShowUpdate();
                                      }}
                                    >
                                      <button
                                        class="dropdown-item d-flex projects_dropdownlinks"
                                        type="button"
                                      >
                                        <UpdateIcon
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                          }}
                                        />

                                        <span className="ms-2 d-flex">
                                          Update Tasks
                                        </span>
                                      </button>
                                    </div>
                                  </li>
                                  <li
                                    className="pt-2 pb-2"
                                    onClick={handleLinkClick}
                                  >
                                    <button
                                      class="dropdown-item d-flex projects_dropdownlinks"
                                      type="button"
                                      onClick={() => {
                                        setSelectedTask(row);
                                        handleDeleteShow();
                                      }}
                                    >
                                      <DeleteIcon
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />
                                      <span className="ms-2 d-flex">
                                        Delete
                                      </span>
                                    </button>
                                  </li>
                                </>
                              )}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <UpdateTaskModal />
          <UploadTaskModal />
          <TaskModal />
          <FilterModal />
          <Modal
            show={deleteshow}
            onHide={handleDeleteClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="deletebody">
              <div>
                <div className="">
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <div className="media d-flex">
                        <img
                          className="align-self-start mr-3"
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          src={cancel}
                          alt="no_img"
                        />
                        <div
                          className="media-body ms-3"
                          style={{
                            position: "relative",
                          }}
                        >
                          <h5 className="mt-0 alerts-heading text-start">
                            Are you sure?
                          </h5>
                          <p className="text-start">
                            Do you really want to delete these records ? This
                            process cannot be undone
                          </p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-end">
                      <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                        <input
                          type="button"
                          value="Cancel"
                          className="outline_btn"
                          onClick={handleDeleteClose}
                        ></input>
                        <input
                          type="button"
                          value="Delete"
                          className="delete_button"
                          ref={deleteButtonRef}
                          onClick={async () => {
                            postTask({
                              id: selectedTask?.id,
                              project: selectedTask?.project?.id ?? "",
                              assigned_to: selectedTask?.assigned_to.id ?? "",
                              title: selectedTask?.title ?? "",
                              start_date: selectedTask?.start_date ?? "",
                              end_date: selectedTask?.end_date ?? "",
                              created_by: selectedTask?.created_by.id,
                              active: "False",
                            })
                              .then((res) => {
                                toast.success("Task is deleted successfully.");
                                handleDeleteClose();
                                apiCall();
                              })
                              .catch((err) => {
                                toast.error(err.message);
                              });
                          }}
                        ></input>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showviewdoc}
            onHide={handleCloseViewDoc}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
          >
            <Modal.Header>
              <h6 className="view_header mt-2">image.png</h6>
              <span onClick={handleCloseViewDoc}>
                <DownloadViewIcon className="ms-4 download_icon" />
              </span>
              <CancelIcon
                onClick={handleCloseViewDoc}
                style={{ cursor: "pointer" }}
                className="close_icon"
              />
            </Modal.Header>
            <Modal.Body
              className="d-flex justify-content-center align-items-center pb-5"
              style={{
                background: "rgba(241, 241, 241, 1)",
              }}
            >
              <img
                src={sampleview_img}
                alt="no_img"
                style={{ width: "60%", maxWidth: "100%" }}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Tasks;
