import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { postChange_password, updateProfile } from "../api/service";
import { ReactComponent as Changepassword } from "../assets/img/changepassword.svg";
import close_icon from "../assets/img/close.svg";
import default_dp from "../assets/img/default_dp.png";
import { ReactComponent as Editprofile } from "../assets/img/edit.svg";
import { ReactComponent as Logouticon } from "../assets/img/logout.svg";
import { ReactComponent as SearchIcon } from "../assets/img/notification.svg";
import sidebarright from "../assets/img/sidebarright.svg";
import { postLogout } from "../api/service";
import { isPasswordValid } from "../components/utils";
import ChangePasswordModal from "./ChangePasswordModal";

const CustomHeader = ({ scrolling }) => {
  const [isToggled, setToggled] = useState(false);
  const searchIconColor = scrolling ? "#3C3C3C" : "#3C3C3C";
  const backgroundColor = scrolling ? "#fffff" : "#ffffff";
  const profilePic = localStorage.getItem("profile_pic");
  const fileInputRef = useRef(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditProfileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e?.target?.files?.length) {
      if (!e?.target?.files[0]?.type?.startsWith("image")) {
        toast.error("Please select images only");
        return;
      } else {
        let image = {
          preview: URL.createObjectURL(e?.target?.files[0]),
          raw: e?.target?.files[0],
        };
        let role = localStorage.getItem("role");
        let profileId = localStorage.getItem("profileid");
        let userid = localStorage.getItem("userid");
        const formData = new FormData();
        formData.append("id", profileId);
        formData.append("role", role);
        formData.append("user", userid);
        formData.append("active", true);
        if (image.raw) {
          formData.append("profile_pic", image.raw);
        }
        updateProfile(formData)
          .then((res) => {
            let profileUrl = res.profile_pic.split("media")[1];
            let updatedProfileUrl = `/media${profileUrl}`;
            localStorage.setItem("profile_pic", updatedProfileUrl);
            setCompleteProfilePicUrl(res.profile_pic);
            toast.success("Profile pic is updated");
          })
          .catch(() => {
            toast.error("Profile is not updated");
          });
      }
    }
  };
  const [completeProfilePicUrl, setCompleteProfilePicUrl] = useState(
    profilePic !== "null" && profilePic !== "" ? profilePic : default_dp
  );
  const handleToggle = () => {
    setToggled(!isToggled);

    const wrapper = document.getElementById("wrapper");
    if (wrapper) {
      wrapper.classList.toggle("toggled");
    }
  };

  // removing the token from backend and clearing the stored user info from localstorage
  function handleUserLogout() {
    postLogout()
      .then((res) => {
        toast.success(res.detail);
        localStorage.clear();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  return (
    <nav
      className={`navbar navbar-expand-md sticky-top ${
        scrolling ? "scrolled" : ""
      }`}
      style={{
        background: backgroundColor,
      }}
    >
      <div className="container-fluid">
        <div className="navbar-brand d-flex justify-content-start align-items-center">
          <span>
            {isToggled ? (
              <img
                className="sidebar-toggle"
                src={sidebarright}
                alt="no_img"
                id="menu-toggle"
                title="sidebar-close/open"
                style={{
                  cursor: "pointer",
                  fontSize: "30px",
                  color: "#fff",
                }}
                onClick={handleToggle}
              />
            ) : (
              <img
                className="sidebar-toggle"
                src={sidebarright}
                alt="no_img"
                id="menu-toggle"
                title="sidebar-close/open"
                style={{
                  cursor: "pointer",
                  fontSize: "30px",
                  color: "#fff",
                }}
                onClick={handleToggle}
              />
            )}
          </span>
        </div>
        <form className="d-flex gap-3" role="search">
          <div className="btn-group">
            <div
              className="profile-dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <SearchIcon
                style={{ cursor: "pointer" }}
                width="24"
                height="24"
                fill={searchIconColor}
                className="mt-2"
              />
            </div>
            <div className="dropdown-content dropdown-menu dropdown-menu-end custom_header">
              <div className="list-unstyled text-center">
                <h6>No Notifications</h6>
              </div>
            </div>
          </div>
          <div className="btn-group">
            <div
              className="profile-dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={completeProfilePicUrl}
                style={{ cursor: "pointer" }}
                alt="Avatar"
                className="avatar"
              ></img>
            </div>
            <div className="dropdown-content dropdown-menu dropdown-menu-end custom_header">
              <div className="list-unstyled text-center list-items">
                <div className="media d-flex">
                  <div onClick={handleEditProfileClick}>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                    <Editprofile
                      className="edit_icon_profile"
                      title="edit profile photo"
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                  <img
                    className="mr-3"
                    src={completeProfilePicUrl}
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                    }}
                    alt="no_img"
                  />
                  <div
                    className="media-body ms-3 userprofile-dropdown"
                    style={{ textAlign: "left" }}
                  >
                    <h5 className="mt-1 mb-1 profilename_heading">
                      {localStorage.getItem("username")}
                    </h5>
                    <p
                      className="profile-description"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      Role:
                      <b>{localStorage.getItem("role")}</b>
                    </p>
                  </div>
                </div>
                <hr className="profile_hr_tag" />
                <Link
                  onClick={handleShow}
                  className="label  d-flex justify-content-start-0 align-items-center"
                >
                  <Changepassword className="me-2" />
                  <span className="mt-0">Change Password</span>
                </Link>
                <div className="logout_link">
                  <Link
                    to="/login"
                    className="label d-flex justify-content-start-0 align-items-center"
                    style={{ marginBottom: "-12px" }}
                    onClick={handleUserLogout}
                  >
                    <Logouticon className="me-2" />
                    <span className="logout-text">Logout</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ChangePasswordModal show={show} handleClose={handleClose} />
    </nav>
  );
};

export default CustomHeader;
