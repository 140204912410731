import React from "react";
import { Row, Col } from "react-bootstrap";
import { ReactComponent as HomeIcon } from "../assets/img/home.svg";
import { sidebarItems } from "../Customcomponents/Customsidebar";
import { NavLink } from "react-router-dom";

function DashboardCard({ lable, to, icon }) {
  return (
    <NavLink to={to}>
      <div className="dashboardcard d-flex flex-column justify-content-center align-items-center">
        {icon && (
          <img
            src={icon}
            alt={lable}
            className="mb-2"
            style={{ height: "25px", width: "25px" }}
          />
        )}
        {lable}
      </div>
    </NavLink>
  );
}

function handleDashboardNavigation(menuitems, icon) {
  return menuitems?.map((menuitem) => {
    return menuitem.submenu
      ? handleDashboardNavigation(menuitem.submenu, menuitem.icon ?? icon)
      : menuitem.label?.toLowerCase() !== "home" && (
          <Col xs lg="3" md sm="6" className="mb-3">
            <DashboardCard
              lable={menuitem.label}
              to={menuitem.to}
              icon={menuitem.icon ?? icon}
            />
          </Col>
        );
  });
}

const Home = () => {
  const role = localStorage.getItem("role");
  return (
    <React.Fragment>
      <div class="banner-section">
        <div>
          <h4 class="headingfour">Home</h4>
        </div>
        <hr />
        <div class="banner-section-dashboard mt-2 mb-3">
          <div style={{ minHeight: "68vh" }}>
            <Row>{handleDashboardNavigation(sidebarItems[role])}</Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
