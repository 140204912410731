import React, { useState } from "react";

import Custompagenation from "../Customcomponents/Custompagenation";
import AddIcon from "@mui/icons-material/Add";
import close_icon from "../assets/img/close.svg";
import CloseIcon from "@mui/icons-material/Close";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
// import Button from "react-bootstrap/Button";
import search_icon from "../assets/img/search.svg";
import Form from "react-bootstrap/Form";
import location_icon from "../assets/img/location.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import menu_icon from "../assets/img/menu.svg";
// import view_icon from "../assets/img/view.svg";

// import copy_icon from "../assets/img/copy.svg";
import { ReactComponent as CopyIcon } from "../assets/img/copy.svg";
import { ReactComponent as PrintIcon } from "../assets/img/print.svg";
import { ReactComponent as ExportIcon } from "../assets/img/export.svg";
import { ReactComponent as SignoffIcon } from "../assets/img/signoff.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import { ReactComponent as ViewIcon } from "../assets/img/view.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import Custombuttons from "../Customcomponents/Custombuttons";

// import preexecution_icon from "../assets/img/preexecution.svg";
// import intial_icon from "../assets/img/initialprocrutment.svg";
// import queries_icon from "../assets/img/quaries.svg";

import Table from "react-bootstrap/Table";
import Customsearch from "../Customcomponents/Customsearch";
const Purchaselistitem = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showview, setShowView] = useState(false);

  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center mt-3">
          <div>
            <h4 class="headingfour">
              Projects
              <span style={{ fontSize: "18px" }}>
                / Initial Procurement List / List Items
              </span>
            </h4>
          </div>
          <div class="d-grid gap-2 d-md-flex">
            <a href="/purchaseintialprocurement">
              <button
                class="outline_btn d-flex flex-row"
                style={{ width: "120px" }}
              >
                <span>Back</span>
              </button>
            </a>
          </div>
        </div>
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <Customsearch />
          </div>
          <div className="table_section">
            <Table bordered responsive="lg">
              <thead className="table_head_bg">
                <tr>
                  <th scope="col">Sl.No</th>
                  <th scope="col">Material</th>
                  <th scope="col">Thickness</th>
                  <th scope="col">Type</th>
                  <th scope="col">Brand (if any)</th>
                  <th scope="col">Units Required</th>
                  <th scope="col">Date Required</th>
                  <th scope="col">Required At</th>
                  <th scope="col">Status</th>
                  <th scope="col">Units Sent</th>
                  <th scope="col">Date Sent</th>

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Plywood</td>
                  <td>18mm</td>
                  <td>MR</td>
                  <td>Century Sainik</td>
                  <td>20</td>
                  <td>15-12-2023</td>
                  <td>Factory</td>
                  <td>New</td>
                  <td>10</td>
                  <td>14-12-2023</td>

                  <td className="text-center">
                    <div class="btn-group dropend">
                      <span data-bs-toggle="dropdown" aria-expanded="false">
                        <MenuIcon alt="no_img" className="edit_icon" />
                      </span>
                      <ul class="dropdown-menu">
                        <li
                          style={{ borderBottom: "1px solid #CBCBCB" }}
                          className="pt-2 pb-2"
                        >
                          <Link onClick={handleShow}>
                            <button
                              class="dropdown-item d-flex projects_dropdownlinks"
                              type="button"
                            >
                              <EditIcon
                                style={{ height: "16px", width: "16px" }}
                              />

                              <span className="ms-2 d-flex">Edit</span>
                            </button>
                          </Link>
                        </li>
                        <li
                          style={{ borderBottom: "1px solid #CBCBCB" }}
                          className="pt-2 pb-2"
                        >
                          <Link onClick={handleShowView}>
                            <button
                              class="dropdown-item d-flex projects_dropdownlinks"
                              type="button"
                            >
                              <ViewIcon
                                style={{ height: "16px", width: "16px" }}
                              />

                              <span className="ms-2 d-flex">View</span>
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Custompagenation />
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">Update List Item</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group className="col-md-4">
                    <Form.Label>Material</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="plywood"
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4">
                    <Form.Label>Thickness</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4">
                    <Form.Label>Unit</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield"
                    >
                      <option>Select Status</option>
                      <option>one</option>
                      <option>two</option>
                      <option>three</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Units Required</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                      type="Century Sainik-"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Date Required</Form.Label>
                    <Form.Control
                      type="date"
                      className="inputfield form-control date-control"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Required At</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group
                    as={Col}
                    controlId="formGridPassword"
                    className="col-md-12"
                  >
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield"
                    >
                      <option>Select Status</option>
                      <option>one</option>
                      <option>two</option>
                      <option>three</option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Units Sent</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Date Sent</Form.Label>
                    <Form.Control
                      type="date"
                      className="inputfield form-control date-control"
                    />
                  </Form.Group>
                </Row>
                <hr />

                <div className="d-flex justify-content-start align-items-start gap-2">
                  <button class="add_employee_btn" style={{ width: "120px" }}>
                    Submit
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={showview}
            onHide={handleCloseView}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">Update List Item</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleCloseView}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group className="col-md-4">
                    <Form.Label>Material</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="plywood"
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4">
                    <Form.Label>Thickness</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4">
                    <Form.Label>Unit</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield"
                    >
                      <option>Select Status</option>
                      <option>one</option>
                      <option>two</option>
                      <option>three</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Units Required</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                      type="Century Sainik-"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Date Required</Form.Label>
                    <Form.Control
                      type="date"
                      className="inputfield form-control date-control"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Required At</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group
                    as={Col}
                    controlId="formGridPassword"
                    className="col-md-12"
                  >
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield"
                    >
                      <option>Select Status</option>
                      <option>one</option>
                      <option>two</option>
                      <option>three</option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Units Sent</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Date Sent</Form.Label>
                    <Form.Control
                      type="date"
                      className="inputfield form-control date-control"
                    />
                  </Form.Group>
                </Row>
                <hr />
                <Row className="mb-4">
                  <Form.Group className="col-md-12">
                    <div className="table_section">
                      <div className="table-responsive">
                        <table className="table table-bordered align-middle">
                          <thead className="table_head_bg">
                            <tr>
                              <th scope="col">Date Required</th>
                              <th scope="col">Units Required</th>
                              <th scope="col">Date Sent</th>
                              <th scope="col">Units Sent</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>22-12-2023</td>
                              <td>20</td>
                              <td>22-12-2023</td>
                              <td>10</td>

                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Form.Group>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Purchaselistitem;
