import React, { useEffect, useState } from "react";
import { getTask_history, postTask_history } from "../api/service";
import { toast } from "react-toastify";
import Custompagenation from "../Customcomponents/Custompagenation";
import { conDateToReadable } from "../components/utils";
import { Link } from "react-router-dom";
import DocumentViewModal from "../Customcomponents/DocumentViewModal";

export default function TaskHistory({ taskid }) {
  const [tableData, setTableData] = useState(null);
  const [selectedTaskHistory, setSelectedTaskHistory] = useState(null);
  const [showView, setShowView] = useState(false);
  const handleCloseView = () => {
    setShowView(false);
    setSelectedTaskHistory(null);
  };
  useEffect(() => {
    getTask_history({ active: "True", task: taskid })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);
  return (
    <>
      <table className="table table-bordered align-middle">
        <thead className="table_head_bg">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Percentage</th>
            <th scope="col">Status</th>
            <th scope="col">Comments</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Image</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.results.map((row) => {
            return (
              <tr>
                <td>{conDateToReadable(row.created_at)}</td>
                <td>{row.completion}</td>
                <td>{row.status}</td>
                <td>{row.comments}</td>
                <td>{conDateToReadable(row.start_date, false)}</td>
                <td>{conDateToReadable(row.end_date, false)}</td>
                <td class="active-links">
                  <span
                    class="footer_link"
                    onClick={() => {
                      setSelectedTaskHistory(row);
                      setShowView(true);
                    }}
                  >
                    View
                  </span>
                </td>
              </tr>
            );
          })}
          {tableData?.count === 0 && (
            <tr>
              <td colSpan={7} style={{ textAlign: "center" }}>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Custompagenation data={tableData} setData={setTableData} />
      <DocumentViewModal
        show={showView}
        onHide={handleCloseView}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="custom-modal-xl"
        handleCloseView={handleCloseView}
        links={selectedTaskHistory?.docs}
      />
    </>
  );
}
