// Sidebar.js
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AccountsIcon from "../assets/img/accounts.svg";
import CustomersIcon from "../assets/img/customeres.svg";
import employee from "../assets/img/employee_icon.svg";
import home_icon from "../assets/img/home.svg";
import logo from "../assets/img/logo.svg";
import ReportIcon from "../assets/img/report.svg";
import paymentdetials from "../assets/img/payment.svg";
import projectmanagement from "../assets/img/projectmanagement.svg";
import projectsicon from "../assets/img/projects.svg";
import newrequest from "../assets/img/queries_icon.svg";
import signoffdoc from "../assets/img/signoffdocu.svg";
import actualprocurement from "../assets/img/task.svg";
import teammembers from "../assets/img/teammembers.svg";
import projecttracking from "../assets/img/workdetials.svg";

const SidebarItem = ({ to, children, isDefault, onClick }) => {
  const location = useLocation();
  const isActive = isDefault
    ? location.pathname === "/home"
    : location.pathname.startsWith(to);
  return (
    <li
      className={`nav-link pills nav_link_hover ${
        isActive ? "active active-item" : ""
      }`}
    >
      <NavLink
        onClick={onClick}
        to={to}
        style={{
          alignItems: "center",
        }}
      >
        {children}
      </NavLink>
    </li>
  );
};

let sidebarItems = {
  admin: [
    {
      id: "1",
      to: "/home",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    { id: "2", to: "/employees", label: "Employee", icon: employee },
    {
      id: "3",
      to: "/salesprojects",
      label: "Projects",
      icon: projectsicon,
    },
  ],

  customer: [
    {
      id: "1",
      to: "/customerdashboard",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    {
      id: "2",
      to: "/projecttracking",
      label: "Project Tracking",
      icon: projecttracking,
    },
    {
      id: "3",
      to: "/paymentdetails",
      label: "Payment Details",
      icon: paymentdetials,
    },
    {
      id: "4",
      to: "/customersignoffdoc",
      label: "Sign Off Documents",
      icon: signoffdoc,
    },
    {
      id: "5",
      to: "/actualprocurement",
      label: "Actual Procurement",
      icon: actualprocurement,
    },
    { id: "6", to: "/newrequest", label: "New Request", icon: newrequest },
  ],
  sales_head: [
    {
      id: "1",
      to: "/home",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    {
      id: "2",
      to: "/customers",
      label: "Customers",
      icon: CustomersIcon,
    },
    {
      id: "3",
      to: "/salesprojects",
      label: "Projects",
      icon: projectsicon,
    },
    {
      id: "4",
      to: "/teammembers",
      label: "Team Members",
      icon: teammembers,
    },
    {
      id: "5",
      label: "Project Management",
      icon: projectmanagement,
      submenu: [
        {
          id: "5_1",
          to: "/tasks",
          label: "Tasks",
        },
      ],
    },
    { id: "6", to: "/civilqueries", label: "New Request", icon: newrequest },
  ],
  sales_team: [
    {
      id: "1",
      to: "/home",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    {
      id: "2",
      to: "/customers",
      label: "Customers",
      icon: CustomersIcon,
    },
    {
      id: "3",
      to: "/salesprojects",
      label: "Projects",
      icon: projectsicon,
    },
    {
      id: "5",
      label: "Project Management",
      icon: projectmanagement,
      submenu: [
        {
          id: "5_1",
          to: "/tasks",
          label: "Tasks",
        },
      ],
    },
    { id: "6", to: "/civilqueries", label: "New Request", icon: newrequest },
  ],
  civil_head: [
    {
      id: "1",
      to: "/home",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    {
      id: "2",
      to: "/salesprojects",
      label: "Projects",
      icon: projectsicon,
    },
    {
      id: "3",
      to: "/teammembers",
      label: "Team Members",
      icon: teammembers,
    },
    {
      id: "4",
      label: "Project Management",
      icon: projectmanagement,
      submenu: [
        {
          id: "4_1",
          to: "/tasks",
          label: "Tasks",
        },
        {
          id: "4_2",
          label: "Subcontractors",
          submenu: [
            {
              id: "4_2_2",
              to: "/subcontractortype",
              label: "Subcontractors Type",
            },
            {
              id: "4_2_1",
              to: "/subcontractorslist",
              label: "Subcontractors List",
            },
            {
              id: "4_2_3",
              to: "/subcontractorestimate",
              label: "Subcontractors Estimates",
            },
          ],
        },
      ],
    },
    {
      id: "5",
      label: "Accounts",
      icon: AccountsIcon,
      submenu: [
        {
          id: "5_1",
          to: "/pettycashexpense",
          label: "Petty Cash Expense",
        },
      ],
    },
    { id: "6", to: "/civilqueries", label: "New Request", icon: newrequest },
  ],
  civil_team: [
    {
      id: "1",
      to: "/home",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    {
      id: "2",
      to: "/salesprojects",
      label: "Projects",
      icon: projectsicon,
    },
    {
      id: "3",
      label: "Project Management",
      icon: projectmanagement,
      submenu: [
        {
          id: "3_1",
          to: "/tasks",
          label: "Tasks",
        },
        {
          id: "3_2",
          label: "Subcontractors",
          submenu: [
            {
              id: "3_2_1",
              to: "/subcontractorestimate",
              label: "Subcontractors Estimates",
            },
          ],
        },
      ],
    },
    {
      id: "4",
      label: "Accounts",
      icon: AccountsIcon,
      submenu: [
        {
          id: "4_1",
          to: "/pettycashexpense",
          label: "Petty Cash Expense",
        },
      ],
    },
    { id: "5", to: "/civilqueries", label: "New Request", icon: newrequest },
  ],
  purchase_head: [
    {
      id: "1",
      to: "/home",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    {
      id: "2",
      to: "/salesprojects",
      label: "Projects",
      icon: projectsicon,
    },
    {
      id: "3",
      to: "/teammembers",
      label: "Team Members",
      icon: teammembers,
    },
    {
      id: "4",
      label: "Project Management",
      icon: projectmanagement,
      submenu: [
        {
          id: "4_1",
          to: "/tasks",
          label: "Tasks",
        },
        {
          id: "4_2",
          label: "Vendors",
          submenu: [
            {
              id: "4_2_2",
              to: "/vendortypes",
              label: "Vendor Types",
            },
            {
              id: "4_2_1",
              to: "/vendorlist",
              label: "Vendor List",
            },
            {
              id: "4_2_3",
              to: "/vendorestimates",
              label: "Vendor Estimates",
            },
          ],
        },
      ],
    },
    {
      id: "5",
      label: "Accounts",
      icon: AccountsIcon,
      submenu: [
        {
          id: "5_1",
          to: "/pettycashexpense",
          label: "Petty Cash Expense",
        },
      ],
    },
  ],
  purchase_team: [
    {
      id: "1",
      to: "/home",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    {
      id: "2",
      to: "/salesprojects",
      label: "Projects",
      icon: projectsicon,
    },
    {
      id: "4",
      label: "Project Management",
      icon: projectmanagement,
      submenu: [
        {
          id: "4_1",
          to: "/tasks",
          label: "Tasks",
        },
        {
          id: "4_2",
          label: "Vendors",
          submenu: [
            {
              id: "4_2_1",
              to: "/vendorestimates",
              label: "Vendor Estimates",
            },
          ],
        },
      ],
    },
    {
      id: "5",
      label: "Accounts",
      icon: AccountsIcon,
      submenu: [
        {
          id: "5_1",
          to: "/pettycashexpense",
          label: "Petty Cash Expense",
        },
      ],
    },
  ],
  design_head: [
    {
      id: "1",
      to: "/home",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    {
      id: "2",
      to: "/salesprojects",
      label: "Projects",
      icon: projectsicon,
    },
    {
      id: "3",
      to: "/teammembers",
      label: "Team Members",
      icon: teammembers,
    },
    {
      id: "4",
      label: "Project Management",
      icon: projectmanagement,
      submenu: [
        {
          id: "4_1",
          to: "/tasks",
          label: "Tasks",
        },
      ],
    },
    { id: "5", to: "/civilqueries", label: "New Request", icon: newrequest },
  ],
  design_team: [
    {
      id: "1",
      to: "/home",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    {
      id: "2",
      to: "/salesprojects",
      label: "Projects",
      icon: projectsicon,
    },
    {
      id: "4",
      label: "Project Management",
      icon: projectmanagement,
      submenu: [
        {
          id: "4_1",
          to: "/tasks",
          label: "Tasks",
        },
      ],
    },
    { id: "5", to: "/civilqueries", label: "New Request", icon: newrequest },
  ],
  accounts: [
    {
      id: "1",
      to: "/home",
      label: "Home",
      icon: home_icon,
      isDefault: true,
    },
    {
      id: "2",
      to: "/salesprojects",
      label: "Projects",
      icon: projectsicon,
    },
    {
      id: "3",
      label: "Accounts",
      icon: AccountsIcon,
      submenu: [
        {
          id: "3_1",
          to: "/incomeandexpenses",
          label: "Income & Expenses",
        },
        {
          id: "3_2",
          to: "/pettycash",
          label: "Petty Cash",
        },
      ],
    },
    {
      id: "4",
      label: "Report",
      icon: ReportIcon,
      submenu: [
        {
          id: "4_1",
          to: "/cashbook",
          label: "Cash Book",
        },
        {
          id: "4_2",
          to: "/labourpaymentreport",
          label: "Labour Payment Report",
        },
      ],
    },
    { id: "5", to: "/civilqueries", label: "New Request", icon: newrequest },
  ],
};

const Sidebar = () => {
  const [isToggled, setToggled] = useState(false);
  const role = localStorage.getItem("role");

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" || event.key === "Escape") {
        closeSidebar();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const closeSidebar = () => {
    setToggled(false);
    // Close the sidebar when a NavLink is clicked
    const wrapper = document.getElementById("wrapper");
    if (wrapper) {
      wrapper.classList.remove("toggled");
    }
  };

  function handleSideMenuBar(menubarItems) {
    return menubarItems?.map((menubarItem, index) => {
      return menubarItem.submenu ? (
        <li className="nav-link pills" key={menubarItem.id}>
          <div className="accordion" id={`accordion${menubarItem.id}`}>
            <div className="accordion-item">
              <h2 className="accordion-header" id={`heading${menubarItem.id}`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${menubarItem.id}`}
                  aria-expanded="true"
                  aria-controls={`collapse${menubarItem.id}`}
                  style={{ fontWeight: 500, height: "40px" }}
                >
                  {menubarItem.icon && (
                    <img
                      src={menubarItem.icon}
                      className="me-3"
                      style={{ height: "14px", width: "14px" }}
                    />
                  )}
                  {menubarItem.label}
                </button>
              </h2>
              <div
                id={`collapse${menubarItem.id}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${menubarItem.id}`}
                data-bs-parent={`#accordion${menubarItem.id}`}
              >
                <div className="accordion-body">
                  {handleSideMenuBar(menubarItem.submenu)}
                </div>
              </div>
            </div>
          </div>
        </li>
      ) : (
        <SidebarItem
          to={menubarItem.to}
          isDefault={menubarItem.isDefault}
          onClick={closeSidebar}
          submenu={menubarItem.submenu}
          key={menubarItem.id}
        >
          {menubarItem.icon && (
            <img
              src={menubarItem.icon}
              alt="no_img"
              style={{ marginRight: "3px" }}
            />
          )}
          <span className="ms-2">{menubarItem.label}</span>
        </SidebarItem>
      );
    });
  }

  return (
    <div id="wrapper" className={isToggled ? "toggled" : ""}>
      {isToggled && <div className="overlay" onClick={closeSidebar}></div>}
      <div id="sidebar-wrapper">
        <div className="sidebar-brand mt-3 d-flex justify-content-start align-itemes-center ms-4">
          <h6 className="sidebar_heading">
            <img src={logo} alt="no_img" className="me-3 logo" />
            Avant
          </h6>
        </div>
        <hr />
        <ul className="sidebar-nav tab">
          {handleSideMenuBar(sidebarItems[role])}
        </ul>
      </div>
    </div>
  );
};

export { Sidebar as default, sidebarItems };
