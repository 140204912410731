import React, { useState } from "react";
import Form from "react-bootstrap/Form";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";

import { postUserLogin } from "../api/service";

const Login = ({ handleChangeToken }) => {
  const [loginUser, setLoginUser] = useState({
    username: "",
    password: "",
  });

  const handleNameChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setLoginUser({ ...loginUser, [name]: value });
  };

  const handleChangePassword = (event) => {
    let value = event.target.value;
    setLoginUser({ ...loginUser, password: value });
  };

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [isLoggedIn, setLoggedIn] = useState(false);

  const handleLogin = (event) => {
    event.preventDefault();
    if (loginUser.username === "") {
      toast.error("Please enter email");
      return;
    } else if (loginUser.password === "") {
      toast.error("Please enter password");
      return;
    } else {
      postUserLogin(loginUser)
        .then((res) => {
          toast.success("Logged in successfully");
          const storeToken = `Token ${res?.token}`;
          //setToken(storeToken);
          localStorage.setItem("token", storeToken);
          localStorage.setItem("username", res?.user_profile?.user?.username);
          localStorage.setItem("userid", res.id);
          localStorage.setItem("profileid", res.user_profile.id);
          localStorage.setItem("role", res?.user_profile?.role);

          localStorage.setItem(
            "profile_pic",
            res.user_profile.profile_pic
              ? `${process.env.REACT_APP_BASE_URL}${res.user_profile.profile_pic}`
              : null
          );
          setLoggedIn(true);
          handleChangeToken();
          // Redirect to the Dashboard component
          if (res?.user_profile?.role === "customer") {
            navigate("/customerdashboard");
          } else {
            navigate("/home");
          }
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <React.Fragment>
      <div class="login_section">
        <div class="content">
          <div id="form-main-container">
            <div id="form-area">
              <h4 class="headingfour mb-3 text-center text-white">Login</h4>

              <div id="form-body">
                <Form>
                  <Form.Group
                    className="mb-2 loginpage"
                    controlId="formBasicEmail"
                  >
                    <span className="text-white login_label_text">Email</span>
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder="Enter email"
                      className="login_formcontrol mt-1"
                      onChange={(event) => {
                        handleNameChange(event);
                      }}
                      autoComplete="false"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <span className="text-white login_label_text">
                      Password
                    </span>

                    <div style={{ position: "relative" }}>
                      <Form.Control
                        placeholder="Enter your password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={loginUser.password}
                        className="login_formcontrol"
                        onChange={(event) => {
                          handleChangePassword(event);
                        }}
                        style={{ paddingRight: "45px" }}
                        autoComplete="false"
                      />

                      <div
                        className="loginpassword_field"
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: "2%",
                          cursor: "pointer",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <VisibilityIcon
                            style={{ fontSize: "20px", color: "#C8C8C8" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            style={{ fontSize: "20px", color: "#C8C8C8" }}
                          />
                        )}
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="mb-4 text-end text-white"
                    controlId="formBasicCheckbox"
                  >
                    <Link
                      to="/forgotpassword"
                      className="label_text forgot_password_text"
                    >
                      Forgot Password?
                    </Link>
                  </Form.Group>
                  <div className="d-flex justify-content-center">
                    <input
                      type="submit"
                      onClick={handleLogin}
                      value="Login"
                      className="signup_button button_width"
                    />
                    {/*<Primarybutton name="Login" onClick={handleLogin} /> */}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
