import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Stack from "@mui/material/Stack";
import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";

export default function Multiselect({
  selectedAssignees,
  setselectedAssignees,
  assignees,
  setAssignees,
  dropdownVisible,
  setDropdownVisible,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setDropdownVisible]);

  function checkItem(assignee) {
    return selectedAssignees.some((item) => item.id === assignee.user.id);
  }

  function pushOrPopItem(item) {
    const existingIndex = selectedAssignees.findIndex((selectedAssignee) => selectedAssignee.id === item.id);
    if (existingIndex !== -1) {
      setselectedAssignees(selectedAssignees.filter((_, index) => index !== existingIndex));
    } else {
      setselectedAssignees([...selectedAssignees, item]);
    }
  }

  const filteredAssignees = assignees?.filter((assignee) =>
    assignee.user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleInputClick = (event) => {
    event.stopPropagation(); // Prevent click event from bubbling up to the parent div
    if (!dropdownVisible) {
      setDropdownVisible(true);
    }
  };

  return (
    <Col sm="12" md="12" xl="12">
      <div
        className="dropdown"
        style={{
          position: "relative",
          width: "100%",
          cursor: "pointer",
        }}
      >
        <div
          className="form-select d-flex justify-content-start-0 assignee_input inputfield"
          onClick={handleInputClick}
        >
          {assignees === null ? (
            "Please wait"
          ) : assignees.length === 0 ? (
            <span>No users found</span>
          ) : selectedAssignees.length === 0 ? (
            <span>Select the assignee</span>
          ) : (
            selectedAssignees.map((user) => (
              <Stack
                className="me-2"
                key={user.username}
                direction="row"
                alignItems="center"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <span>{user?.username ?? "NA"}</span>
              </Stack>
            ))
          )}
        </div>

        {dropdownVisible && (
          <div
            className="dropdown-content"
            style={{
              display: "block",
              position: "absolute",
              overflow: "auto",
              boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
              zIndex: "1",
              maxHeight: "200px",
              width: "100%",
              left: 0,
              overflow: "auto",
            }}
            ref={dropdownRef}
          >
            <div
              className="d-flex mb-2"
              style={{
                borderBottom: "1px solid gray",
              }}
            >
              <IconButton type="button" aria-label="search">
                <SearchIcon className="search-icon" />
              </IconButton>
              <InputBase
                placeholder="Search Assignee"
                inputProps={{
                  style: { fontWeight: 'normal' },
                  "aria-label": "search google maps",
                }}
                fullWidth
                value={searchQuery}
                onClick={handleInputClick} // Prevent click event from bubbling up
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {filteredAssignees?.length > 0 ? (
              filteredAssignees.map((assignee) => {
                return (
                  <div
                    key={assignee.user.id}
                    className="d-flex justify-content-start align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => pushOrPopItem(assignee.user)}
                  >
                    <Checkbox
                      sx={{
                        color: "gray",
                        "&.Mui-checked": {
                          color: "#008080;",
                        },
                      }}
                      checked={checkItem(assignee)}
                    />
                    <div>
                      {assignee.user.username}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-data-found-message">No data available</div>
            )}
          </div>
        )}
      </div>
    </Col>
  );
}
