import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Custompagenation from "../Customcomponents/Custompagenation";
import Customsearch from "../Customcomponents/Customsearch";
import { getProject_orders, postProject_orders } from "../api/service";
import close_icon from "../assets/img/close.svg";
import { conDateToReadable } from "../components/utils";

import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";

const Signoffdocument = () => {
  const location = useLocation();
  const projectId = location?.state?.projectId;

  const customerFname = location?.state?.customerFname;
  const customerLname = location?.state?.customerLname;
  const isProjectClosed = location?.state?.isProjectClosed;

  const [isEditing, setIsEditing] = useState(false);
  const [selectedProcurement, setSelectedProcurement] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const currentUserRole = localStorage.getItem("role");
  const currentUserId = localStorage.getItem("id");

  const hasAddEditProcurementPerm =
    ["civil_head", "civil_team"].includes(currentUserRole) && !isProjectClosed;

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setSelectedProcurement(null);
    setShow(false);
    setIsEditing(false);
  };
  const handleShow = () => setShow(true);

  const [showupdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleShowUpdate = () => setShowUpdate(true);

  function apiCall() {
    getProject_orders({ project: projectId, search: searchValue })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  useEffect(() => {
    apiCall();
  }, [searchValue]);

  function AddEditProcurementModal() {
    const [payload, setPayload] = useState({
      id: selectedProcurement?.id,
      name: selectedProcurement?.name,
      project: selectedProcurement?.project?.id ?? projectId,
      created_by: selectedProcurement?.created_by ?? currentUserId,
      updated_by: currentUserId,
    });
    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      postProject_orders(payload)
        .then((res) => {
          toast.success(`Procurement is ${isEditing ? "updated" : "added"}`);
          handleClose();
          apiCall();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">{isEditing ? "Edit" : "Add"} Procurement</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row className="mb-2">
              <Form.Group
                as={Col}
                controlId="formGridPassword"
                className="col-md-12"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  className="inputfield"
                  required
                  name="name"
                  onChange={handleInputChange}
                  value={payload.name}
                />
              </Form.Group>
            </Row>
            <hr />
            <div className="d-flex justify-content-start align-items-start gap-2">
              <button
                type="button"
                class="outline_btn"
                style={{ width: "120px" }}
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                class="add_employee_btn"
                style={{ width: "120px" }}
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
      <div className="d-flex justify-content-between align-items-center">
          <div>
            <ul>
              <li>
                <h6 className="breadcrumb_main_heading">
                  <Link to="/salesprojects">Projects</Link>
                </h6>
              </li>
              <li>
                <span className="breadcrumb_paragraph">  Procurement Request List</span>
              </li>
            </ul>
          </div>
          <div>
            <div className="d-flex justify-content-end list-group-item">
              <div className="d-flex justify-content-end pt-1 pb-1 gap-2 ">
              <div class="d-grid gap-2 d-md-flex">
            {hasAddEditProcurementPerm && (
              <div>
                <button
                  className="add_employee_btn d-flex flex-row"
                  onClick={handleShow}
                >
                  <AddIcon />
                  <span>Add Procurement</span>
                </button>
              </div>
            )}

            <Link to="/salesprojects">
              <button
                type="button"
                className="outline_btn"
                style={{ width: "120px" }}
              >
                Back
              </button>
            </Link>
          </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="d-flex justify-content-between align-items-center mt-3">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item headingfour">
                  <Link to="/salesprojects">{`Proj ${projectId}_${customerFname}_${customerLname}`}</Link>
                </li>
                <li
                  class="breadcrumb-item active m-auto"
                  aria-current="page"
                  style={{ fontSize: "18px" }}
                >
                  Procurement Request List
                </li>
              </ol>
            </nav>
          </div>
          <div class="d-grid gap-2 d-md-flex">
            {hasAddEditProcurementPerm && (
              <div>
                <button
                  className="add_employee_btn d-flex flex-row"
                  onClick={handleShow}
                >
                  <AddIcon />
                  <span>Add Procurement</span>
                </button>
              </div>
            )}

            <Link to="/salesprojects">
              <button
                type="button"
                className="outline_btn"
                style={{ width: "120px" }}
              >
                Back
              </button>
            </Link>
          </div>
        </div> */}
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">


        <div>
            <label classname="breadcrumb_description">
              Project Id:{" "}
              <span style={{ fontWeight: "400" }}>{`Proj ${projectId}`}</span>
            </label>
          </div>
          <div>
            <label classname="breadcrumb_description" style={{ display: "block", wordWrap: "break-word" }}>
              Customer Name:{" "}
              <span
                style={{ fontWeight: "400" }}
                className="breadcrumb_description_names"
              >{`${customerFname} ${customerLname}`}</span>
            </label>
          </div>

<hr/>
          <div class="d-flex justify-content-start align-items-center mb-3">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">
                      <div>Name</div>
                    </th>
                    <th scope="col">
                      <div>Status</div>
                    </th>
                    <th scope="col">
                      <div>Activity Date</div>
                    </th>
                    {hasAddEditProcurementPerm && (
                      <th scope="col">
                        <div>Action</div>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results.map((row) => {
                    return (
                      <tr>
                        <td class="active-links">
                          <Link
                            class="footer_link"
                            to="/civilintialprocurelist"
                            state={{
                              projectId: projectId,
                              orderId: row.id,
                              customerFname: customerFname,
                              customerLname: customerLname,
                              isProjectClosed: isProjectClosed,
                            }}
                          >
                            {row.name}
                          </Link>
                        </td>
                        <td>{row.status}</td>
                        <td>{conDateToReadable(row.updated_at, false)}</td>
                        {hasAddEditProcurementPerm && (
                          <td class="active-links">
                            <div class="btn-group dropend">
                              <span
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <MenuIcon alt="no_img" className="edit_icon" />
                              </span>
                              <ul class="dropdown-menu">
                                <li
                                  style={{ borderBottom: "1px solid #CBCBCB" }}
                                  className="pt-2 pb-2"
                                >
                                  <button
                                    class="dropdown-item d-flex projects_dropdownlinks"
                                    type="button"
                                    onClick={() => {
                                      setIsEditing(true);
                                      setSelectedProcurement(row);
                                      handleShow();
                                    }}
                                  >
                                    <EditIcon
                                      style={{ height: "18px", width: "18px" }}
                                    />
                                    <span className="ms-2 d-flex">Edit</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={4} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <AddEditProcurementModal />
          <Modal
            show={showupdate}
            onHide={handleCloseUpdate}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">Update Procurement List</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleCloseUpdate}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group
                    as={Col}
                    controlId="formGridPassword"
                    className="col-md-12"
                  >
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" className="inputfield" />
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group as={Col} className="col-md-12 pb-5">
                    <Form.Label>Document</Form.Label>
                    <div className="d-flex justify-content-start align-items-center gap-3">
                      <input type="file" id="file-upload" class="inputfile" />
                      <label
                        for="file-upload"
                        class="add_employee_btn d-flex flex-row"
                        style={{ cursor: "pointer" }}
                      >
                        <span>Upload file</span>
                      </label>
                    </div>
                  </Form.Group>
                </Row>
                <hr />

                <div className="d-flex justify-content-start align-items-start gap-2">
                  <button
                    class="outline_btn"
                    style={{ width: "120px" }}
                    onClick={handleCloseUpdate}
                  >
                    Cancel
                  </button>
                  <button
                    class="add_employee_btn"
                    style={{ width: "120px" }}
                    onClick={handleCloseUpdate}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Signoffdocument;
