import React, { useState, useEffect, useRef } from "react";
import Custompagenation from "../Customcomponents/Custompagenation";
import Modal from "react-bootstrap/Modal";
import close_icon from "../assets/img/close.svg";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as Add } from "../assets/img/add.svg";
import { ReactComponent as Deactiveicon } from "../assets/img/deactive.svg";
import { ReactComponent as Activeicon } from "../assets//img/active.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import Custombuttons from "../Customcomponents/Custombuttons";
import { Link } from "react-router-dom";
import { ListGroup, Table } from "react-bootstrap";
import Customsearch from "../Customcomponents/Customsearch";
import {
  isEmailValid,
  isPasswordValid,
  isMobileNumValid,
} from "../components/utils";

import {
  createUpdateUserProfile,
  getProfile,
  deleteUsersProfile,
} from "../api/service";
import { toast } from "react-toastify";
const Emplyoee = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setaddUserHeading("Add Employee");
    setFirstname("");
    setLastname("");
    setUsername("");
    setSelectedRole("admin");
    setEmail("");
    setMobile("");
    setPassword("");
    setConfirmPassword("");
    setEmployeeId("");
    setIsUpdateAPI(false);
    setShowPassword(false);
    setShowConfirmPassword(false);
  };
  const handleShow = () => setShow(true);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("admin");
  const [tableData, setTableData] = useState({});
  const [addUserHeading, setaddUserHeading] = useState("Add New Employee");
  const [userId, setUserId] = useState("");
  const [profileId, setProfileId] = useState(null);

  const [isUpdateAPI, setIsUpdateAPI] = useState(false);

  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [active, setActive] = useState("True");
  const [searchValue, setSearchValue] = useState("");
  const roles = [
    "admin",
    "sales_head",
    "civil_head",
    "purchase_head",
    "design_head",
    "accounts",
  ];

  const deleteButtonRef = useRef(null); // Reference to the delete button

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      deleteButtonRef.current.click(); // Trigger click event on the delete button
    }
  };

  useEffect(() => {
    if (deleteshow) {
      document.addEventListener("keydown", handleKeyPress);
      // Set focus on the delete button when the modal is shown
      deleteButtonRef.current.focus();
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [deleteshow]);

  // Run effect when deleteshow changes
  const rolesstr = roles.join(",");
  function updateCurrentUser(data) {
    setSelectedUser(data);
  }
  const EmployeeActions = ({ data }) => {
    const updateData = () => {
      setFirstname(data?.user?.first_name);
      setLastname(data?.user?.last_name);
      setUsername(data?.user?.username);
      setSelectedRole(data?.role);
      setEmail(data?.user?.email);
      setMobile(data?.mobile_no);
      setProfileId(data?.id);
      setUserId(data?.user.id);
      setEmployeeId(data.employee_id);
      setaddUserHeading("Edit Employee");
      setIsUpdateAPI(true);
      handleShow();
    };

    return (
      <div class="btn-group d-flex justify-content-center">
        <span data-bs-toggle="dropdown" aria-expanded="false">
          <MenuIcon alt="no_img" className="edit_icon" />
        </span>
        <ul class="dropdown-menu dropdown-menu-end">
          <li
            style={{ borderBottom: "1px solid #CBCBCB" }}
            className="pt-2 pb-2"
          >
            <Link to="#/">
              <button
                class="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                type="button"
                onClick={updateData}
              >
                <EditIcon style={{ height: "16px", width: "16px" }} />
                <span className="ms-2 ">Edit</span>
              </button>
            </Link>
          </li>
          <li className="pt-2 pb-2">
            <div>
              <button
                class="dropdown-item d-flex projects_dropdownlinks"
                type="button"
                onClick={(event) => {
                  if (data.active) {
                    updateCurrentUser(data);
                    handleDeleteShow();
                  } else {
                    deleteUser(data);
                  }
                }}
              >
                {data.active ? (
                  <Deactiveicon style={{ height: "18px", width: "18px" }} />
                ) : (
                  <Activeicon style={{ height: "18px", width: "18px" }} />
                )}
                <span className="ms-2 d-flex">
                  {data.active ? "Deactivate" : "Activate"}
                </span>
              </button>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const deleteUser = (data) => {
    const payload = {
      id: data?.id,
      role: data?.role,
      user: data?.user?.id,
      active: !data.active,
    };
    deleteUsersProfile(payload)
      .then((res) => {
        setData();
        handleDeleteClose();
        toast.success(
          `${data.user.username} is ${
            data.active ? "deactivated" : "activated"
          }`
        );
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const setData = () => {
    getProfile({ active: active, role: rolesstr, search: searchValue }).then(
      (res) => {
        setTableData({ ...res });
      }
    );
  };
  useEffect(() => {
    setData();
  }, [active, searchValue]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!isEmailValid(username)) {
      toast.error("Email is required");
      return;
    } else if (firstname === "" || lastname === "") {
      toast.error("Firstname and Lastname are required");
      return;
    } else if (!isMobileNumValid(mobile)) {
      toast.error("Please enter valid mobile number");
      return;
    } else if (
      isUpdateAPI == false &&
      (!isPasswordValid(password) || !isPasswordValid(confirmPassword))
    ) {
      toast.error(
        "Password and Confirm Password should have 8 characters at least 1 Capital letter, 1 Small letter, 1 number and 1 symbol"
      );
      return;
    } else if (isUpdateAPI == false && password !== confirmPassword) {
      toast.error("Password and Confirm Password didn't match");
      return;
    } else if (selectedRole === "") {
      toast.error("Please select the role");
      return;
    } else if (employeeId === "") {
      toast.error("Emp ID is required");
      return;
    }
    if (!isFormSubmitted) {
      setIsFormSubmitted(true);
      const formData = {
        username,
        firstname,
        lastname,
        email,
        mobile,
        password,
        confirmPassword,
        role: selectedRole,
        userId,
        profileId,
        employeeId,
      };

      createUpdateUserProfile(formData, isUpdateAPI)
        .then((res) => {
          const api_type = isUpdateAPI ? "Updated" : "Created";
          toast.success(`${api_type} User Successfully`);
          setData();
          handleClose();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsFormSubmitted(false);
        });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  function DeactivateUserModal() {
    return (
      <Modal
        show={deleteshow}
        onHide={handleDeleteClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="deletebody">
          <div>
            <div className="">
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <div className="media d-flex">
                    <Deactiveicon
                      className="align-self-start mr-3"
                      style={{
                        height: "40px",
                        width: "40px",
                      }}
                    />
                    <div
                      className="media-body ms-3"
                      style={{
                        position: "relative",
                      }}
                    >
                      <h5 className="mt-0 alerts-heading text-start">
                        Are you sure?
                      </h5>
                      <p className="text-start">
                        Do you really want to deactivate this record?
                      </p>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-end">
                  <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                    <input
                      type="button"
                      value="Cancel"
                      className="outline_btn"
                      onClick={handleDeleteClose}
                    ></input>
                    <input
                      type="button"
                      value="Deactivate"
                      className="delete_button"
                      ref={deleteButtonRef}
                      onClick={() => {
                        deleteUser(selectedUser);
                      }}
                    ></input>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Employees</h4>
          </div>
          <div>
            <Custombuttons
              label={"Add New Employee"}
              buttonIcon={Add}
              className="add_employee_btn"
              onClick={handleShow}
            />
            {/* <button
              className="add_employee_btn d-flex flex-row"
              onClick={handleShow}
            >
              <Add className="add_button" />
              <span className="addemployee_text"> Add New Employee</span>
            </button> */}
          </div>
        </div>
        <hr />

        <div className="banner-section-dashboard mb-4">
          <div className="d-flex justify-content-between align-items-center mb-3 gap-2 employee_buttons">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
            <div>
              <div class="tabs">
                <label class="tab">
                  <input
                    type="radio"
                    name="tab-input"
                    class="tab-input"
                    checked={active === "True"}
                    onClick={() => setActive("True")}
                  />
                  <div class="tab-box">Active</div>
                </label>
                <div className="line"></div>
                <label class="tab">
                  <input
                    type="radio"
                    name="tab-input"
                    class="tab-input"
                    checked={active === "False"}
                    onClick={() => setActive("False")}
                  />
                  <div class="tab-box">Inactive</div>
                </label>
              </div>
            </div>
          </div>
          <div>
            <div className="table_section">
              <Table
                bordered
                responsive="xxl"
                className="table table-bordered align-middle"
              >
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">Emp Id</th>
                    <th scope="col">Employee Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Role</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.results?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>{user.employee_id}</td>
                        <td>
                          {user.user.first_name} {user.user.last_name}
                        </td>
                        <td>{user.user.username}</td>
                        <td>{user.mobile_no}</td>
                        <td>{user.role}</td>
                        <td>{user.active ? "Active" : "Inactive"}</td>
                        <td>
                          <EmployeeActions data={user} />
                        </td>
                      </tr>
                    );
                  })}
                  {tableData.count === 0 && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <Custompagenation setData={setTableData} data={tableData} />

          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">{addUserHeading}</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleFormSubmit}>
                <Row className="mb-2">
                  <Form.Group controlId="formGridEmail" className="col-md-6">
                    <Form.Label>Emp ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter employee ID"
                      className="inputfield"
                      value={employeeId}
                      autocomplete="new-empid"
                      onChange={(e) => {
                        setEmployeeId(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>Role</Form.Label>
                    <Form.Select
                      value={selectedRole}
                      className="role inputfield"
                      onChange={(e) => setSelectedRole(e.target.value)}
                      required
                    >
                      {roles.map((role, index) => (
                        <option className={index}>{role}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group className="col-md-6" controlId="formGridEmail">
                    <Form.Label>Firstname</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter first name"
                      className="inputfield"
                      autocomplete="new-firstname"
                      value={firstname}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z \b]*$/;
                        if (regex.test(e.target.value)) {
                          setFirstname(e.target.value);
                        }
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="col-md-6" controlId="formGridPassword">
                    <Form.Label>Lastname</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter last name"
                      className="inputfield"
                      autocomplete="new-lastname"
                      value={lastname}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z \b]*$/;
                        if (regex.test(e.target.value)) {
                          setLastname(e.target.value);
                        }
                      }}
                      required
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group
                    className="mb-2 col-md-6"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      placeholder="Enter email id"
                      type="email"
                      autocomplete="new-email"
                      className="inputfield"
                      value={username}
                      disabled={isUpdateAPI}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-2 col-md-6"
                    controlId="formGridAddress2"
                  >
                    <Form.Label>Mobile no.</Form.Label>

                    <Form.Control
                      placeholder="Enter mobile number"
                      type="tel"
                      className="inputfield"
                      autoComplete="new-number" // Corrected attribute name to follow JSX syntax
                      value={mobile}
                      maxLength={10}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const regex = /^[0-9]*$/;
                        if (regex.test(inputValue) || inputValue === "") {
                          setMobile(inputValue);
                        }
                      }}
                      onKeyDown={(e) => {
                        const allowedChars = /[0-9]/;
                        const allowedKeyCombinations = {
                          "Control+c": true,
                          "Control+v": true,
                          "Control+a": true,
                        };

                        // Allow Backspace and Tab keys without restrictions
                        if (
                          e.key === "Backspace" ||
                          e.key === "Tab" ||
                          e.key === "ArrowLeft" ||
                          e.key === "ArrowRight" ||
                          e.key === "Escape"
                        ) {
                          return;
                        }

                        if (
                          !(
                            allowedChars.test(e.key) ||
                            allowedKeyCombinations[
                              `${
                                e.ctrlKey ? "Control+" : ""
                              }${e.key.toLowerCase()}`
                            ]
                          )
                        ) {
                          e.preventDefault(); // Prevent input if key is not allowed
                        }
                      }}
                      onPaste={(e) => {
                        const pastedText = e.clipboardData
                          .getData("text/plain")
                          .replace(/\D/g, ""); // Remove non-numeric characters
                      }}
                      required
                    />
                  </Form.Group>
                </Row>

                {!isUpdateAPI && (
                  <Row className="mb-2">
                    <Form.Group className="col-md-6" controlId="formGridCity">
                      <Form.Label>Password</Form.Label>

                      <div style={{ position: "relative" }}>
                        <Form.Control
                          placeholder="Enter password"
                          type={showPassword ? "text" : "password"}
                          className="inputfield"
                          value={password}
                          autocomplete="new-password"
                          onChange={(e) => setPassword(e.target.value)}
                          style={{ paddingRight: "40px" }}
                          required
                        />

                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: "3%",
                            cursor: "pointer",
                          }}
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <VisibilityIcon
                              className="icon"
                              style={{ fontSize: "20px", color: "#1F1F1F" }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              className="icon"
                              style={{ fontSize: "20px", color: "#1F1F1F" }}
                            />
                          )}
                        </div>
                      </div>
                    </Form.Group>

                    <Form.Group className="col-md-6" controlId="formGridState">
                      <Form.Label>Confirm Password</Form.Label>
                      <div style={{ position: "relative" }}>
                        <Form.Control
                          placeholder="Re-enter Password"
                          className="inputfield"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          autocomplete="new-password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          style={{ paddingRight: "40px" }}
                          required
                        />

                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: "3%",
                            cursor: "pointer",
                          }}
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ? (
                            <VisibilityIcon
                              className="icon"
                              style={{ fontSize: "20px", color: "#1F1F1F" }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              className="icon"
                              style={{ fontSize: "20px", color: "#1F1F1F" }}
                            />
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </Row>
                )}
                <hr />

                <div className="d-flex justify-content-center align-items-center">
                  <button class="add_employee_btn" style={{ width: "120px" }}>
                    {isUpdateAPI ? "Update" : "Create"}
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <DeactivateUserModal />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Emplyoee;
