import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Custompagenation from "../Customcomponents/Custompagenation";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import close_icon from "../assets/img/close.svg";
import search_icon from "../assets/img/search.svg";
import { ReactComponent as DownloadIcon } from "../assets/img/downloadtask.svg";
import { ReactComponent as UploadIcon } from "../assets/img/upload.svg";
import Custombuttons from "../Customcomponents/Custombuttons";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import cancel from "../assets/img/delete.svg";
import Table from "react-bootstrap/Table";
import { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import { ReactComponent as ViewIcon } from "../assets/img/view.svg";
import {
  getPointOrder_items,
  postOrder_items,
  postOrder_items_history,
  getOrder_items_history,
  postOrder_item_upload,
} from "../api/service";
import { toast } from "react-toastify";
import Customsearch from "../Customcomponents/Customsearch";
import { Col } from "react-bootstrap";
import SampleFile from "../assets/sample_procurement_list.xlsx";

const Civilintialprocurelist = () => {
  //previous page data
  const location = useLocation();
  const locationState = location.state;
  const navigate = useNavigate();

  const [tableData, setTableData] = useState(null);
  const [orderHistoryTableDate, setOrderHistoryTableData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const currentUserId = localStorage.getItem("userid");
  const currentUserRole = localStorage.getItem("role");
  const projectId = locationState?.projectId;
  const orderId = locationState?.orderId;

  const customerFname = locationState?.customerFname;
  const customerLname = locationState?.customerLname;
  const isProjectClosed = locationState?.isProjectClosed;

  if (!projectId || !orderId) {
    navigate("/salesprojects");
  }

  const hasAddEditProcurementPerm =
    ["civil_head", "civil_team"].includes(currentUserRole) && !isProjectClosed;
  const [selectedProcurement, setSelectedProcurement] = useState(null);
  const [isEditing, setIsEditing] = useState("Add");
  const [showUploadProcurment, setShowUploadProcurment] = useState(false);

  function handleShowUploadProcurment() {
    setShowUploadProcurment(true);
  }

  function handelCloseUploadProcurment() {
    setShowUploadProcurment(false);
  }

  const procurementMap = {
    View: "",
    Add: "",
    Edit: "",
    Update: "",
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setSelectedProcurement(null);
    setIsEditing("Add");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [showview, setShowView] = useState(false);

  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  const [showupdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleShowUpdate = () => setShowUpdate(true);

  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  function apiCall() {
    getPointOrder_items({ order: orderId, search: searchValue })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function getOrderItemsHistory(orderId) {
    getOrder_items_history({ item: orderId })
      .then((res) => {
        setOrderHistoryTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  //download sample task file
  function downloadSampleTaskFile(event) {
    event.preventDefault();
    const link = document.createElement("a");
    link.href = SampleFile;
    link.download = "sample.xlsx";
    link.click();
  }

  //upload procurement request modal
  function UploadProcurementModal() {
    const [uploadProcurementFile, setUploadProcurementFile] = useState(null);
    return (
      <Modal
        show={showUploadProcurment}
        onHide={handelCloseUploadProcurment}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Upload Procurement List</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handelCloseUploadProcurment}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-2">
              <Form.Group as={Col} className="col-md-12 pb-5">
                <Form.Label>Add Document</Form.Label>
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <div>
                    <input
                      type="file"
                      id="file-upload"
                      class="inputfile"
                      onChange={(event) => {
                        setUploadProcurementFile(event.target.files[0]);
                      }}
                      accept=".csv,.xls,.xlsx"
                    />
                    <label
                      for="file-upload"
                      class="add_employee_btn d-flex flex-row"
                      style={{ cursor: "pointer" }}
                    >
                      <span>
                        {uploadProcurementFile
                          ? `${uploadProcurementFile.name} is selected`
                          : "Select file"}
                      </span>
                    </label>
                  </div>
                </div>
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-start align-items-center gap-2 pt-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handelCloseUploadProcurment}
              ></input>
              <input
                type="button"
                value="Submit"
                className="signup_button"
                onClick={() => {
                  if (!uploadProcurementFile) {
                    toast.error("Please select the file to upload");
                  }
                  let formData = new FormData();
                  formData.append("order", parseInt(orderId));
                  formData.append("file", uploadProcurementFile);
                  postOrder_item_upload(formData)
                    .then((res) => {
                      toast.success("Items are added");
                      apiCall();
                      handelCloseUploadProcurment();
                    })
                    .catch((err) => {
                      toast.error(err.message);
                    });
                }}
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  useEffect(() => {
    apiCall();
  }, [searchValue]);

  function AddProcurementItem() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const isFieldDisabled = isEditing === "View" || isEditing === "Update";
    const showSubmitBut = isEditing !== "View" || isEditing === "Update";
    const [payload, setPayload] = useState({
      id: selectedProcurement?.id,
      material: selectedProcurement?.material,
      thickness: selectedProcurement?.thickness,
      requested_quantity: selectedProcurement?.requested_quantity,
      received_quantity: selectedProcurement?.received_quantity,
      brand: selectedProcurement?.brand,
      requested_date: selectedProcurement?.requested_date,
      requested_at: selectedProcurement?.requested_at,
      status: selectedProcurement?.status?.toLowerCase() ?? "pending",
      type: selectedProcurement?.type,
      created_by: selectedProcurement?.created_by ?? currentUserId,
      updated_by: currentUserId,
      order: orderId,
    });

    const [historyPayload, setHistoryPayload] = useState({
      item: selectedProcurement?.id,
      received_quantity: null,
      received_date: null,
      created_by: currentUserId,
      updated_by: currentUserId,
    });

    const statusOptions = ["partially fulfilled", "completed"];

    function handleChangeField(event) {
      let name = event.target.name;
      let value = event.target.value;
      if (isEditing === "Update") {
        setHistoryPayload({ ...historyPayload, [name]: value });
      } else {
        setPayload({ ...payload, [name]: value });
      }
    }
    function handleSubmitForm(event) {
      event.preventDefault();
      if (!isFormSubmitted) {
        setIsFormSubmitted(true);
        postOrder_items(payload)
          .then((res) => {
            setIsFormSubmitted(false);
            toast.success("Item is added");
            handleClose();
            apiCall();
          })
          .catch((err) => {
            setIsFormSubmitted(false);
            toast.error(err.message);
          });
      }
    }
    function handleUpdateSubmitForm(event) {
      event.preventDefault();
      if (
        historyPayload.received_quantity >
        payload.requested_quantity - payload.received_quantity
      ) {
        toast.error("You cant send units more than requested");
        return;
      }
      if (!isFormSubmitted) {
        setIsFormSubmitted(true);
        postOrder_items_history(historyPayload)
          .then((res) => {
            setIsFormSubmitted(false);
            toast.success("Item is updated");
            handleClose();
            apiCall();
          })
          .catch((err) => {
            setIsFormSubmitted(false);
            toast.error(err.message);
          });
      }
    }

    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">{isEditing} List Item</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={
              isEditing === "Update" ? handleUpdateSubmitForm : handleSubmitForm
            }
          >
            <Row className="mb-2">
              <Form.Group
                controlId="formGridPassword"
                className="col-lg-4 col-md-12"
              >
                <Form.Label>Material</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter material name"
                  className="inputfield"
                  name="material"
                  value={payload.material}
                  onChange={handleChangeField}
                  disabled={isFieldDisabled}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formGridPassword" className="col-md-4">
                <Form.Label>Thickness</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter thickness of the material"
                  className="inputfield col-lg-4 col-md-12"
                  name="thickness"
                  value={payload.thickness}
                  onChange={handleChangeField}
                  disabled={isFieldDisabled}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formGridPassword" className="col-md-4">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter type"
                  className="inputfield col-lg-4 col-md-12"
                  name="type"
                  value={payload.type}
                  onChange={handleChangeField}
                  disabled={isFieldDisabled}
                  required
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                controlId="formGridPassword"
                className="col-lg-4 col-md-12"
              >
                <Form.Label>Units Required</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter units required"
                  className="inputfield"
                  name="requested_quantity"
                  value={payload.requested_quantity}
                  onChange={handleChangeField}
                  disabled={isFieldDisabled}
                  required
                />
              </Form.Group>
              {isEditing === "Update" && (
                <Form.Group
                  controlId="formGridPassword"
                  className="col-lg-4 col-md-12"
                >
                  <Form.Label>Units Received</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Units received"
                    className="inputfield"
                    name="received_quantity"
                    value={payload.received_quantity}
                    onChange={handleChangeField}
                    disabled={isFieldDisabled}
                    required
                  />
                </Form.Group>
              )}
              <Form.Group
                controlId="formGridPassword"
                className="col-lg-4 col-md-12"
              >
                <Form.Label>Brand</Form.Label>
                <Form.Control
                  type="text"
                  className="inputfield col-lg-4 col-md-12"
                  name="brand"
                  placeholder="Enter brand"
                  value={payload.brand}
                  onChange={handleChangeField}
                  disabled={isFieldDisabled}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                controlId="formGridPassword"
                className="col-lg-6 col-md-12"
              >
                <Form.Label>Date Required</Form.Label>
                <Form.Control
                  type="date"
                  className="inputfield form-control date-control"
                  name="requested_date"
                  value={payload.requested_date}
                  onChange={handleChangeField}
                  disabled={isFieldDisabled}
                  required
                />
              </Form.Group>
              <Form.Group
                controlId="formGridPassword"
                className="col-lg-6 col-md-12"
              >
                <Form.Label>Required at</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter required at"
                  className="inputfield col-lg-4 col-md-12"
                  name="requested_at"
                  value={payload.requested_at}
                  onChange={handleChangeField}
                  disabled={isFieldDisabled}
                  required
                />
              </Form.Group>
            </Row>
            <hr />
            {isEditing === "Update" && (
              <>
                <Row className="mb-2">
                  <Form.Group
                    as={Col}
                    controlId="formGridPassword"
                    className="col-md-12"
                  >
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      className="inputfield"
                      name="status"
                      value={historyPayload.status}
                      onChange={handleChangeField}
                      required
                    >
                      <option hidden value="">
                        Select Status
                      </option>
                      {statusOptions.map((statusOption) => {
                        return (
                          <option value={statusOption}>{statusOption}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Units Sent</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      name="received_quantity"
                      value={historyPayload.received_quantity}
                      onChange={handleChangeField}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2"
                  >
                    <Form.Label>Date Sent</Form.Label>
                    <Form.Control
                      type="date"
                      className="inputfield form-control date-control"
                      name="received_date"
                      value={historyPayload.received_date}
                      onChange={handleChangeField}
                      required
                    />
                  </Form.Group>
                </Row>
              </>
            )}

            {showSubmitBut && (
              <div className="d-flex justify-content-start align-items-start gap-2">
                <button
                  type="submit"
                  class="add_employee_btn"
                  style={{ width: "120px" }}
                >
                  Submit
                </button>
              </div>
            )}
          </Form>
          {isEditing === "View" && (
            <Row className="mb-4">
              <Form.Group className="col-md-12">
                <div className="table_section">
                  <div className="table-responsive">
                    <table className="table table-bordered align-middle">
                      <thead className="table_head_bg">
                        <tr>
                          <th scope="col">Date Required</th>
                          <th scope="col">Units Required</th>
                          <th scope="col">Date Sent</th>
                          <th scope="col">Units Sent</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderHistoryTableDate?.results.map((row) => {
                          return (
                            <tr>
                              <td>{payload.requested_date}</td>
                              <td>{payload.requested_quantity}</td>
                              <td>{row.received_date}</td>
                              <td>{row.received_quantity}</td>
                              <td>{row.status}</td>
                            </tr>
                          );
                        })}
                        {orderHistoryTableDate?.count === 0 && (
                          <tr>
                            <td colSpan={5} style={{ textAlign: "center" }}>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Custompagenation
                      data={orderHistoryTableDate}
                      setData={setOrderHistoryTableData}
                    />
                  </div>
                </div>
              </Form.Group>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <ul>
              <li>
                <h6 className="breadcrumb_main_heading">
                  <Link to="/salesprojects">Projects</Link>
                </h6>
              </li>
              <li>
                <span className="breadcrumb_paragraph">
                  <Link
                    to="/civilintialprourement"
                    state={{
                      projectId: projectId,
                      customerFname: customerFname,
                      customerLname: customerLname,
                      isProjectClosed: isProjectClosed,
                    }}
                  >
                    Procurement Request List
                  </Link>{" "}
                  / List Items
                </span>
              </li>
            </ul>
          </div>
          <div>
            <div className="d-flex justify-content-end list-group-item">
              <div className="d-flex justify-content-end pt-1 pb-1 gap-2 ">
                <div class="d-flex-row gap-2 d-md-flex">
                  {hasAddEditProcurementPerm && (
                    <>
                      <Custombuttons
                        label={"Download Sheet Template"}
                        buttonIcon={DownloadIcon}
                        onClick={downloadSampleTaskFile}
                      />
                      <Custombuttons
                        label={"Upload Procurement list"}
                        buttonIcon={UploadIcon}
                        onClick={handleShowUploadProcurment}
                      />
                      <Custombuttons
                        label="Add List Items"
                        buttonIcon={AddIcon}
                        onClick={handleShow}
                      />
                    </>
                  )}
                  <Link
                    to="/civilintialprourement"
                    state={{
                      projectId: projectId,
                      customerFname: customerFname,
                      customerLname: customerLname,
                      isProjectClosed: isProjectClosed,
                    }}
                  >
                    <button
                      type="button"
                      className="outline_btn"
                      style={{ width: "120px" }}
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div>
            <label classname="breadcrumb_description">
              Project Id:{" "}
              <span style={{ fontWeight: "400" }}>{`Proj ${projectId}`}</span>
            </label>
          </div>
          <div>
            <label
              classname="breadcrumb_description"
              style={{ display: "block", wordWrap: "break-word" }}
            >
              Customer Name:{" "}
              <span
                style={{ fontWeight: "400" }}
                className="breadcrumb_description_names"
              >{`${customerFname} ${customerLname}`}</span>
            </label>
          </div>
          <hr />
          <div class="d-flex justify-content-start align-items-center mb-3">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
          <div className="table_section">
            <Table bordered responsive="lg">
              <thead className="table_head_bg">
                <tr>
                  <th scope="col">Sl No.</th>
                  <th scope="col">Material</th>
                  <th scope="col">Thickness</th>
                  <th scope="col">Type</th>
                  <th scope="col">Brand (if any)</th>
                  <th scope="col">Units Required</th>
                  <th scope="col">Date Required</th>
                  <th scope="col">Required at</th>
                  <th scope="col">Status</th>
                  <th scope="col">Units Received</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {tableData?.results.map((row, index) => {
                  return (
                    <tr>
                      <td>{`${index + 1}.`}</td>
                      <td>{row.material}</td>
                      <td>{row.thickness}</td>
                      <td>{row.type}</td>
                      <td>{row.brand}</td>
                      <td>{row.requested_quantity}</td>
                      <td>{row.requested_date}</td>
                      <td>{row.requested_at}</td>
                      <td>{row.status}</td>
                      <td>{row.received_quantity}</td>
                      <td>
                        <div class="btn-group dropend">
                          <span data-bs-toggle="dropdown" aria-expanded="false">
                            <MenuIcon alt="no_img" className="edit_icon" />
                          </span>
                          <ul class="dropdown-menu">
                            {hasAddEditProcurementPerm && (
                              <>
                                <li
                                  style={{
                                    borderBottom: "1px solid #CBCBCB",
                                  }}
                                  className="pt-2 pb-2"
                                >
                                  <button
                                    class="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                                    type="button"
                                    onClick={() => {
                                      setIsEditing("Edit");
                                      setSelectedProcurement(row);
                                      handleShow();
                                    }}
                                  >
                                    <EditIcon
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                      }}
                                    />
                                    <span className="ms-2 ">Edit</span>
                                  </button>
                                </li>
                                <li
                                  style={{
                                    borderBottom: "1px solid #CBCBCB",
                                  }}
                                  className="pt-2 pb-2"
                                >
                                  <button
                                    class="dropdown-item d-flex projects_dropdownlinks"
                                    type="button"
                                    onClick={handleDeleteShow}
                                  >
                                    <DeleteIcon
                                      style={{
                                        height: "18px",
                                        width: "18px",
                                      }}
                                    />
                                    <span className="ms-2 d-flex">Delete</span>
                                  </button>
                                </li>
                              </>
                            )}
                            {((!isProjectClosed &&
                              currentUserRole === "purchase_head") ||
                              currentUserRole === "purchase_team") && (
                              <li
                                style={{ borderBottom: "1px solid #CBCBCB" }}
                                className="pt-2 pb-2"
                              >
                                <button
                                  class="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                                  type="button"
                                  onClick={() => {
                                    setIsEditing("Update");
                                    setSelectedProcurement(row);
                                    handleShow();
                                  }}
                                >
                                  <EditIcon
                                    style={{ height: "16px", width: "16px" }}
                                  />
                                  <span className="ms-2 ">Update</span>
                                </button>
                              </li>
                            )}
                            <li
                              style={{ borderBottom: "1px solid #CBCBCB" }}
                              className="pt-2 pb-2"
                            >
                              <button
                                class="dropdown-item d-flex projects_dropdownlinks"
                                type="button"
                                onClick={() => {
                                  getOrderItemsHistory(row.id);
                                  setIsEditing("View");
                                  setSelectedProcurement(row);
                                  handleShow();
                                }}
                              >
                                <ViewIcon
                                  style={{ height: "18px", width: "18px" }}
                                />
                                <span className="ms-2 d-flex">View</span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {tableData?.count === 0 && (
                  <tr>
                    <td colSpan={12} style={{ textAlign: "center" }}>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <AddProcurementItem />
          <UploadProcurementModal />
          <Modal
            show={showview}
            onHide={handleCloseView}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">Edit List Item</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleCloseView}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-lg-4 col-md-12"
                  >
                    <Form.Label>Material</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="Plywood"
                    />
                  </Form.Group>
                  <Form.Group controlId="formGridPassword" className="col-md-4">
                    <Form.Label>Thickness</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield col-lg-4 col-md-12"
                      value="18"
                    />
                  </Form.Group>
                  <Form.Group controlId="formGridPassword" className="col-md-4">
                    <Form.Label>Unit</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield col-lg-4 col-md-12"
                    >
                      <option>mm</option>
                      <option>one</option>
                      <option>two</option>
                      <option>three</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-lg-4 col-md-12"
                  >
                    <Form.Label>Units Required</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-lg-4 col-md-12"
                  >
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield col-lg-4 col-md-12"
                      value="Century Sainik"
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-lg-6 col-md-12"
                  >
                    <Form.Label>Date Required</Form.Label>
                    <Form.Control
                      type="date"
                      className="inputfield form-control date-control"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-lg-6 col-md-12"
                  >
                    <Form.Label>Required at</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield col-lg-4 col-md-12"
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-5">
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-md-12"
                  >
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield"
                    >
                      <option></option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <hr />

                <div className="d-flex justify-content-start align-items-start gap-2">
                  <button class="add_employee_btn" style={{ width: "120px" }}>
                    Submit
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={showupdate}
            onHide={handleCloseUpdate}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">View List Item</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleCloseUpdate}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-lg-4 col-md-12"
                  >
                    <Form.Label>Material</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="Plywood"
                    />
                  </Form.Group>
                  <Form.Group controlId="formGridPassword" className="col-md-4">
                    <Form.Label>Thickness</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield col-lg-4 col-md-12"
                      value="18"
                    />
                  </Form.Group>
                  <Form.Group controlId="formGridPassword" className="col-md-4">
                    <Form.Label>Unit</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield col-lg-4 col-md-12"
                    >
                      <option>mm</option>
                      <option>one</option>
                      <option>two</option>
                      <option>three</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-lg-4 col-md-12"
                  >
                    <Form.Label>Units Required</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield"
                      value="18"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-lg-4 col-md-12"
                  >
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield col-lg-4 col-md-12"
                      value="Century Sainik"
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-lg-6 col-md-12"
                  >
                    <Form.Label>Date Required</Form.Label>
                    <Form.Control
                      type="date"
                      className="inputfield form-control date-control"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-lg-6 col-md-12"
                  >
                    <Form.Label>Required at</Form.Label>
                    <Form.Control
                      type="text"
                      className="inputfield col-lg-4 col-md-12"
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-5">
                  <Form.Group
                    controlId="formGridPassword"
                    className="col-md-12"
                  >
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield"
                    >
                      <option></option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <hr />
                <Row className="mb-4">
                  <Form.Group className="col-md-12">
                    <div className="table_section">
                      <div className="table-responsive">
                        <table className="table table-bordered align-middle">
                          <thead className="table_head_bg">
                            <tr>
                              <th scope="col">Date Required</th>
                              <th scope="col">Units Required</th>
                              <th scope="col">Date Sent</th>
                              <th scope="col">Units Sent</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>22-12-2023</td>
                              <td>20</td>

                              <td>22-12-2023</td>
                              <td>10</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Form.Group>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={deleteshow}
            onHide={handleDeleteClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="deletebody">
              <div>
                <div className="">
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <div className="media d-flex">
                        <img
                          className="align-self-start mr-3"
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          src={cancel}
                          alt="no_img"
                        />
                        <div
                          className="media-body ms-3"
                          style={{
                            position: "relative",
                          }}
                        >
                          <h5 className="mt-0 alerts-heading text-start">
                            Are you sure?
                          </h5>
                          <p className="text-start">
                            Do you really want to delete these records ? This
                            process cannot be undone
                          </p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-end">
                      <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                        <input
                          type="button"
                          value="Cancel"
                          className="outline_btn"
                          onClick={handleDeleteClose}
                        ></input>
                        <input
                          type="button"
                          value="Delete"
                          className="delete_button"
                          onClick={handleDeleteClose}
                        ></input>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Civilintialprocurelist;
