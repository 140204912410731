import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Custombuttons from "../Customcomponents/Custombuttons";
import Custompagenation from "../Customcomponents/Custompagenation";
import Customsearch from "../Customcomponents/Customsearch";
import DocumentViewModal from "../Customcomponents/DocumentViewModal";
import {
  getProject,
  getProject_payment,
  getSubCategories,
  postDrivem,
  postPayments,
  postProject_payment,
} from "../api/service";
import { ReactComponent as Add } from "../assets/img/add.svg";
import close_icon from "../assets/img/close.svg";
import cancel, { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import {
  getIds,
  headAndTeamMembers,
  paymentCategories,
} from "../components/utils";
import CustomFileSelector from "../Customcomponents/CustomFileSelector";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Accounts = () => {
  const currentUserRole = localStorage.getItem("role");
  const currentUserId = localStorage.getItem("userid");
  const [projects, setProjects] = useState(null);
  const [categories, setCategories] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [tableData, setTableData] = useState(null);

  const [show, setShow] = useState(false);
  const [selectedPettyCashExpense, setSelectedPettyCashExpense] =
    useState(null);
  const [showDocs, setShowDocs] = useState(false);

  const handleClose = () => {
    setSelectedPettyCashExpense(null);
    setShow(false);
    setIsEditing(false);
  };
  const handleShow = () => setShow(true);
  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => {
    setSelectedPettyCashExpense(null);
    setDeleteShow(false);
  };
  const handleDeleteShow = () => setDeleteShow(true);
  const [searchValue, setSearchValue] = useState("");

  const deleteButtonRef = useRef(null); // Reference to the delete button

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      deleteButtonRef.current.click(); // Trigger click event on the delete button
    }
  };

  useEffect(() => {
    if (deleteshow) {
      document.addEventListener("keydown", handleKeyPress);
      // Set focus on the delete button when the modal is shown
      deleteButtonRef.current.focus();
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [deleteshow]); // Run effect when deleteshow changes

  function AddPettyCashExpenseModal() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [prevDocuments, setPrevDocuments] = useState([
      ...(selectedPettyCashExpense?.docs ?? []),
    ]);
    const [documents, setDocuments] = useState([]);

    const [paymentPayload, setPaymentPayload] = useState({
      id: selectedPettyCashExpense?.payment?.id,
      type: "petty-cash",
      category: "expense",
      mode: "cash",
      amount: selectedPettyCashExpense?.payment?.amount ?? "",
      comments: selectedPettyCashExpense?.payment?.comments ?? "",
      created_by:
        selectedPettyCashExpense?.payment?.created_by?.id ?? currentUserId,
      updated_by:
        selectedPettyCashExpense?.payment?.created_by?.id ?? currentUserId,
    });
    const [payload, setPayload] = useState({
      id: selectedPettyCashExpense?.id,
      project: selectedPettyCashExpense?.project.id ?? "",
      payment_date: selectedPettyCashExpense?.payment_date ?? "",
      category: selectedPettyCashExpense?.category ?? "",
    });
    function handlePaymentPayloadInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPaymentPayload({ ...paymentPayload, [name]: value });
    }
    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (prevDocuments.length === 0 && documents.length === 0) {
        toast.error("Please select files to upload");
        return;
      }
      if (!isFormSubmitted) {
        setIsFormSubmitted(true);
        let documentsPrms = [];
        for (let index = 0; index < documents?.length; index++) {
          let form = new FormData();
          form.append("file", documents[index]);
          form.append("categories", categories.category?.id);
          form.append("subcategories", categories?.id);
          documentsPrms.push(postDrivem(form));
        }
        Promise.all(documentsPrms).then((documentsRes) => {
          let docIds = documentsRes.map((documentRes) => {
            return documentRes.id;
          });
          let prevDocIds = getIds(prevDocuments);
          postPayments(paymentPayload)
            .then((res) => {
              postProject_payment({
                ...payload,
                payment: res.id,
                docs: [...docIds, ...prevDocIds],
              })
                .then((res) => {
                  toast.success(
                    `Petty cash expense is ${isEditing ? "updated" : "added"}`
                  );
                  handleClose();
                  setIsFormSubmitted(false);
                  apiCall();
                })
                .catch((err) => {
                  setIsFormSubmitted(false);
                  toast.error(err.message);
                });
            })
            .catch((err) => {
              toast.error(err.message);
            });
        });
      }
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">
            {isEditing ? "Edit" : "Add"} Petty Cash Expense
          </h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row>
              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label>Project</Form.Label>
                <Form.Select
                  className="inputfield"
                  name={"project"}
                  value={payload?.project}
                  onChange={handleInputChange}
                  required
                >
                  <option hidden value="">
                    Select Project
                  </option>
                  {projects?.map((project) => {
                    return (
                      !(
                        project.status === "project on hold" ||
                        project.status === "project closed"
                      ) && (
                        <option
                          value={project.id}
                        >{`Proj ${project.id}_${project.customer?.first_name}_${project.customer?.last_name}`}</option>
                      )
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  className="inputfield"
                  name={"category"}
                  value={payload.category}
                  onChange={handleInputChange}
                  required
                >
                  <option hidden value="">
                    Select Category
                  </option>
                  {paymentCategories.map((pettyCashExpenseCategory) => {
                    return (
                      <option value={pettyCashExpenseCategory}>
                        {pettyCashExpenseCategory}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                className="col-lg-6 col-md-12 mb-2"
                controlId="formGridEmail"
              >
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  className="inputfield form-control date-control"
                  name="payment_date"
                  value={payload.payment_date}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  placeholder=""
                  type="number"
                  className="inputfield"
                  name="amount"
                  value={paymentPayload.amount}
                  onChange={handlePaymentPayloadInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} className="col-md-12">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  placeholder="Enter Description"
                  name="comments"
                  value={paymentPayload.comments}
                  onChange={handlePaymentPayloadInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group as={Col} className="col-md-12">
                <Form.Label>Add File</Form.Label>
                <CustomFileSelector
                  selectedFiles={documents}
                  setSelectedFiles={setDocuments}
                  prevSelectedFiles={prevDocuments}
                  setPrevSelectedFiles={setPrevDocuments}
                />
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-start align-items-center gap-2 pt-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleClose}
              ></input>
              <input
                type="submit"
                value="Submit"
                className="signup_button"
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  function getProjects() {
    let payload = { pagination: false, active: "True" };
    if (Object.values(headAndTeamMembers).includes(currentUserRole)) {
      payload["users_assigned"] = currentUserId;
    }
    getProject(payload)
      .then((res) => {
        setProjects(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  function getCategoryAndSubCategory() {
    getSubCategories({ category__name: "SubContractor", name: "Estimates" })
      .then((res) => {
        if (res?.results.length > 0) {
          setCategories(res.results[0]);
        } else {
          toast.error("Add SubContractor and Estimates in admin panel");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  function apiCall() {
    getProject_payment({ active: "True", search: searchValue })
      .then((res) => {
        setTableData(res);
        console.log("response is", res);
      })
      .catch((err) => {
        console.log("err", err.message);
      });
  }

  useEffect(() => {
    getProjects();
    getCategoryAndSubCategory();
  }, []);

  useEffect(() => {
    apiCall();
  }, [searchValue]);

  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Petty Cash Expense</h4>
          </div>
          <div className="d-flex gap-2">
            <Custombuttons
              label={"Add Petty Cash Expense"}
              buttonIcon={Add}
              className="add_employee_btn"
              onClick={handleShow}
            />
          </div>
        </div>
        <hr />

        <div className="banner-section-dashboard  mb-3">
          <div class="d-flex justify-content-start align-items-center mb-3 gap-2">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col" style={{ width: "150px" }}>
                      Project
                    </th>
                    <th scope="col">Expense Category</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Document</th>
                    <th scope="col" style={{ width: "100px" }}>
                      Details
                    </th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results.map((row) => {
                    return (
                      <tr>
                        <td>{row.payment_date}</td>
                        <td>
                          {" "}
                          <LightTooltip
                            title={`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>{row.category}</td>
                        <td>{row.payment.amount}</td>
                        <td class="active-links">
                          <span
                            class="footer_link"
                            onClick={() => {
                              setSelectedPettyCashExpense(row);
                              setShowDocs(true);
                            }}
                          >
                            View
                          </span>
                        </td>
                        <td>
                          <LightTooltip
                            title={row.payment.comments}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {row.payment.comments}
                            </div>
                          </LightTooltip>
                        </td>

                        <td>
                          {!(
                            row.project.status === "project on hold" ||
                            row.project.status === "project closed"
                          ) && (
                            <div className="d-flex justify-content-center align-items-center gap-2">
                              <LightTooltip
                                title="Edit"
                                arrow
                                placement="bottom"
                              >
                                <EditIcon
                                  style={{ color: "#008080" }}
                                  className="edit_icon"
                                  onClick={() => {
                                    setSelectedPettyCashExpense(row);
                                    setIsEditing(true);
                                    setShow(true);
                                  }}
                                />
                              </LightTooltip>
                              <LightTooltip
                                title="Edit"
                                arrow
                                placement="bottom"
                              >
                                <DeleteIcon
                                  onClick={() => {
                                    setSelectedPettyCashExpense(row);
                                    handleDeleteShow();
                                  }}
                                  style={{ color: "#008080", fontSize: "20px" }}
                                  className="edit_icon"
                                />
                              </LightTooltip>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <DocumentViewModal
            show={showDocs}
            onHide={() => {
              setShowDocs(false);
              setSelectedPettyCashExpense(null);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
            handleCloseView={() => {
              setShowDocs(false);
              setSelectedPettyCashExpense(null);
            }}
            links={selectedPettyCashExpense?.docs}
          />
          <AddPettyCashExpenseModal />

          <Modal
            show={deleteshow}
            onHide={handleDeleteClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="deletebody">
              <div>
                <div className="">
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <div className="media d-flex">
                        <img
                          className="align-self-start mr-3"
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          src={cancel}
                          alt="no_img"
                        />
                        <div
                          className="media-body ms-3"
                          style={{
                            position: "relative",
                          }}
                        >
                          <h5 className="mt-0 alerts-heading text-start">
                            Are you sure?
                          </h5>
                          <p className="text-start">
                            Do you really want to delete these record ? This
                            process cannot be undone
                          </p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-end">
                      <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                        <input
                          type="button"
                          value="Cancel"
                          className="outline_btn"
                          onClick={handleDeleteClose}
                        ></input>
                        <input
                          type="button"
                          value="Delete"
                          className="delete_button"
                          ref={deleteButtonRef}
                          onClick={() => {
                            let payload = {
                              id: selectedPettyCashExpense?.id,
                              payment: selectedPettyCashExpense?.payment.id,
                              docs: getIds(selectedPettyCashExpense.docs),
                              category: selectedPettyCashExpense?.category,
                              payment_date:
                                selectedPettyCashExpense?.payment_date,
                              active: false,
                              created_by: selectedPettyCashExpense?.created_by,
                              updated_by: currentUserId,
                              project: selectedPettyCashExpense?.project?.id,
                            };
                            postProject_payment(payload)
                              .then((res) => {
                                toast.success("Petty cash expense is deleted");
                                handleDeleteClose();
                                apiCall();
                              })
                              .catch((err) => {
                                toast.error(err.message);
                              });
                          }}
                        ></input>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Accounts;
