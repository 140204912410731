import React from "react";

const Uploadbutton = (props) => {
  return (
    <div>
      <input type="file" id="file-upload" class="inputfile" />
      <label
        for="file-upload"
        class="add_employee_btn d-flex flex-row"
        style={{ cursor: "pointer" }}
      >
        <span>{props.name}</span>
      </label>
    </div>
  );
};

export default Uploadbutton;
