import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import CustomPagination from "../Customcomponents/Custompagenation";
import Customsearch from "../Customcomponents/Customsearch";
import { ReactComponent as Add } from "../assets/img/add.svg";
import close_icon from "../assets/img/close.svg";
import cancel, { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { toast } from "react-toastify";
import Custombuttons from "../Customcomponents/Custombuttons";
import {
  getProfile,
  createUpdateUserProfile,
  deleteUsersProfile,
} from "../api/service";
import { isPasswordValid, isEmailValid } from "../components/utils";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const CustomerList = () => {
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const deleteButtonRef = useRef(null); // Reference to the delete button

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      deleteButtonRef.current.click(); // Trigger click event on the delete button
    }
  };

  const currentUserRole = localStorage.getItem("role");
  const isSalesHead = currentUserRole === "sales_head";

  //api call
  function apiCall() {
    getProfile({ role: "customer", active: "True", search: searchValue })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  useEffect(() => {
    apiCall();
  }, [searchValue]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => {
    setSelectedUser(null);
    setDeleteShow(false);
  };
  const handleDeleteShow = () => setDeleteShow(true);

  useEffect(() => {
    if (selectedUser) {
      document.addEventListener("keydown", handleKeyPress);
      // Set focus on the delete button when the modal is shown
      deleteButtonRef.current.focus();
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [deleteshow === true]); // Run effect when deleteshow changes

  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [tableData, setTableData] = useState(null);

  const handleClose = () => {
    setIsEditing(false);
    setSelectedUser(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  function AddCustomerModal() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(
        (prevShowConfirmPassword) => !prevShowConfirmPassword
      );
    };
    const [initalPayload, setInitalPayload] = useState({
      firstname: selectedUser?.user.first_name ?? "",
      lastname: selectedUser?.user.last_name ?? "",
      username: selectedUser?.user.email ?? "",
      mobile: `${selectedUser?.mobile_no ?? ""}`,
      password: "",
      confirm_password: "",
      role: "customer",
    });
    useEffect(() => {
      if (isEditing) {
        setInitalPayload({
          ...initalPayload,
          userId: selectedUser?.user.id,
          profileId: selectedUser?.id,
        });
      }
    }, []);
    function handleChangeField(event) {
      let name = event.target.name;
      let value = event.target.value;
      setInitalPayload({ ...initalPayload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (initalPayload.firstname === "") {
        toast.error("Please enter firstname");
        return;
      } else if (initalPayload.lastname === "") {
        toast.error("Please enter lastname");
        return;
      } else if (!isEmailValid(initalPayload.username)) {
        toast.error("Please enter valid email");
        return;
      } else if (initalPayload.mobile.length !== 10) {
        toast.error("Please enter valid mobile number");
        return;
      } else if (!isEditing && !isPasswordValid(initalPayload.password)) {
        toast.error(
          "Password should have 8 characters at least 1 Capital letter, 1 Small letter, 1 number and 1 symbol"
        );
        return;
      } else if (
        !isEditing &&
        !isPasswordValid(initalPayload.confirm_password)
      ) {
        toast.error(
          "Confirm should have 8 characters at least 1 Capital letter, 1 Small letter, 1 number and 1 symbol"
        );
        return;
      } else if (
        !isEditing &&
        initalPayload.password !== initalPayload.confirm_password
      ) {
        toast.error("Password and confirm password didnt match");
        return;
      }
      if (!isSubmitted) {
        setIsSubmitted(true);
        createUpdateUserProfile(initalPayload, isEditing)
          .then((res) => {
            toast.success(
              isEditing
                ? "Customer details are updated"
                : "New customer is added"
            );
            handleClose();
            setIsSubmitted(false);
            apiCall();
          })
          .catch((err) => {
            setIsSubmitted(false);
            toast.error(err.message);
          });
      }
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">{isEditing ? "Edit" : "Add"} Customer</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row>
              <Form.Group
                className="col-lg-6 col-md-12 mb-2"
                controlId="formGridEmail"
              >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  className="inputfield"
                  name={"firstname"}
                  value={initalPayload.firstname}
                  onChange={handleChangeField}
                  required
                />
              </Form.Group>

              <Form.Group
                className="col-lg-6 col-md-12 mb-2"
                controlId="formGridPassword"
              >
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  className="inputfield"
                  name={"lastname"}
                  value={initalPayload.lastname}
                  onChange={handleChangeField}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 col-md-12 mb-2"
                controlId="formGridAddress1"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Enter email id"
                  type="email"
                  className="inputfield"
                  name={"username"}
                  value={initalPayload.username}
                  onChange={handleChangeField}
                  autoComplete="new-email"
                  disabled={isEditing}
                  required
                />
              </Form.Group>
              <Form.Group
                className="col-lg-6 col-md-12 mb-2"
                controlId="formGridAddress2"
              >
                <Form.Label>Mobile no.</Form.Label>
                <Form.Control
                  placeholder="Enter mobile number"
                  type="tel"
                  className="inputfield"
                  name={"mobile"}
                  value={initalPayload.mobile}
                  maxLength={10}
                  onChange={handleChangeField}
                  onKeyDown={(e) => {
                    const allowedChars = /[0-9]/;
                    const allowedKeyCombinations = {
                      "Control+c": true,
                      "Control+v": true,
                      "Control+a": true,
                    };

                    // Allow Backspace and Tab keys without restrictions
                    if (
                      e.key === "Backspace" ||
                      e.key === "Tab" ||
                      e.key === "ArrowLeft" ||
                      e.key === "ArrowRight" ||
                      e.key === "Escape"
                    ) {
                      return;
                    }

                    if (
                      !(
                        allowedChars.test(e.key) ||
                        allowedKeyCombinations[
                          `${e.ctrlKey ? "Control+" : ""}${e.key.toLowerCase()}`
                        ]
                      )
                    ) {
                      e.preventDefault(); // Prevent input if key is not allowed
                    }
                  }}
                  onPaste={(e) => {
                    const pastedText = e.clipboardData
                      .getData("text/plain")
                      .replace(/\D/g, ""); // Remove non-numeric characters
                  }}
                  required
                />
              </Form.Group>
            </Row>

            {!isEditing && (
              <Row>
                <Form.Group
                  className="col-lg-6 col-md-12 mb-2"
                  controlId="formGridCity"
                >
                  <Form.Label>Password</Form.Label>

                  <div style={{ position: "relative" }}>
                    <Form.Control
                      placeholder="Enter password"
                      type={showPassword ? "text" : "password"}
                      className="inputfield"
                      name="password"
                      value={initalPayload.password}
                      onChange={handleChangeField}
                      style={{ paddingRight: "40px" }}
                      autoComplete="new-password"
                      required
                    />

                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "3%",
                        cursor: "pointer",
                      }}
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <VisibilityIcon
                          className="icon"
                          style={{ fontSize: "20px", color: "#1F1F1F" }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          className="icon"
                          style={{ fontSize: "20px", color: "#1F1F1F" }}
                        />
                      )}
                    </div>
                  </div>
                </Form.Group>

                <Form.Group
                  className="col-lg-6 col-md-12 mb-2"
                  controlId="formGridState"
                >
                  <Form.Label>Confirm Password</Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      placeholder="Re-enter password"
                      className="inputfield"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirm_password"
                      value={initalPayload.confirm_password}
                      onChange={handleChangeField}
                      style={{ paddingRight: "40px" }}
                      required
                    />

                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "3%",
                        cursor: "pointer",
                      }}
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon
                          className="icon"
                          style={{ fontSize: "20px", color: "#1F1F1F" }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          className="icon"
                          style={{ fontSize: "20px", color: "#1F1F1F" }}
                        />
                      )}
                    </div>
                  </div>
                </Form.Group>
              </Row>
            )}

            <hr />

            <div className="d-flex justify-content-center align-items-center">
              <input
                type="submit"
                value={isEditing ? "Save" : "Create"}
                className="signup_button"
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  const deleteUser = (data) => {
    const payload = {
      id: data?.id,
      role: data?.role,
      user: data?.user?.id,
      active: !data.active,
    };
    deleteUsersProfile(payload)
      .then((res) => {
        handleDeleteClose();
        toast.success(`${data.user.username} is deleted`);
        apiCall();
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Customers</h4>
          </div>
          <div>
            <Custombuttons
              label={"Add New Customer"}
              buttonIcon={Add}
              className="add_employee_btn"
              onClick={handleShow}
            />
            {/* <button
              className="add_employee_btn d-flex flex-row"
              onClick={handleShow}
            >
              <Add />
              <span> Add New Customer</span>
            </button> */}
          </div>
        </div>
        <hr />
        <div className="banner-section-dashboard  mb-3">
          <div className="d-flex justify-content-between align-items-center mb-3 gap-2">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col" style={{ width: "150px" }}>
                      Customer Name
                    </th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results?.map((row) => {
                    return (
                      <tr key={row.id}>
                        <td>
                          <LightTooltip
                            title={`${row.user.first_name} ${row.user.last_name}`}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {`${row.user.first_name} ${row.user.last_name}`}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>{row.user.email ?? "--"}</td>
                        <td>{row.mobile_no}</td>
                        <td>{row.active ? "Active" : "Inactive"}</td>
                        <td>
                          <div className="d-flex justify-content-center align-content-center gap-2">
                            <LightTooltip title="Edit" arrow placement="bottom">
                              <EditIcon
                                style={{ color: "#008080" }}
                                className="edit_icon"
                                onClick={() => {
                                  setIsEditing(true);
                                  setSelectedUser(row);
                                  setShow(true);
                                }}
                              />
                            </LightTooltip>
                            {isSalesHead && (
                              <LightTooltip
                                title="Delete"
                                arrow
                                placement="bottom"
                              >
                                <DeleteIcon
                                  onClick={() => {
                                    setSelectedUser(row);
                                    handleDeleteShow();
                                  }}
                                  style={{ color: "#008080", fontSize: "20px" }}
                                  className="edit_icon"
                                />
                              </LightTooltip>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={5} className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <CustomPagination data={tableData} setData={setTableData} />
          <Modal
            show={deleteshow}
            onHide={handleDeleteClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="deletebody">
              <div>
                <div className="">
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <div className="media d-flex">
                        <img
                          className="align-self-start mr-3"
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          src={cancel}
                          alt="no_img"
                        />
                        <div
                          className="media-body ms-3"
                          style={{
                            position: "relative",
                          }}
                        >
                          <h5 className="mt-0 alerts-heading text-start">
                            Are you sure?
                          </h5>
                          <p className="text-start">
                            Do you really want to delete these record ? This
                            process cannot be undone
                          </p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-end">
                      <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                        <input
                          type="button"
                          value="Cancel"
                          className="outline_btn"
                          onClick={handleDeleteClose}
                        ></input>
                        <input
                          type="button"
                          value="Delete"
                          className="delete_button"
                          ref={deleteButtonRef}
                          onClick={() => deleteUser(selectedUser)}
                        ></input>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <AddCustomerModal />
      </div>
    </React.Fragment>
  );
};

export default CustomerList;
