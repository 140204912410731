import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Custompagenation from "../Customcomponents/Custompagenation";
import close_icon from "../assets/img/close.svg";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ReactComponent as ExportIcon } from "../assets/img/export.svg";
import { ReactComponent as FilterIcon } from "../assets/img/filter.svg";
import Custombuttons from "../Customcomponents/Custombuttons";
import Customdate from "../Customcomponents/Customdate";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  baseProject_payment,
  getProject_payment,
  getProject,
  getProfile,
  getCashbook_report,
} from "../api/service";
import { toast } from "react-toastify";
import {
  cashbookTypes,
  paymentMethods,
  conDateToReadable,
  paymentCategories,
} from "../components/utils";
import ExportAsModal from "../Customcomponents/ExportAsModal";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const CashBook = () => {
  const [report, setReport] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [updatedFilters, setUpdatedFilters] = useState({
    payment__category: "",
    payment__type: "",
    project: "",
    payment_date: "",
    category: "",
    payment__created_by: "",
    payment__mode: "",
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showExport, setShowExport] = useState(false);
  function handleCloseExport() {
    setShowExport(false);
  }

  function getUserName(userId) {
    for (let index = 0; index < users.length; index++) {
      if (users[index].user.id == userId) {
        return `${users[index].user?.username}`;
      }
    }
    return users.find((user) => user.id === userId);
  }

  function apiCall() {
    let modifiedFilters = JSON.parse(JSON.stringify(updatedFilters));
    if (
      modifiedFilters.payment__category === "income" ||
      modifiedFilters.payment__category === "expense"
    ) {
      modifiedFilters.payment__type = "payment";
      modifiedFilters.payment__category = modifiedFilters.payment__category;
    } else if (
      modifiedFilters.payment__category === "petty cash expense" ||
      modifiedFilters.payment__category === "petty cash credit"
    ) {
      modifiedFilters.payment__type = "petty-cash";
      modifiedFilters.payment__category =
        modifiedFilters.payment__category === "petty cash expense"
          ? "expense"
          : "income";
    }
    getProject_payment({ active: "True", ...modifiedFilters })
      .then((res) => {
        getCashbook_report().then((rep) => {
          setReport(rep);
        });
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function getProjectsAndUsers() {
    let projectPrm = getProject({ pagination: false });
    let usersPrm = getProfile({ pagination: false });
    Promise.all([projectPrm, usersPrm])
      .then(([projectRes, usersRes]) => {
        setProjects(projectRes);
        setUsers(usersRes);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  useEffect(() => {
    apiCall();
  }, [updatedFilters]);

  useEffect(() => {
    getProjectsAndUsers();
  }, []);

  function FilterModal() {
    const [filters, setFilters] = useState(updatedFilters);
    function handleFilterChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setFilters({ ...filters, [name]: value });
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Filter</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-4">
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="payment__category"
                  value={filters.payment__category}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Type</option>
                  {cashbookTypes.map((cashbookType) => {
                    return <option value={cashbookType}>{cashbookType}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Project</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="project"
                  value={filters.project}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Project</option>
                  {projects.map((project) => {
                    return (
                      <option
                        value={project.id}
                      >{`Proj ${project.id}_${project.customer?.first_name}_${project.customer?.last_name}`}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Payment Date</Form.Label>
                <Customdate
                  name="payment_date"
                  value={filters.payment_date}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="category"
                  value={filters.category}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Category</option>
                  {paymentCategories.map((paymentCategory) => {
                    return (
                      <option value={paymentCategory}>{paymentCategory}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Created By</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="payment__created_by"
                  value={filters.payment__created_by}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select created by</option>
                  {users.map((user) => {
                    return (
                      <option value={user?.user?.id}>
                        {user?.user?.username}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Payment Type</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="payment__mode"
                  value={filters.payment__mode}
                  onChange={handleFilterChange}
                >
                  <option>Select Payment type</option>
                  {paymentMethods.map((paymentMethod) => {
                    return (
                      <option value={paymentMethod}>{paymentMethod}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-center align-items-start gap-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleClose}
              ></input>
              <input
                type="button"
                value="Apply"
                className="signup_button"
                onClick={() => {
                  setUpdatedFilters(filters);
                  handleClose();
                }}
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  function getCashbookType(payment) {
    if (payment.type === "petty-cash") {
      if (payment.category === "expense") {
        return "Petty cash expense";
      } else if (payment.category === "income") {
        return "Petty cash credit";
      }
    } else {
      return payment.category;
    }
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div>
          <h4 className="headingfour">Cash Book</h4>
        </div>
        <hr />

        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-4 mb-3 text-center">
          <div class="col">
            <div class="card h-100 cashback_card">
              <div class="card-body">
                <h6 class="text-center card_text">
                  Total Expenses : {report?.total_expense ?? 0}
                </h6>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100 cashback_card">
              <div class="card-body">
                <h6 class="text-center card_text">
                  Total Income : {report?.total_income ?? 0}
                </h6>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100 cashback_card">
              <div class="card-body">
                <h6 class="text-center card_text">
                  Total Petty Cash Expense :
                  {report?.total_petty_cash_expense ?? 0}
                </h6>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100 cashback_card">
              <div class="card-body">
                <h6 class="text-center card_text">
                  Total Petty Cash Credit :
                  {report?.total_petty_cash_credit ?? 0}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="banner-section-dashboard mt-2 mb-3">
          <div class="d-flex justify-content-between align-items-center mb-3 gap-2">
            <div className="d-flex flex-wrap align-items-center mb-3 gap-2">
              <button
                className="add_employee_btn d-flex flex-row"
                onClick={handleShow}
              >
                <FilterIcon />
                <span>Add Filter</span>
              </button>
              <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
                {Object.keys(updatedFilters).map((key) => {
                  if (key !== "active" && updatedFilters[key] !== "")
                    return (
                      <div class="chip ms-1 me-1">
                        <div class="chip-content">
                          {key === "project"
                            ? `Proj ${updatedFilters[key]}`
                            : key === "payment__created_by" ||
                              key === "payment__receiver"
                            ? getUserName(updatedFilters[key])
                            : updatedFilters[key]}
                        </div>
                        <div
                          class="chip-close"
                          onClick={() => {
                            setUpdatedFilters({ ...updatedFilters, [key]: "" });
                          }}
                        >
                          <svg
                            class="chip-svg"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                          </svg>
                        </div>
                      </div>
                    );
                })}
              </div>
            </div>
            <div>
              <div class="d-flex gap-2 d-md-flex">
                <Custombuttons
                  label={"Export As"}
                  buttonIcon={ExportIcon}
                  onClick={() => {
                    setShowExport(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Type</th>
                    <th scope="col" style={{ width: "150px" }}>
                      Project
                    </th>
                    <th scope="col">Amount</th>
                    <th scope="col">Category</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Payment Type</th>
                    <th scope="col" style={{ width: "150px" }}>
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results?.map((row) => {
                    return (
                      <tr>
                        <td>{conDateToReadable(row.created_at)}</td>
                        <td>{getCashbookType(row?.payment ?? {})}</td>
                        <td>
                          <LightTooltip
                            title={`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>{row?.payment.amount ?? "--"}</td>
                        <td>{row.category ?? "--"}</td>
                        <td>{row?.payment?.created_by?.username ?? "--"}</td>
                        <td>{row?.payment.mode ?? "--"}</td>
                        <td>
                          <LightTooltip
                            title={row?.payment.comments ?? "--"}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {row?.payment.comments ?? "--"}
                            </div>
                          </LightTooltip>
                        </td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={8} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <FilterModal />
          <ExportAsModal
            url={`${baseProject_payment}?active=True&pagination=false`}
            showExport={showExport}
            fileName={"cashbook"}
            handleCloseExport={handleCloseExport}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CashBook;
