import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as DownloadViewIcon } from "../assets/img/downloadviewicon.svg";
import { ReactComponent as CancelIcon } from "../assets/img/cancel.svg";
import { ReactComponent as LeftArrowIcon } from "../assets/img/left_arrow.svg";
import { ReactComponent as RightArrowIcon } from "../assets/img/right_arrow.svg";

export default function DocumentViewModal(props) {
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  let currentFileUrl = `${props.links?.[currentFileIndex]?.file}`;
  let fileName = currentFileUrl.split("/")?.at(-1);
  function changeCurrentFileIndex(name) {
    if (name === "next") {
      if (props?.links?.length - 1 > currentFileIndex) {
        setCurrentFileIndex(currentFileIndex + 1);
      }
    } else {
      if (currentFileIndex > 0) {
        setCurrentFileIndex(currentFileIndex - 1);
      }
    }
  }
  return (
    <Modal {...props}>
      <Modal.Header>
        <h6 className="view_header mt-2">
          {props.links && props.links.length > 0 ? fileName : "No files"}
        </h6>
        {props.links && props.links.length > 0 && (
          <span>
            <DownloadViewIcon
              className="ms-4 download_icon"
              onClick={(event) => {
                fetch(currentFileUrl)
                  .then((res) => {
                    return res.blob();
                  })
                  .then((data) => {
                    const a = document.createElement("a");
                    a.href = window.URL.createObjectURL(data);
                    a.download = fileName;
                    a.click();
                  });
              }}
            />
          </span>
        )}
        <CancelIcon
          onClick={props.handleCloseView}
          style={{ cursor: "pointer" }}
          className="close_icon"
        />
      </Modal.Header>
      <Modal.Body
        className="d-flex justify-content-center align-items-center"
        style={{
          background: "rgba(241, 241, 241, 1)",
        }}
        id="modalbody"
      >
        {props.links && props.links.length > 0 ? (
          <div>
            {!currentFileIndex <= 0 && (
              <LeftArrowIcon
                className="preview_icons"
                onClick={(event) => changeCurrentFileIndex("prev")}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "20px",
                  transform: "translateY(-50%)",
                  height:"33px",
                  width:'33px'
                 
                }}
              />
            )}
            <img
              src={`${props.links?.[currentFileIndex]?.file}`}
              alt="no_img"
              style={{
                width: "87%",
                maxWidth: "100%",
                display: "block",
                margin: "0 auto",
               
              }}
            />
            {!(currentFileIndex >= props.links?.length - 1) && (
              <RightArrowIcon
                className="preview_icons"
                onClick={(event) => changeCurrentFileIndex("next")}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "20px",
                  transform: "translateY(-50%)",
                  height:"33px",
                  width:'33px'
                 
                }}
              />
            )}
          </div>
        ) : (
          <>No files are there</>
        )}
      </Modal.Body>
    </Modal>
  );
}
