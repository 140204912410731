import React from "react";
import Form from "react-bootstrap/Form";
const Customdate = (props) => {
  return (
    <React.Fragment>
      <Form.Control
        type="date"
        className="inputfield form-control date-control"
        {...props}
      />
    </React.Fragment>
  );
};

export default Customdate;
