import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { request } from "../api/service";
import { toast } from "react-toastify";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  color: "#fff",
});

export default function UsePagination({ data, setData }) {
  const { items, page } = usePagination({
    count: Math.ceil(data?.count / 10),
    defaultPage: 1,
  });

  const handleButtonClick = (event, page) => {
    if ((data.next || data.previous) && page > 0) {
      let urlWithSearchParams = new URL(data.next ?? data.previous);
      urlWithSearchParams.searchParams.set("page", page);
      request({ method: "GET", url: urlWithSearchParams, requireToken: true })
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          toast.error(err.detail);
        });
    }
  };

  return (
    <nav className="d-flex justify-content-end mt-4">
      <List className="gap-2">
        {items.map(({ page, type, selected, disabled, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                className={`pagination_btn${selected ? " active" : ""}`}
                onClick={(e) => handleButtonClick(e, page)}
                {...item}
                disabled={disabled}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button
                type="button"
                className={`pagination_btn ${
                  type === "previous" ? "prev_button" : "next_button"
                } ${disabled ? "disabled" : ""}`}
                onClick={(e) => handleButtonClick(e, page)}
                {...item}
                disabled={disabled}
              >
                {type === "previous" ? "Prev" : "Next"}
              </button>
            );
          }

          return (
            <li key={index} onClick={(e) => handleButtonClick(e, page)}>
              {children}
            </li>
          );
        })}
      </List>
    </nav>
  );
}
