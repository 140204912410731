import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { postChange_password } from "../api/service";
import close_icon from "../assets/img/close.svg";
import { isPasswordValid } from "../components/utils";

export default function ChangePasswordModal({ show, handleClose }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const toggleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword(
      (prevShowConfirmNewPassword) => !prevShowConfirmNewPassword
    );
  };
  function handleSubmitForm(event) {
    if (password === "" || newPassword === "" || confirmPassword === "") {
      toast.error("Please will all the required fields");
      return;
    } else if (!isPasswordValid(newPassword)) {
      toast.error(
        "New Password should have 8 characters at least 1 Capital letter, 1 Small letter, 1 number and 1 symbol"
      );
      return;
    } else if (!isPasswordValid(confirmPassword)) {
      toast.error(
        "Confirm Password should have 8 characters at least 1 Capital letter, 1 Small letter, 1 number and 1 symbol"
      );
      return;
    } else if (newPassword === password) {
      toast.error("Password and new password can't be same");
      return;
    } else if (newPassword !== confirmPassword) {
      toast.error("New Password and Confirm password didn't match");
      return;
    }
    postChange_password({ password: password, new_password: newPassword })
      .then((res) => {
        toast.success(res.detail);
        handleCloseModal();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function handleCloseModal() {
    setPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setShowPassword(false);
    setShowConfirmPassword(false);
    setShowConfirmNewPassword(false);
    handleClose();
  }
  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h1 className="headingfour">Change Password</h1>
        <img
          src={close_icon}
          alt="no_img"
          onClick={handleCloseModal}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmitForm}>
          <Form.Group className="mb-1" controlId="oldpassword">
            <Form.Label>
              Old Password <span className="asterisk_text">*</span>
            </Form.Label>
            <div style={{ position: "relative" }}>
              <Form.Control
                placeholder="Enter old password"
                type={showPassword ? "text" : "password"}
                value={password}
                className="inputfield"
                onChange={(e) => setPassword(e.target.value)}
                style={{ paddingRight: "40px" }}
                autoComplete="current-password"
                required
              />

              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "3%",
                  cursor: "pointer",
                }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <VisibilityIcon
                    style={{ fontSize: "20px", color: "#736f6f" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    style={{ fontSize: "20px", color: "#736f6f" }}
                  />
                )}
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-1" controlId="newpassword">
            <Form.Label>
              New Password <span className="asterisk_text">*</span>
            </Form.Label>

            <div style={{ position: "relative" }}>
              <Form.Control
                className="inputfield"
                placeholder="Enter new password"
                type={showConfirmNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                style={{ paddingRight: "40px" }}
                autoComplete="new-password"
                required
              />

              <div
                className="loginpassword_field"
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "3%",
                  cursor: "pointer",
                }}
                onClick={toggleConfirmNewPasswordVisibility}
              >
                {showConfirmNewPassword ? (
                  <VisibilityIcon
                    style={{ fontSize: "20px", color: "#736f6f" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    style={{ fontSize: "20px", color: "#736f6f" }}
                  />
                )}
              </div>
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmpassword">
            <Form.Label>
              Confirm new password <span className="asterisk_text">*</span>
            </Form.Label>
            <div style={{ position: "relative" }}>
              <Form.Control
                className="inputfield"
                placeholder="Confirm new password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{ paddingRight: "40px" }}
                autoComplete="new-password"
                required
              />
              <div
                className="loginpassword_field"
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "3%",
                  cursor: "pointer",
                }}
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? (
                  <VisibilityIcon
                    style={{ fontSize: "20px", color: "#736f6f" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    style={{ fontSize: "20px", color: "#736f6f" }}
                  />
                )}
              </div>
            </div>
          </Form.Group>

          <hr />

          <div className="d-flex justify-content-start align-items-start gap-2">
            <button className="signup_button">Change Password</button>
            {/* <input
                    type="button"
                    value="Change Password"
                    className="signup_button"
                    onClick={() =>
                      changePasswordApi({
                        password: password,
                        new_password: newPassword,
                        confirm_password: confirmPassword,
                      })
                    }
                  ></input> */}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
