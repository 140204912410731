import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Outlinebutton from "../Customcomponents/Outlinebutton";
import Primarybutton from "../Customcomponents/Primarybutton";
import { getProfile, postProject } from "../api/service";
import { toast } from "react-toastify";

const Salesaddprojects = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const curUsrRole = localStorage.getItem("role");
  const currentUserId = localStorage.getItem("userid");
  const canEditSalesTeam = !(
    isEditing !== null &&
    (curUsrRole === "sales_head" || curUsrRole === "sales_team")
  );
  const canEditCivilTeam = !(
    isEditing !== null &&
    (curUsrRole === "civil_head" || curUsrRole === "civil_team")
  );

  const canEditAccounts = !(isEditing !== null && curUsrRole === "accounts");
  const canEditDesign = !(
    isEditing !== null &&
    (curUsrRole === "design_head" || curUsrRole === "design_team")
  );

  /*
    isEditing=null -> view project
    isEdting=false -> adding project
    isEditing=true -> editing project
  */
  const workModuleChoices = [
    { key: "modular", display: "Fast Track" },
    { key: "manual", display: "Normal" },

  ];
  const [customers, setCustomers] = useState([]);
  const [projectPayload, setProjectPayload] = useState({
    work_module_cp: null,
    customer: "",
    address: "",
    city: "",
    location: "",
    required_docs: "",
    pr_weekends: "",
    pr_weekdays: "",
    token_received: null,
    tdc: null,
    work_module_ct: "",
    site_marking_date: null,
    site_marking: null,
    ho_date_ce: null,
    exp_3D_closure_date: null,
    closure_date_2D: null,
    material_selection_at_sales_ho: null,
    quotation_value: null,
    material_selection: null,
    ho_date_sales_ce: null,
    received_on: null,
    received_amount: null,
  });

  const canEditSalesTeamHoCall = !(
    !canEditSalesTeam && projectPayload.exp_3D_closure_date !== null
  );

  //to get customer list
  function getCustomers() {
    getProfile({ role: "customer", active: "True", pagination: false })
      .then((res) => {
        setCustomers(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  useEffect(() => {
    getCustomers();
    let prevState = location.state;
    if (prevState) {
      setIsEditing(prevState.isEditing);
      if (prevState.isEditing === false) {
        setProjectPayload({
          ...projectPayload,
          users_assigned: [currentUserId],
        });
      } else if (
        prevState.isEditing === null ||
        prevState.isEditing !== false
      ) {
        setProjectPayload({
          id: prevState.data.id,
          work_module_cp: prevState.data.work_module_cp,
          customer: prevState.data.customer.id,
          address: prevState.data.address,
          city: prevState.data.city,
          location: prevState.data.location,
          required_docs: prevState.data.required_docs,
          pr_weekends: prevState.data.pr_weekends,
          pr_weekdays: prevState.data.pr_weekdays,
          token_received: prevState.data.token_received,
          work_module_ct: prevState.data.work_module_ct,
          site_marking_date: prevState.data.site_marking_date,
          site_marking: prevState.data?.site_marking?.toString(),
          ho_date_ce: prevState.data.ho_date_ce,
          final_amount_collected: prevState.data.final_amount_collected,
          final_amount_pending: prevState.data.final_amount_pending,
          exp_3D_closure_date: prevState.data.exp_3D_closure_date,
          closure_date_2D: prevState.data.closure_date_2D,
          material_selection_at_sales_ho:
            prevState.data.material_selection_at_sales_ho,
          quotation_value: prevState.data.quotation_value,
          material_selection: prevState.data.material_selection,
          ho_date_sales_ce: prevState.data.ho_date_sales_ce,
          received_on: prevState.data.received_on,
          received_amount: prevState.data.received_amount,
          tdc: prevState.data.tdc,
        });
      }
    }
  }, []);

  function handleChangeField(event) {
    let name = event.target.name;
    let value = event.target.value;
    setProjectPayload({ ...projectPayload, [name]: value });
  }

  function handleSubmitForm(event) {
    event.preventDefault();
    if (!isSubmitted) {
      setIsSubmitted(true);
      postProject(projectPayload)
        .then((res) => {
          toast.success(
            `Project details are ${isEditing ? "updated" : "added"}`
          );
          setIsSubmitted(false);
          navigate("/salesprojects");
        })
        .catch((err) => {
          setIsSubmitted(false);
          toast.error(err.message);
        });
    }
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item headingfour">
                  <Link to="/salesprojects">Projects</Link>
                </li>
                <li
                  class="breadcrumb-item active m-auto"
                  aria-current="page"
                  style={{ fontSize: "18px" }}
                >
                  {isEditing === null ? "View" : isEditing ? "Edit" : "Add"}
                  Project
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <Link to="/salesprojects">
              <button
                className="outline_btn d-flex flex-row"
                style={{ width: "120px" }}
              >
                <span>Back</span>
              </button>
            </Link>
          </div>
        </div>
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div className="table_section">
            <h6 className="mb-3 headingfive">Sales Team (Token Received)</h6>
            <Form onSubmit={handleSubmitForm}>
              <Row>
                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridEmail"
                >
                  <Form.Label
                    className={canEditSalesTeam ? "disabled-label" : ""}
                  >
                    Customer
                  </Form.Label>
                  <Form.Select
                    className="inputfield"
                    name="customer"
                    onChange={handleChangeField}
                    value={projectPayload.customer}
                    disabled={canEditSalesTeam}
                    required
                  >
                    <option hidden value="">
                      Select customer
                    </option>
                    {customers.map((customer) => {
                      return (
                        <option
                          value={customer.user?.id}
                          name={"customer"}
                          onChange={handleChangeField}
                        >
                          {customer.user?.first_name} {customer.user.last_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridCity"
                >
                  <Form.Label
                    className={canEditSalesTeam ? "disabled-label" : ""}
                  >
                    Property Address
                  </Form.Label>
                  <textarea
                    class="form-control inputfield"
                    id="exampleFormControlTextarea1"
                    rows="2"
                    name="address"
                    value={projectPayload.address}
                    onChange={handleChangeField}
                    disabled={canEditSalesTeam}
                    required
                  ></textarea>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridCity"
                >
                  <Form.Label
                    className={canEditSalesTeam ? "disabled-label" : ""}
                  >
                    City
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="inputfield"
                    name="city"
                    value={projectPayload.city}
                    onChange={handleChangeField}
                    disabled={canEditSalesTeam}
                    required
                  />
                </Form.Group>

                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridState"
                >
                  <Form.Label
                    className={canEditSalesTeam ? "disabled-label" : ""}
                  >
                    Map URL
                  </Form.Label>
                  <Form.Control
                    type="url"
                    className="inputfield"
                    name="location"
                    value={projectPayload.location}
                    onChange={handleChangeField}
                    disabled={canEditSalesTeam}
                    required
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group
  className="mb-2 col-lg-6 col-md-12 mb-2"
  controlId="formGridAddress1"
>
  <Form.Label
    className={canEditSalesTeam ? "disabled-label" : ""}
  >
    Gross Quotation Value
  </Form.Label>
  <Form.Control
    type="number"
    className="inputfield"
    name="required_docs"
    value={projectPayload.required_docs}
    onChange={handleChangeField}
    disabled={canEditSalesTeam}
    required
    min="0" // Optional: Ensures only positive numbers are entered
    step="any" // Optional: Allows for decimal numbers; adjust if you need integer values only
  />
</Form.Group>

              </Row>
              <Row>
                <Form.Group
                  className="mb-2 col-lg-6 col-md-12 mb-2"
                  controlId="formGridAddress1"
                >
                  <Form.Label
                    className={canEditSalesTeam ? "disabled-label" : ""}
                  >
                    Property Restrictions - weekend
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="inputfield"
                    name="pr_weekends"
                    value={projectPayload.pr_weekends}
                    onChange={handleChangeField}
                    disabled={canEditSalesTeam}
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-2 col-lg-6 col-md-12 mb-2"
                  controlId="formGridAddress1"
                >
                  <Form.Label
                    className={canEditSalesTeam ? "disabled-label" : ""}
                  >
                    Is Site Ready for measurement?
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="inputfield"
                    name="pr_weekdays"
                    value={projectPayload.pr_weekdays}
                    onChange={handleChangeField}
                    disabled={canEditSalesTeam}
                    required
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridEmail"
                >
                  <Form.Label
                    className={canEditSalesTeam ? "disabled-label" : ""}
                  >
                    Token Received On
                  </Form.Label>
                  <Form.Control
                    type="date"
                    className="inputfield form-control date-control"
                    name="token_received"
                    value={projectPayload.token_received}
                    onChange={handleChangeField}
                    disabled={canEditSalesTeam}
                  />
                </Form.Group>

                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridPassword"
                >
                  <Form.Label
                    className={canEditSalesTeam ? "disabled-label" : ""}
                  >
                   Site Measurement Required on
                  </Form.Label>
                  <Form.Control
                    type="date"
                    className="inputfield form-control date-control"
                    name="tdc"
                    value={projectPayload.tdc}
                    onChange={handleChangeField}
                    disabled={canEditSalesTeam}
                    required
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="col-md-12 col-lg-6 mb-2">
                  <Form.Label
                    className={canEditSalesTeam ? "disabled-label" : ""}
                  >
                   Project Type
                  </Form.Label>
                  <Form.Select
                    className="inputfield"
                    name="work_module_cp"
                    value={projectPayload.work_module_cp}
                    onChange={handleChangeField}
                    disabled={canEditSalesTeam}
                    required
                  >
                    <option hidden value="">
                      Select work module
                    </option>
                    {workModuleChoices.map((workModuleChoice) => {
                      return (
                        <option value={workModuleChoice["key"]}>
                          {workModuleChoice["display"]}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Row>
              <hr />
              <h6 className="mb-3 headingfive">Projects Team</h6>
              <Row>
                <Form.Group
                  className="col-md-12 col-lg-6 mb-3"
                  controlId="formGridEmail"
                >
                  <Form.Label
                    className={canEditCivilTeam ? "disabled-label" : ""}
                  >
                    Site measurement Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    className="inputfield form-control date-control"
                    disabled={canEditCivilTeam}
                    name="site_marking_date"
                    value={projectPayload.site_marking_date}
                    onChange={handleChangeField}
                  />
                </Form.Group>

                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridPassword"
                >
                  <Form.Label
                    className={canEditCivilTeam ? "disabled-label" : ""}
                  >
                    Is Site measurement completed
                  </Form.Label>
                  <div className="col-md-6">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="site_marking"
                        id="inlineRadio1"
                        value="true"
                        disabled={canEditCivilTeam}
                        checked={projectPayload.site_marking == "true"}
                        onChange={handleChangeField}
                      />
                      <label class="form-check-label" for="inlineRadio1">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="site_marking"
                        id="inlineRadio2"
                        value="false"
                        disabled={canEditCivilTeam}
                        checked={projectPayload.site_marking == "false"}
                        onChange={handleChangeField}
                      />
                      <label class="form-check-label" for="inlineRadio2">
                        No
                      </label>
                    </div>
                  </div>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group
                  className="col-lg-6 col-md-12 mb-2"
                  controlId="formGridEmail"
                >
                  <Form.Label
                    className={canEditCivilTeam ? "disabled-label" : ""}
                  >
                    Handover by Sales on
                  </Form.Label>
                  <Form.Control
                    type="date"
                    className="inputfield form-control date-control"
                    disabled={canEditCivilTeam}
                    name="ho_date_ce"
                    value={projectPayload.ho_date_ce}
                    onChange={handleChangeField}
                  />
                </Form.Group>
                <Form.Group as={Col} className="col-lg-6 col-md-12 mb-2">
                  <Form.Label
                    className={canEditCivilTeam ? "disabled-label" : ""}
                  >
                    Work module: Civil Team
                  </Form.Label>
                  <Form.Select
                    className="inputfield"
                    name="work_module_ct"
                    value={projectPayload.work_module_ct}
                    onChange={handleChangeField}
                    disabled={canEditCivilTeam}
                  >
                    <option hidden>Select work module</option>
                    {workModuleChoices.map((workModuleChoice) => {
                      return (
                        <option value={workModuleChoice["key"]}>
                          {workModuleChoice["display"]}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Row>

              <hr />
              <h6 className="mb-3 headingfive">Design Team</h6>

              <Row>
                  <Form.Group
                  controlId="formGridEmail"
                  className="col-md-12 col-lg-6 mb-2"
                >
                  <Form.Label className={canEditDesign ? "disabled-label" : ""}>
                    3D Closure Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    className="inputfield form-control date-control"
                    name="exp_3D_closure_date"
                    value={projectPayload.exp_3D_closure_date}
                    onChange={handleChangeField}
                    disabled={canEditDesign}
                  />
                </Form.Group>
                <Form.Group
                  controlId="formGridEmail"
                  className="col-md-12 col-lg-6 mb-2"
                >
                  <Form.Label className={canEditDesign ? "disabled-label" : ""}>
                    2D Closure Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    className="inputfield form-control date-control"
                    name="closure_date_2D"
                    value={projectPayload.closure_date_2D}
                    onChange={handleChangeField}
                    disabled={canEditDesign}
                  />
                </Form.Group>

              </Row>

              <hr />
              <h6 className="mb-3 headingfive">Sales Team (H/O Call)</h6>
              <Row>
                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridEmail"
                >
                  <Form.Label
                    className={canEditSalesTeamHoCall ? "disabled-label" : ""}
                  >
                    Is Customer staying at site?
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="inputfield"
                    disabled={canEditSalesTeamHoCall}
                    value={projectPayload.material_selection_at_sales_ho}
                    onChange={handleChangeField}
                    name="material_selection_at_sales_ho"

                  />
                </Form.Group>

                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridPassword"
                >
                  <Form.Label
                    className={canEditSalesTeamHoCall ? "disabled-label" : ""}
                  >
                    Net Quotation Amount
                  </Form.Label>
                  <Form.Control
                    type="number"
                    className="inputfield"
                    disabled={canEditSalesTeamHoCall}
                    value={projectPayload.quotation_value}
                    onChange={handleChangeField}
                    name="quotation_value"

                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridEmail"
                >
                  <Form.Label
                    className={canEditSalesTeamHoCall ? "disabled-label" : ""}
                  >
                    Materials Selection Closed on
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="ee"
                    className="inputfield form-control date-control"
                    disabled={canEditSalesTeamHoCall}
                    value={projectPayload.material_selection}
                    onChange={handleChangeField}
                    name="material_selection"

                  />
                </Form.Group>

                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridPassword"
                >
                  <Form.Label
                    className={canEditSalesTeamHoCall ? "disabled-label" : ""}
                  >
                   Handover planned by Sales
                  </Form.Label>
                  <Form.Control
                    type="date"
                    className="inputfield form-control date-control"
                    disabled={canEditSalesTeamHoCall}
                    value={projectPayload.ho_date_sales_ce}
                    onChange={handleChangeField}
                    name="ho_date_sales_ce"

                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridEmail"
                >
                  <Form.Label
                    className={canEditSalesTeamHoCall ? "disabled-label" : ""}
                  >
                    Net Amount Received on
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="ee"
                    className="inputfield form-control date-control"
                    disabled={canEditSalesTeamHoCall}
                    value={projectPayload.received_on}
                    onChange={handleChangeField}
                    name="received_on"

                  />
                </Form.Group>

                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridPassword"
                >
                  <Form.Label
                    className={canEditSalesTeamHoCall ? "disabled-label" : ""}
                  >
                    Net Amount Received
                  </Form.Label>
                  <Form.Control
                    type="number"
                    className="inputfield form-control"
                    disabled={canEditSalesTeamHoCall}
                    value={projectPayload.received_amount}
                    onChange={handleChangeField}
                    name="received_amount"

                  />
                </Form.Group>
              </Row>

              <hr />

              <h6 className="mb-3 headingfive">Accounts Team</h6>
              <Row>
                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridEmail"
                >
                  <Form.Label
                    className={canEditAccounts ? "disabled-label" : ""}
                  >
                    Amount pending from customer (if any)
                  </Form.Label>
                  <Form.Control
                    name="final_amount_pending"
                    type="text"
                    className="inputfield"
                    value={projectPayload.final_amount_pending}
                    onChange={handleChangeField}
                    disabled={canEditAccounts}
                  />
                </Form.Group>

                <Form.Group
                  className="col-md-12 col-lg-6 mb-2"
                  controlId="formGridPassword"
                >
                  <Form.Label
                    className={canEditAccounts ? "disabled-label" : ""}
                  >
                    Final Amount Collected
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="final_amount_collected"
                    className="inputfield"
                    value={projectPayload.final_amount_collected}
                    onChange={(event) => {
                      const regex = /^[0-9\b]*(?![eE])$/;
                      if (regex.test(event.target.value)) {
                        handleChangeField(event);
                      }
                    }}
                    disabled={canEditAccounts}
                  />
                </Form.Group>
              </Row>

              <hr />
              {isEditing !== null && (
                <div className="d-flex justify-content-start align-items-start gap-2 mt-4">
                  <input
                    type="submit"
                    value="Submit"
                    className="signup_button"
                  />
                  <Outlinebutton
                    name="Cancel"
                    onClick={(event) => {
                      navigate("/salesprojects");
                    }}
                  />
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Salesaddprojects;
