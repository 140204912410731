import axios from "axios";
import { toast } from "react-toastify";
const url = process.env.REACT_APP_BASE_URL;
const version = "v1";
const baseURL = `${url}/api/${version}`;
const isDevelopment = process.env.NODE_ENV === "development";
let host = `${window.location.protocol}//${window.location.host}`;

//for export as functionality
const endPointExport_as = isDevelopment
  ? `${baseURL}/export_as/`
  : `${host}/api/${version}/export_as/`;
const baseProject_payment = isDevelopment
  ? `${baseURL}/project_payment/`
  : `${host}/api/${version}/project_payment/`;
const baseSupplier_estimates = isDevelopment
  ? `${baseURL}/supplier_estimates/`
  : `${host}/api/${version}/supplier_estimates/`;
const baseProject = isDevelopment
  ? `${baseURL}/project/`
  : `${host}/api/${version}/project/`;
const baseSupplier_estimatesWithQuery = isDevelopment
  ? `${baseURL}/supplier_estimates/?active=True`
  : `${host}/api/${version}/supplier_estimates/?active=True`;

// URLs
const endPointLogout = "/logout/";
const profileURL = "/login/";
const usersProfileURL = "/profile/";
const userCreateURL = "/user/create/";
const endPointProfile = "/profile/";
const endPointUserForgot_password = "/forgot_password/";
const endPointCheck_validity = "/check_validity/";
const endPointReset_password = "/reset_password/";
const endPointChange_password = "/change_password/";
const endPointcustomer = "/customer/";
const endPointProject = "/project/";
const endPointTask = "/task/";
const endPointSubcontractor_type = "/supplier_type/";
const endPointSubcontractor = "/suppliers/";
const endPointSubcontractor_estimate = "/supplier_estimates/";
const endPointVendor_types = "/vendor_types/";
const endPointVendors = "/vendors/";
const endPointVendor_estimates = "/vendor_estimates/";
const endpointNavigator = "/navigation_constructor/";
const endPointProject_signoff = "/project_signoff/";
const endPointSignoff = "/signoff/";
const endPointTask_history = "/task_history/";
const endPointDrivem = "/drivem/";
const endPointCategories = "/categories/";
const endPointSubCategories = "/subcategories/";
const endPointSupplier_estimate_pay_history = "/supplier_estimate_pay_history/";
const endPointPayments = "/payments/";
const endPointProject_payment = "/project_payment/";
const endPointQuestion = "/question/";
const endPointQueries = "/queries/";
const endPointProject_queries = "/project_queries/";
const endPointChecklist = "/checklist/";
const endPointLabour_payment_report = "/labour_payment_report/";
const endPointCashbook_report = "/cashbook_report/";
const endPointProject_orders = "/project_orders/";
const endPointOrder_items_history = "/order_items_history/";
const endPointOrder_item_upload = "/order_item_upload/";

//for procurement request
const endPointOrders = "/orders/";

//for Sub-contractor Quotations
const endPointOrder_items = "/order_items/";
const endPointSupplier_quotation = "/supplier_quotation/";

// create axios client
const client = axios.create({
  baseURL: baseURL,
  withCredentials: false,
});

const setToken = () => {
  const token = localStorage.getItem("token");
  client.defaults.headers.common["Authorization"] = token;
};

const unSetToken = () => {
  delete client.defaults.headers.common["Authorization"];
};

const request = async ({ method = "GET", url, data, requireToken = false }) => {
  try {
    if (requireToken === true) {
      setToken();
    } else {
      unSetToken();
    }
    if (method === "GET") {
      const response = await client.get(url, data);
      return response.data;
    } else if (method === "POST") {
      const response = await client.post(url, data);
      return response.data;
    } else if (method === "DELETE") {
      const response = await client.delete(url, data);
      return response.data;
    }
  } catch (error) {
    if (url !== profileURL && error.response.status === 401) {
      window.location.href = "/";
      localStorage.clear();
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.detail
    ) {
      throw new Error(error.response.data.detail);
    }
    throw new Error(error.message);
  }
};

const postLogout = (data) => {
  return request({
    method: "POST",
    url: endPointLogout,
    data: data,
    requireToken: true,
  });
};
const postUserLogin = async (data) => {
  return await request({
    method: "POST",
    url: profileURL,
    data: data,
    requireToken: false,
  });
};

const getUsersProfile = async ({ active = "True" }) => {
  return request({
    method: "GET",
    url: `${usersProfileURL}?active=${active ?? "True"}`,
    requireToken: true,
  });
};
const getProfile = (data) => {
  return request({
    method: "GET",
    url: endPointProfile,
    data: { params: data },
    requireToken: true,
  });
};
const updateProfile = async (data) => {
  return request({
    method: "POST",
    url: usersProfileURL,
    data: data,
    requireToken: true,
  });
};

const createUpdateUserProfile = async (data, isUpdateAPI) => {
  const params = {
    username: data.username,
    first_name: data.firstname,
    last_name: data.lastname,
    email: data.username,
    employee_id: data.employeeId,
  };
  if (isUpdateAPI) {
    params.id = data.userId;
  }
  if (!isUpdateAPI) {
    params.password = data.password;
  }
  const user = await request({
    method: "POST",
    url: userCreateURL,
    data: params,
    requireToken: true,
  });

  let createProfileData = {
    role: data.role,
    user: user?.id,
    mobile_no: data.mobile,
    employee_id: data.employeeId,
  };
  if (isUpdateAPI) {
    createProfileData.id = data.profileId;
    createProfileData.user = data.userId;
  }

  const profile = await request({
    method: "POST",
    url: usersProfileURL,
    data: createProfileData,
    requireToken: true,
  });
  return profile;
};

const deleteUsersProfile = async (payload) => {
  return request({
    method: "POST",
    url: usersProfileURL,
    data: payload,
    requireToken: true,
  });
};

const forgetPassword = (payload) => {
  return request({
    method: "POST",
    url: endPointUserForgot_password,
    data: payload,
    requireToken: false,
  });
};
const postCheck_validity = (payload) => {
  return request({
    method: "POST",
    url: endPointCheck_validity,
    data: payload,
    requireToken: false,
  });
};
const postReset_password = (payload) => {
  return request({
    method: "POST",
    url: endPointReset_password,
    data: payload,
    requireToken: false,
  });
};
const getCustomer = (payload) => {
  return request({
    method: "GET",
    url: endPointcustomer,
    data: payload,
    requireToken: true,
  });
};
const postChange_password = (payload) => {
  return request({
    method: "POST",
    url: endPointChange_password,
    data: payload,
    requireToken: true,
  });
};
const getProject = (data) => {
  return request({
    method: "GET",
    url: endPointProject,
    data: { params: data },
    requireToken: true,
  });
};
const postProject = (payload) => {
  return request({
    method: "POST",
    url: endPointProject,
    data: payload,
    requireToken: true,
  });
};
const getTask = (data) => {
  return request({
    method: "GET",
    url: endPointTask,
    data: { params: data },
    requireToken: true,
  });
};
const postTask = (payload) => {
  return request({
    method: "POST",
    url: endPointTask,
    data: payload,
    requireToken: true,
  });
};
const getSubcontractor = (data) => {
  return request({
    method: "GET",
    url: endPointSubcontractor,
    data: { params: data },
    requireToken: true,
  });
};
const postSubcontractor = (payload) => {
  return request({
    method: "POST",
    url: endPointSubcontractor,
    data: payload,
    requireToken: true,
  });
};
const getSubcontractor_type = (data) => {
  return request({
    method: "GET",
    url: endPointSubcontractor_type,
    data: { params: data },
    requireToken: true,
  });
};
const postSubcontractor_type = (payload) => {
  return request({
    method: "POST",
    url: endPointSubcontractor_type,
    data: payload,
    requireToken: true,
  });
};
const getSubcontractor_estimate = (data) => {
  return request({
    method: "GET",
    url: endPointSubcontractor_estimate,
    data: { params: data },
    requireToken: true,
  });
};
const postSubcontractor_estimate = (payload) => {
  return request({
    method: "POST",
    url: endPointSubcontractor_estimate,
    data: payload,
    requireToken: true,
  });
};
const getVendor_types = (data) => {
  return request({
    method: "GET",
    url: endPointVendor_types,
    data: { params: data },
    requireToken: true,
  });
};
const postVendor_types = (payload) => {
  return request({
    method: "POST",
    url: endPointVendor_types,
    data: payload,
    requireToken: true,
  });
};
const getVendors = (data) => {
  return request({
    method: "GET",
    url: endPointVendors,
    data: { params: data },
    requireToken: true,
  });
};
const postVendors = (payload) => {
  return request({
    method: "POST",
    url: endPointVendors,
    data: payload,
    requireToken: true,
  });
};
const getVendor_estimates = (data) => {
  return request({
    method: "GET",
    url: endPointVendor_estimates,
    data: { params: data },
    requireToken: true,
  });
};
const postVendor_estimates = (payload) => {
  return request({
    method: "POST",
    url: endPointVendor_estimates,
    data: payload,
    requireToken: true,
  });
};

const getSideBar = async () => {
  const data = await request({
    method: "GET",
    url: endpointNavigator,
    data: { role__short_name: localStorage.getItem("role") },
    requireToken: true,
  });

  return data;
};
const getProject_signoff = (data) => {
  return request({
    method: "GET",
    url: endPointProject_signoff,
    data: { params: data },
    requireToken: true,
  });
};
const postProject_signoff = (payload) => {
  return request({
    method: "POST",
    url: endPointProject_signoff,
    data: payload,
    requireToken: true,
  });
};
const getSignoff = (data) => {
  return request({
    method: "GET",
    url: endPointSignoff,
    data: { params: data },
    requireToken: true,
  });
};
const postSignoff = (payload) => {
  return request({
    method: "POST",
    url: endPointSignoff,
    data: payload,
    requireToken: true,
  });
};
const getTask_history = (data) => {
  return request({
    method: "GET",
    url: endPointTask_history,
    data: { params: data },
    requireToken: true,
  });
};
const postTask_history = (payload) => {
  return request({
    method: "POST",
    url: endPointTask_history,
    data: payload,
    requireToken: true,
  });
};
const getDrivem = (data) => {
  return request({
    method: "GET",
    url: endPointDrivem,
    data: { params: data },
    requireToken: true,
  });
};
const postDrivem = (payload) => {
  return request({
    method: "POST",
    url: endPointDrivem,
    data: payload,
    requireToken: true,
  });
};

const getCategories = (data) => {
  return request({
    method: "GET",
    url: endPointCategories,
    data: { params: data },
    requireToken: true,
  });
};
const getSubCategories = (data) => {
  return request({
    method: "GET",
    url: endPointSubCategories,
    data: { params: data },
    requireToken: true,
  });
};
const getSupplier_estimate_pay_history = (data) => {
  return request({
    method: "GET",
    url: endPointSupplier_estimate_pay_history,
    data: { params: data },
    requireToken: true,
  });
};
const postSupplier_estimate_pay_history = (payload) => {
  return request({
    method: "POST",
    url: endPointSupplier_estimate_pay_history,
    data: payload,
    requireToken: true,
  });
};
const getPayments = (data) => {
  return request({
    method: "GET",
    url: endPointPayments,
    data: { params: data },
    requireToken: true,
  });
};
const postPayments = (payload) => {
  return request({
    method: "POST",
    url: endPointPayments,
    data: payload,
    requireToken: true,
  });
};
const getProject_payment = (data) => {
  return request({
    method: "GET",
    url: endPointProject_payment,
    data: { params: data },
    requireToken: true,
  });
};
const postProject_payment = (payload) => {
  return request({
    method: "POST",
    url: endPointProject_payment,
    data: payload,
    requireToken: true,
  });
};
const getQuestion = (data) => {
  return request({
    method: "GET",
    url: endPointQuestion,
    data: { params: data },
    requireToken: true,
  });
};
const postQuestion = (payload) => {
  return request({
    method: "POST",
    url: endPointQuestion,
    data: payload,
    requireToken: true,
  });
};
const getQueries = (data) => {
  return request({
    method: "GET",
    url: endPointQueries,
    data: { params: data },
    requireToken: true,
  });
};
const postQueries = (payload) => {
  return request({
    method: "POST",
    url: endPointQueries,
    data: payload,
    requireToken: true,
  });
};
const getProject_queries = (data) => {
  return request({
    method: "GET",
    url: endPointProject_queries,
    data: { params: data },
    requireToken: true,
  });
};
const postProject_queries = (payload) => {
  return request({
    method: "POST",
    url: endPointProject_queries,
    data: payload,
    requireToken: true,
  });
};
const getChecklist = (data) => {
  return request({
    method: "GET",
    url: endPointChecklist,
    data: { params: data },
    requireToken: true,
  });
};
const postChecklist = (payload) => {
  return request({
    method: "POST",
    url: endPointChecklist,
    data: payload,
    requireToken: true,
  });
};
const getLabour_payment_report = (payload) => {
  return request({
    method: "GET",
    url: endPointLabour_payment_report,
    data: payload,
    requireToken: true,
  });
};
const getCashbook_report = (data) => {
  return request({
    method: "GET",
    url: endPointCashbook_report,
    data: { params: data },
    requireToken: true,
  });
};

const getProject_orders = (data) => {
  return request({
    method: "GET",
    url: endPointProject_orders,
    data: { params: data },
    requireToken: true,
  });
};
const postProject_orders = (payload) => {
  return request({
    method: "POST",
    url: endPointProject_orders,
    data: payload,
    requireToken: true,
  });
};

const getOrders = (data) => {
  return request({
    method: "GET",
    url: endPointOrders,
    data: { params: data },
    requireToken: true,
  });
};
const postOrders = (payload) => {
  return request({
    method: "POST",
    url: endPointOrders,
    data: payload,
    requireToken: true,
  });
};

const getPointOrder_items = (data) => {
  return request({
    method: "GET",
    url: endPointOrder_items,
    data: { params: data },
    requireToken: true,
  });
};
const postOrder_items = (payload) => {
  return request({
    method: "POST",
    url: endPointOrder_items,
    data: payload,
    requireToken: true,
  });
};
const getOrder_items_history = (data) => {
  return request({
    method: "GET",
    url: endPointOrder_items_history,
    data: { params: data },
    requireToken: true,
  });
};
const postOrder_items_history = (payload) => {
  return request({
    method: "POST",
    url: endPointOrder_items_history,
    data: payload,
    requireToken: true,
  });
};
const getSupplier_quotation = (data) => {
  return request({
    method: "GET",
    url: endPointSupplier_quotation,
    data: { params: data },
    requireToken: true,
  });
};
const postSupplier_quotation = (payload) => {
  return request({
    method: "POST",
    url: endPointSupplier_quotation,
    data: payload,
    requireToken: true,
  });
};

const postOrder_item_upload = (payload) => {
  return request({
    method: "POST",
    url: endPointOrder_item_upload,
    data: payload,
    requireToken: true,
  });
};
export {
  request,
  endPointExport_as,
  baseProject,
  baseProject_payment,
  baseSupplier_estimates,
  baseSupplier_estimatesWithQuery,
  postLogout,
  postUserLogin,
  getUsersProfile,
  getProfile,
  updateProfile,
  createUpdateUserProfile,
  deleteUsersProfile,
  forgetPassword,
  postCheck_validity,
  postReset_password,
  getCustomer,
  postChange_password,
  getProject,
  postProject,
  getTask,
  postTask,
  getSubcontractor_type,
  postSubcontractor_type,
  getSubcontractor,
  postSubcontractor,
  getSubcontractor_estimate,
  postSubcontractor_estimate,
  getVendor_types,
  postVendor_types,
  getVendors,
  postVendors,
  getVendor_estimates,
  postVendor_estimates,
  getSideBar,
  getSignoff,
  postSignoff,
  getTask_history,
  postTask_history,
  getProject_signoff,
  postProject_signoff,
  getDrivem,
  postDrivem,
  getCategories,
  getSubCategories,
  getSupplier_estimate_pay_history,
  postSupplier_estimate_pay_history,
  getPayments,
  postPayments,
  getProject_payment,
  postProject_payment,
  getQuestion,
  postQuestion,
  getQueries,
  postQueries,
  getProject_queries,
  postProject_queries,
  getChecklist,
  postChecklist,
  getLabour_payment_report,
  getCashbook_report,
  getProject_orders,
  postProject_orders,
  getPointOrder_items,
  postOrder_items,
  getOrders,
  postOrders,
  getOrder_items_history,
  postOrder_items_history,
  getSupplier_quotation,
  postSupplier_quotation,
  postOrder_item_upload,
};
