import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";
import Customdate from "../Customcomponents/Customdate";
import Custompagenation from "../Customcomponents/Custompagenation";
import close_icon from "../assets/img/close.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
// import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import Customsearch from "../Customcomponents/Customsearch";
import DocumentViewModal from "../Customcomponents/DocumentViewModal";
import {
  getSupplier_quotation,
  postSupplier_quotation,
  postDrivem,
  getSubCategories,
} from "../api/service";
import { conDateToReadable, getIds } from "../components/utils";
import CustomFileSelector from "../Customcomponents/CustomFileSelector";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Civilactualprocurement = () => {
  const currentUserId = localStorage.getItem("userid");
  const currentUserRole = localStorage.getItem("role");
  const isCustomer = currentUserRole === "customer";

  //getting project id from previous page
  const location = useLocation();
  const state = location.state;
  const projectId = state?.projectId;
  const customerFname = state?.customerFname;
  const customerLname = state?.customerLname;
  const isProjectClosed = state?.isProjectClosed;

  const isCivil =
    ["civil_head", "civil_team"].includes(currentUserRole) && !isProjectClosed;

  const [tabelData, setTableData] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [selectedSubContractorQuatation, setSelectedSubContractorQuatation] =
    useState(null);

  const [taskCategory, setTaskCategory] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setIsEditing(false);
    setSelectedSubContractorQuatation(null);
  };
  const handleShow = () => setShow(true);

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  function handleShowCustomerModal() {
    setShowCustomerModal(true);
  }

  function handleCloseCustomerModal() {
    setShowCustomerModal(false);
    setSelectedSubContractorQuatation(null);
  }

  const [showview, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);
  function apiCall() {
    getSupplier_quotation({ project: projectId, search: searchValue })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function getCategoryAndSubCategory() {
    getSubCategories({
      category__name: "Supplier Quotation",
      name: "Quotation",
    })
      .then((res) => {
        if (res?.results.length > 0) {
          setTaskCategory(res.results[0]);
        } else {
          toast.error("Supplier Quotation and Quotation in admin panel");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  useEffect(() => {
    getCategoryAndSubCategory();
  }, []);
  useEffect(() => {
    apiCall();
  }, [searchValue]);

  function AddSupplierQuotationModal() {
    //upload files
    const [prevSQFiles, setPrevSQFiles] = useState([
      ...(selectedSubContractorQuatation?.quotation_document ?? []),
    ]);
    const [SQFiles, setSQFiles] = useState([]);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [payload, setPayload] = useState({
      id: selectedSubContractorQuatation?.id,
      title: selectedSubContractorQuatation?.title,
      description: selectedSubContractorQuatation?.description,
      date_of_meeting_with_client:
        selectedSubContractorQuatation?.date_of_meeting_with_client,
      quotation_amount: selectedSubContractorQuatation?.quotation_amount,
      created_by:
        selectedSubContractorQuatation?.created_by?.id ?? currentUserId,
      updated_by: currentUserId,
      quotation_document: selectedSubContractorQuatation?.quotation_document,
      project: projectId,
    });

    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }
    function handleFormSubmit(event) {
      event.preventDefault();
      if (prevSQFiles.length === 0 && SQFiles.length === 0) {
        toast.error("Please select files to upload");
        return;
      }
      if (!isSubmitted) {
        let SQFilesPrms = [];
        for (let index = 0; index < SQFiles.length; index++) {
          let form = new FormData();
          form.append("file", SQFiles[index]);
          form.append("categories", taskCategory.category?.id);
          form.append("subcategories", taskCategory?.id);
          SQFilesPrms.push(postDrivem(form));
        }
        Promise.all(SQFilesPrms).then((SQFilesRes) => {
          let docIds = SQFilesRes.map((taskFileRes) => {
            return taskFileRes.id;
          });
          let prevDocIds = getIds(prevSQFiles);
          postSupplier_quotation({
            ...payload,
            quotation_document: [...docIds, ...prevDocIds],
          })
            .then((res) => {
              toast.success(
                `Quotation is ${isEditing ? "updated" : "created"}`
              );
              apiCall();
              handleClose();
            })
            .catch((err) => {
              toast.error(err.message);
            });
        });
      }
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">{isEditing ? "Edit" : "Add"} Quotation</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Form.Group
                as={Col}
                controlId="formGridPassword"
                className="col-md-6"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  className="inputfield"
                  name="title"
                  value={payload.title}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group
                as={Col}
                controlId="formGridPassword"
                className="col-md-6"
              >
                <Form.Label>Date of Meeting with Client</Form.Label>
                <Customdate
                  name="date_of_meeting_with_client"
                  value={payload.date_of_meeting_with_client}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Col}
                controlId="description"
                className="col-md-12"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  name={"description"}
                  value={payload.description}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-12 col-md-12 mb-2 mt-2"
                controlId="formGridCity"
              >
                <Form.Label>Quotation Amount</Form.Label>
                <Form.Control
                  type="number"
                  className="inputfield"
                  name="quotation_amount"
                  value={payload.quotation_amount}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-5 mt-2">
              <Form.Group className="col-md-12">
                <Form.Label>Quotation Document</Form.Label>
                <CustomFileSelector
                  selectedFiles={SQFiles}
                  setSelectedFiles={setSQFiles}
                  prevSelectedFiles={prevSQFiles}
                  setPrevSelectedFiles={setPrevSQFiles}
                />
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-start align-items-start gap-2">
              <button
                type="button"
                class="outline_btn"
                style={{ width: "120px" }}
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                class="add_employee_btn"
                style={{ width: "120px" }}
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  function CustomerUpdateModal() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [SQFiles, setSQFiles] = useState([]);
    const [payload, setPayload] = useState({
      id: selectedSubContractorQuatation?.id,
      title: selectedSubContractorQuatation?.title,
      description: selectedSubContractorQuatation?.description,
      date_of_meeting_with_client:
        selectedSubContractorQuatation?.date_of_meeting_with_client,
      quotation_amount: selectedSubContractorQuatation?.quotation_amount,
      //customer fields
      customer_response_date: conDateToReadable(new Date(), false, "-", false),
      customer_remarks: selectedSubContractorQuatation?.customer_remarks,
      customer_response: selectedSubContractorQuatation?.customer_response,

      created_by:
        selectedSubContractorQuatation?.created_by?.id ?? currentUserId,
      updated_by: currentUserId,
      project: projectId,
    });

    const responseOptions = ["accept", "reject",];

    const [showDocs, setShowDocs] = useState(false);

    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }
    function handleFormSubmit(event) {
      event.preventDefault();
      if (!isSubmitted) {
        postSupplier_quotation(payload)
          .then((res) => {
            toast.success("Quotation is updated");
            handleCloseCustomerModal();
            apiCall();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
    return (
      <Modal
        show={showCustomerModal}
        onHide={handleCloseCustomerModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Update Response</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleCloseCustomerModal}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Row className="mb-2">
              <Form.Group
                controlId="formGridEmail"
                className="col-md-12 col-lg-6 mb-2 disable-state"
              >
                <Form.Label>Created by</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="2000"
                  className="inputfield"
                  value={selectedSubContractorQuatation?.created_by?.username}
                  readOnly
                  disabled
                />
              </Form.Group>
              <Form.Group
                controlId="formGridEmail"
                className="col-md-12 col-lg-6 mb-2 disable-state"
              >
                <Form.Label>Quotation Amount</Form.Label>
                <Form.Control
                  type="text"
                  className="inputfield"
                  value={payload?.quotation_amount}
                  readOnly
                  disabled
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="col-md-6 mb-2"
              >
                <Form.Label>Response</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="customer_response"
                  value={payload.customer_response}
                  onChange={handleInputChange}
                  required
                >
                  <option hidden value="">
                    Select response
                  </option>
                  {responseOptions.map((responseOption) => {
                    return (
                      <option value={responseOption}>{responseOption}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} className="col-md-12">
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  name="customer_remarks"
                  value={payload.customer_remarks}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group
                as={Col}
                className="col-md-12 pb-3"
                style={{ lineHeight: "16px" }}
              >
                <Form.Label>Quotation from Civil Team</Form.Label>

                <div className="active-links">
                  <label
                    for="file-upload"
                    class="footer_link d-flex flex-row active-links"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowDocs(true);
                    }}
                  >
                    <a>View Document</a>
                  </label>
                </div>
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-start align-items-start gap-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleCloseCustomerModal}
              ></input>
              <input
                type="submit"
                value="Submit"
                className="signup_button"
              ></input>
            </div>
          </Form>
          <DocumentViewModal
            show={showDocs}
            onHide={() => {
              setShowDocs(false);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
            handleCloseView={() => {
              setShowDocs(false);
            }}
            links={selectedSubContractorQuatation?.quotation_document}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <ul>
              <li>
                <h6 className="breadcrumb_main_heading">
                  <Link
                    to={isCustomer ? "/customerdashboard" : "/salesprojects"}
                  >
                    Projects
                  </Link>
                </h6>
              </li>
              <li>
                <span className="breadcrumb_paragraph">
                  {" "}
                  Sub-contractor Quotations
                </span>
              </li>
            </ul>
          </div>
          <div>
            <div className="d-flex justify-content-end list-group-item">
              <div className="d-flex justify-content-end pt-1 pb-1 gap-2 ">
                <div class="d-grid gap-2 d-md-flex">
                  {isCivil && (
                    <div>
                      <button
                        className="add_employee_btn d-flex flex-row"
                        onClick={handleShow}
                      >
                        <AddIcon />
                        <span>Add Quotation</span>
                      </button>
                    </div>
                  )}

                  <Link
                    to={isCustomer ? "/customerdashboard" : "/salesprojects"}
                  >
                    <button
                      type="button"
                      className="outline_btn"
                      style={{ width: "120px" }}
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="d-flex justify-content-between align-items-center mt-3">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item headingfour">
                  <Link
                    to={isCustomer ? "/customerdashboard" : "/salesprojects"}
                  >
                    {`Proj ${projectId}_${customerFname}_${customerLname}`}
                  </Link>
                </li>
                <li class="breadcrumb-item m-auto" style={{ fontSize: "18px" }}>
                  Sub-contractor Quotations
                </li>
              </ol>
            </nav>
          </div>
          <div class="d-grid gap-2 d-md-flex">
            {isCivil && (
              <div>
                <button
                  className="add_employee_btn d-flex flex-row"
                  onClick={handleShow}
                >
                  <AddIcon />
                  <span>Add Quotation</span>
                </button>
              </div>
            )}

            <Link to={isCustomer ? "/customerdashboard" : "/salesprojects"}>
              <button
                type="button"
                className="outline_btn"
                style={{ width: "120px" }}
              >
                Back
              </button>
            </Link>
          </div>
        </div> */}
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div>
            <label classname="breadcrumb_description">
              Project Id:{" "}
              <span style={{ fontWeight: "400" }}>{`Proj ${projectId}`}</span>
            </label>
          </div>
          {!isCustomer && (
            <div>
              <label
                classname="breadcrumb_description"
                style={{ display: "block", wordWrap: "break-word" }}
              >
                Customer Name:{" "}
                <span
                  style={{ fontWeight: "400" }}
                  className="breadcrumb_description_names"
                >{`${customerFname} ${customerLname}`}</span>
              </label>
            </div>
          )}

          <hr />
          <div class="d-flex justify-content-start align-items-center mb-3">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">
                      <div>Created on</div>
                    </th>
                    <th scope="col">
                      <div>Created by</div>
                    </th>
                    <th scope="col">
                      <div>Title</div>
                    </th>
                    <th scope="col">
                      <div>Description</div>
                    </th>
                    <th scope="col">
                      <div>Quotation Document</div>
                    </th>
                    <th scope="col">
                      <div>Quotation Amount</div>
                    </th>
                    <th scope="col">
                      <div>Customer Response Date</div>
                    </th>
                    <th scope="col">
                      <div>Customer Response</div>
                    </th>
                    <th scope="col">
                      <div>Customer Remarks</div>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {tabelData?.results.map((row) => {
                    return (
                      <tr>
                        <td>{conDateToReadable(row.created_at, false)}</td>
                        <td>{row.created_by?.username}</td>
                        <td>{row.title}</td>
                        <td>{row.description}</td>
                        <td class="active-links">
                          <span
                            class="footer_link"
                            onClick={() => {
                              handleShowView();
                              setSelectedSubContractorQuatation(row);
                            }}
                          >
                            View
                          </span>
                        </td>
                        <td>{row.quotation_amount}</td>
                        <td>{row.customer_response_date}</td>
                        <td>{row.customer_response}</td>
                        <td>{row.customer_remarks}</td>
                        <td className="text-center">
                          {!(
                            row.project.status === "project on hold" ||
                            row.project.status === "project closed"
                          ) &&
                            row?.customer_response?.toLowerCase() !==
                              "accept" &&
                            row?.customer_response?.toLowerCase() !==
                              "reject" && (
                              <LightTooltip
                                title={"Edit"}
                                arrow
                                placement="bottom"
                              >
                                <EditIcon
                                  onClick={() => {
                                    if (isCustomer) {
                                      setSelectedSubContractorQuatation(row);
                                      handleShowCustomerModal();
                                    } else if (isCivil) {
                                      setIsEditing(true);
                                      setSelectedSubContractorQuatation(row);
                                      handleShow();
                                    }
                                  }}
                                  style={{ color: "#008080" }}
                                  className="edit_icon"
                                />
                              </LightTooltip>
                            )}
                        </td>
                      </tr>
                    );
                  })}
                  {tabelData?.count === 0 && (
                    <tr>
                      <td colSpan={8} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Custompagenation data={tabelData} setData={setTableData} />
          <DocumentViewModal
            show={showview}
            onHide={() => {
              setShow(false);
              setSelectedSubContractorQuatation(null);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
            handleCloseView={() => {
              setShowView(false);
              setSelectedSubContractorQuatation(null);
            }}
            links={selectedSubContractorQuatation?.quotation_document}
          />
          <AddSupplierQuotationModal />
          <CustomerUpdateModal />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Civilactualprocurement;
