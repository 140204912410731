import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

// Modify the component to accept props
const Custombuttons = (props) => {
  return (
    <React.Fragment>
      <div>
        <LightTooltip
          title={props.label}
          arrow
          placement="bottom"
          className="tooltip_buttons"
        >
          <button className={`download_btn d-flex flex-row ${props.className}`} onClick={props.onClick}>
            <props.buttonIcon className="copy_icon" />
            <span className="button_lable">{props.label}</span>
          </button>
        </LightTooltip>
      </div>
    </React.Fragment>
  );
};

export default Custombuttons;
