import { useState, useEffect } from "react";
export default function useStorage(key) {
  const [storageValue, setStorageValue] = useState(localStorage.getItem(key));

  useEffect(() => {
    const handleStorageChange = () => {
      setStorageValue(localStorage.getItem(key));
      if (!localStorage.getItem(key)) {
        window.location.href = "/login";
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key]);

  return storageValue;
}
