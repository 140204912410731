import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Custombuttons from "../Customcomponents/Custombuttons";
import Custompagenation from "../Customcomponents/Custompagenation";
import DocumentViewModal from "../Customcomponents/DocumentViewModal";
import Outlinebutton from "../Customcomponents/Outlinebutton";
import Uploadbutton from "../Customcomponents/Uploadbutton";
import {
  getProject_queries,
  getSubCategories,
  postDrivem,
  postProject_queries,
  postQueries,
} from "../api/service";
import { ReactComponent as Add } from "../assets/img/add.svg";
import close_icon from "../assets/img/close.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import { ReactComponent as ReassignIcon } from "../assets/img/reassign.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { conDateToReadable, getIds } from "../components/utils";
import CustomFileSelector from "../Customcomponents/CustomFileSelector";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    lineHeight: "1.3",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Customerqueries = () => {
  const location = useLocation();
  const projectId = location?.state?.projectId;

  const customerFname = location?.state?.customerFname;
  const customerLname = location?.state?.customerLname;

  const isProjectClosed = location?.state?.isProjectClosed;

  const currentUserId = localStorage.getItem("userid");
  const [requestCategory, setrequestCategory] = useState(null);
  const [tableData, setTableData] = useState(null);

  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showupdate, setShowUpdate] = useState(false);
  const handleShowUpdate = () => setShowUpdate(true);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setSelectedRequest(null);
    setIsEditing(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [showview, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [showassign, setShowAssign] = useState(false);
  const handleCloseAssign = () => setShowAssign(false);
  const handleShowAssign = () => setShowAssign(true);

  const [showdocview, setShowDocView] = useState(false);
  const handleCloseDocView = () => {
    setShowDocView(false);
    setSelectedRequest(null);
  };
  const handleShowDocView = () => setShowDocView(true);

  function apiCall() {
    getProject_queries({ project: projectId })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function getCategoryAndSubCategory() {
    getSubCategories({ category__name: "Requests", name: "Request" })
      .then((res) => {
        if (res?.results.length > 0) {
          setrequestCategory(res.results[0]);
        } else {
          toast.error("Add Task and Task History in admin panel");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  useEffect(() => {
    apiCall();
    getCategoryAndSubCategory();
  }, []);

  function AddEditRequestModal() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [prevDocFiles, setPrevDocFiles] = useState([
      ...(selectedRequest?.docs ?? []),
    ]);
    const [docFiles, setDocFiles] = useState([]);

    const [queryPayload, setQueryqueryPayload] = useState({
      id: selectedRequest?.queries?.id,
      title: selectedRequest?.queries?.title ?? "",
      description: selectedRequest?.queries?.description ?? "",
      created_by: selectedRequest?.queries?.created_by?.id ?? currentUserId,
      updated_by: currentUserId,
    });
    const [projectQueryPayload, setProjectQueryPayload] = useState({
      id: selectedRequest?.id,
      project: selectedRequest?.project ?? projectId,
      created_by: selectedRequest?.created_by ?? currentUserId,
      updated_by: currentUserId,
    });
    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setQueryqueryPayload({ ...queryPayload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (prevDocFiles.length === 0 && docFiles.length === 0) {
        toast.error("Please select files to upload");
        return;
      }
      if (!isFormSubmitted) {
        setIsFormSubmitted(true);
        let docsPrms = [];
        for (let index = 0; index < docFiles.length; index++) {
          let form = new FormData();
          form.append("file", docFiles[index]);
          form.append("categories", requestCategory.category?.id);
          form.append("subcategories", requestCategory?.id);
          docsPrms.push(postDrivem(form));
        }
        Promise.all(docsPrms).then((docsRes) => {
          let docIds = docsRes.map((taskFileRes) => {
            return taskFileRes.id;
          });
          let prevDocIds = getIds(prevDocFiles);
          postQueries(queryPayload)
            .then((res) => {
              postProject_queries({
                queries: res.id,
                docs: [...docIds, ...prevDocIds],
                ...projectQueryPayload,
              }).then((res) => {
                toast.success(
                  isEditing ? "Request is updated" : "New request is added"
                );
                apiCall();
                setIsFormSubmitted(false);
                handleClose();
              });
            })
            .catch((err) => {
              setIsFormSubmitted(false);
              toast.error(err.message);
            });
        });
      }
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">{isEditing ? "Edit" : "Add New"} Request</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row className="mb-2">
              <Form.Group
                controlId="formGridEmail"
                className="col-lg-6 col-md-12 mb-2"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  className="inputfield"
                  name="title"
                  value={queryPayload.title}
                  onChange={handleInputChange}
                  required
                ></Form.Control>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group className="col-md-12">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  name="description"
                  value={queryPayload.description}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} className="col-md-12">
                <Form.Label>Add Document</Form.Label>
                <CustomFileSelector
                  selectedFiles={docFiles}
                  setSelectedFiles={setDocFiles}
                  prevSelectedFiles={prevDocFiles}
                  setPrevSelectedFiles={setPrevDocFiles}
                />
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-start align-items-start gap-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleClose}
              ></input>
              <input
                type="submit"
                value="Submit"
                className="signup_button"
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <ul>
              <li>
                <h6 className="breadcrumb_main_heading">
                  <Link to="/customerdashboard">Projects</Link>
                </h6>
              </li>
              <li>
                <span className="breadcrumb_paragraph"> New Request</span>
              </li>
            </ul>
          </div>
          <div>
            <div className="d-flex justify-content-end list-group-item">
              <div className="d-flex justify-content-end pt-1 pb-1 gap-2 ">
                {!isProjectClosed && (
                  <Custombuttons
                    label={"Add New Request"}
                    buttonIcon={Add}
                    className="add_employee_btn"
                    onClick={handleShow}
                  />
                )}
                <div>
                  <Link to="/customerdashboard">
                    <Outlinebutton name="Back" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="d-flex justify-content-between align-items-center">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item headingfour">
                  <Link to="/customerdashboard">{`Proj ${projectId}_${customerFname}_${customerLname}`}</Link>
                </li>
                <li
                  class="breadcrumb-item active m-auto"
                  aria-current="page"
                  style={{ fontSize: "18px" }}
                >
                  New Request
                </li>
              </ol>
            </nav>
          </div>
          <div className="d-flex gap-2">
            {!isProjectClosed && (
              <Custombuttons
                label={"Add New Request"}
                buttonIcon={Add}
                className="add_employee_btn"
                onClick={handleShow}
              />
            )}
            <Link to="/customerdashboard">
              <Outlinebutton name="Back" />
            </Link>
          </div>
        </div> */}
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div>
            <label classname="breadcrumb_description">
              Project Id:{" "}
              <span style={{ fontWeight: "400" }}>{`Proj ${projectId}`}</span>
            </label>
          </div>
          <hr />
          <div className="table_section">
            <div className="table-responsive-lg">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">
                      <div>Date</div>
                    </th>
                    <th scope="col">
                      <div>Assigned by</div>
                    </th>
                    <th scope="col">
                      <div>Assigner Role</div>
                    </th>

                    <th scope="col">
                      <div>Assigned to</div>
                    </th>
                    <th scope="col">
                      <div>Assignee Role</div>
                    </th>
                    <th scope="col">
                      <div>Title</div>
                    </th>
                    <th scope="col">
                      <div>Description</div>
                    </th>
                    <th scope="col">
                      <div>Status</div>
                    </th>
                    <th scope="col">
                      <div>Documents</div>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results?.map((row) => {
                    return (
                      <tr>
                        <td>{conDateToReadable(row.created_at)}</td>
                        <td>{row?.queries?.created_by?.username}</td>
                        <td>{row?.queries?.created_by?.role}</td>
                        <td>{row?.queries?.assigned_to?.[0]?.username}</td>
                        <td>{row?.queries?.assigned_to?.[0]?.role}</td>
                        <td>{row?.queries?.title}</td>
                        <td>
                          <LightTooltip
                            title={row?.queries?.description}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {row?.queries?.description}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>{row?.queries?.status}</td>
                        <td className="active-links">
                          <span
                            onClick={() => {
                              setSelectedRequest(row);
                              handleShowDocView();
                            }}
                            class="footer_link"
                          >
                            View
                          </span>
                        </td>
                        <td className="text-center">
                          {!isProjectClosed &&
                            row?.queries?.status?.toLowerCase() ===
                              "pending" && (
                              <div class="btn-group dropend">
                                <span
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <MenuIcon
                                    alt="no_img"
                                    className="edit_icon"
                                  />
                                </span>
                                <ul class="dropdown-menu">
                                  <li
                                    style={{
                                      borderBottom: "1px solid #CBCBCB",
                                    }}
                                    className="pt-2 pb-2"
                                  >
                                    <button
                                      class="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                                      type="button"
                                      onClick={() => {
                                        setSelectedRequest(row);
                                        setIsEditing(true);
                                        handleShow();
                                      }}
                                    >
                                      <EditIcon
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />
                                      <span className="ms-2 ">Edit</span>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                        </td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={10} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Custompagenation data={tableData} setData={setTableData} />
            </div>
          </div>
          <AddEditRequestModal />
          <DocumentViewModal
            show={showdocview}
            onHide={handleCloseDocView}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
            handleCloseView={handleCloseDocView}
            links={selectedRequest?.docs}
          />
          <Modal
            show={showview}
            onHide={handleCloseView}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">View Request</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleCloseView}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2 disable-state"
                  >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      placeholder="Changes Required"
                      className="inputfield"
                    ></Form.Control>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group className="col-md-12 disable-state">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      className="inputfield"
                      value="Want a little change in the design"
                    />
                  </Form.Group>
                </Row>

                <Row className="mt-4 mb-5">
                  <div className="table_section">
                    <div className="table-responsive-lg">
                      <table className="table table-bordered align-middle">
                        <thead className="table_head_bg">
                          <tr>
                            <th scope="col">
                              <div>Date</div>
                            </th>
                            <th scope="col">
                              <div>Assigned by</div>
                            </th>
                            <th scope="col">
                              <div>Assigner Role</div>
                            </th>

                            <th scope="col">
                              <div>Assigned to</div>
                            </th>
                            <th scope="col">
                              <div>Assignee Role</div>
                            </th>

                            <th scope="col">
                              <div>Status</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>08/12/2023 04:04 PM</td>
                            <td>Naveen</td>

                            <td>Client</td>
                            <td>Santosh_CH</td>
                            <td>Civil Head</td>

                            <td>Active</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Row>

                <hr />
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={showassign}
            onHide={handleCloseAssign}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">Reassign</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleCloseAssign}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group
                    controlId="formGridEmail"
                    className="col-lg-6 col-md-12 mb-2 disable-state"
                  >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      placeholder="Changes Required"
                      className="inputfield"
                    ></Form.Control>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group className="col-md-12">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      className="inputfield"
                      value="Want a little change in the design"
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group as={Col} className="col-md-12 pb-5">
                    <Form.Label>Add Document</Form.Label>
                    <div className="d-flex justify-content-start align-items-center gap-3">
                      <Uploadbutton name="Upload file" />
                      <button
                        type="button"
                        class="outline_btn"
                        style={{
                          height: "20px",
                          width: "20px",
                          padding: "10px",
                        }}
                      >
                        <AddIcon style={{ fontSize: "20px" }} />
                      </button>
                    </div>
                  </Form.Group>
                </Row>
                <hr />

                <div className="d-flex justify-content-center align-items-start gap-2">
                  <input
                    type="button"
                    value="Reassign"
                    className="signup_button"
                    onClick={handleCloseAssign}
                  ></input>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Customerqueries;
