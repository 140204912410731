import React from "react";
import { Link } from "react-router-dom";

const Pagenotfound = () => {
  return (
    <div className="login_section">
      <div className="error-container">
        <h1 className="error-head">404</h1>
        <div className="error-divider"></div>
        <p className="error-message" style={{ margin: "0px" }}>
          The page you’re looking for doesn’t exist.
        </p>
      </div>
      <div className="mt-4">
        <Link to="/home">
          <input
            type="button"
            className="signup_button mx-auto"
            value="Back to Workspace"
          ></input>
        </Link>
      </div>
    </div>
  );
};

export default Pagenotfound;
