import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ProjecttrackingIcon } from "../assets/img/signoff.svg";
import { ReactComponent as PaymentdetialsIcon } from "../assets/img/payment_dark.svg";
import { ReactComponent as SignoffIcon } from "../assets/img/signoff.svg";
import { ReactComponent as PreexecutionIcon } from "../assets/img/preexecution.svg";
import { ReactComponent as QueritesIcon } from "../assets/img/quaries.svg";
import { ReactComponent as ActualIcon } from "../assets/img/actual.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import location_icon from "../assets/img/location.svg";
import { getProject } from "../api/service";

import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const CustomerDashboard = () => {
  const currentUserId = localStorage.getItem("userid");
  const [projects, setProjects] = useState(null);
  useEffect(() => {
    getProject({ customer__id: currentUserId })
      .then((res) => {
        setProjects(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);
  return (
    <React.Fragment>
      <div className="banner-section">
        <div>
          <h4 className="headingfour">Projects</h4>
        </div>
        <hr />
        <div className="mb-3 mt-2">
          <div className="row">
            {projects?.results.map((project) => {
              return (
                <div className="col-md-6 col-lg-6 col-xl-4 p-2">
                  <div className="card h-100 customer_dashboard_card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h4 className="projects_heading">{`Proj ${project.id}`}</h4>
                        </div>
                        <div className="d-flex jusitfy-content-center align-items-center gap-2">
                          <h6 className="m-0 token_recived_text response_text_heading">
                            {project.status}
                          </h6>
                          <div className="btn-group">
                            <span
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <LightTooltip
                                title="Options"
                                arrow
                                placement="bottom"
                              >
                                <MenuIcon alt="no_img" className="edit_icon" />
                              </LightTooltip>
                            </span>
                            <ul className="dropdown-menu">
                              <li
                                style={{ borderBottom: "1px solid #CBCBCB" }}
                                className="pt-2 pb-2"
                              >
                                <Link
                                  to="/projecttracking"
                                  state={{
                                    projectId: project.id,
                                    customerFname: project.customer?.first_name,
                                    customerLname: project.customer?.last_name,
                                  }}
                                >
                                  <button
                                    className="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                                    type="button"
                                  >
                                    <ProjecttrackingIcon
                                      style={{ height: "16px", width: "16px" }}
                                    />
                                    <span className="ms-2 ">
                                      Project Tracking
                                    </span>
                                  </button>
                                </Link>
                              </li>

                              <li
                                style={{ borderBottom: "1px solid #CBCBCB" }}
                                className="pt-2 pb-2"
                              >
                                <Link
                                  to="/paymentdetails"
                                  state={{
                                    projectId: project.id,
                                    customerFname: project.customer?.first_name,
                                    customerLname: project.customer?.last_name,
                                  }}
                                >
                                  <button
                                    className="dropdown-item d-flex projects_dropdownlinks"
                                    type="button"
                                  >
                                    <PaymentdetialsIcon
                                      style={{ height: "18px", width: "18px" }}
                                    />
                                    <span className="ms-2 d-flex">
                                      Payment Details
                                    </span>
                                  </button>
                                </Link>
                              </li>
                              <li
                                style={{ borderBottom: "1px solid #CBCBCB" }}
                                className="pt-2 pb-2"
                              >
                                <Link
                                  to="/salessignoffdoc"
                                  state={{
                                    projectId: project.id,
                                    received_amount: project.received_amount,
                                    received_on: project.received_on,
                                    customerFname: project.customer?.first_name,
                                    customerLname: project.customer?.last_name,
                                  }}
                                >
                                  <button
                                    className="dropdown-item d-flex projects_dropdownlinks"
                                    type="button"
                                  >
                                    <SignoffIcon
                                      style={{ height: "16px", width: "16px" }}
                                    />

                                    <span className="ms-2 d-flex">
                                      Sign Off Documents
                                    </span>
                                  </button>
                                </Link>
                              </li>
                              <li
                                style={{ borderBottom: "1px solid #CBCBCB" }}
                                className="pt-2 pb-2"
                              >
                                <Link
                                  to="/civilactualprocurement"
                                  state={{
                                    projectId: project?.id,
                                    customerFname: project.customer?.first_name,
                                    customerLname: project.customer?.last_name,
                                  }}
                                >
                                  <button
                                    className="dropdown-item d-flex projects_dropdownlinks"
                                    type="button"
                                  >
                                    <ActualIcon
                                      style={{ height: "16px", width: "16px" }}
                                    />

                                    <span className="ms-2 d-flex">
                                      Sub-contractor Quotations
                                    </span>
                                  </button>
                                </Link>
                              </li>

                              <li className="pt-2 pb-2">
                                <Link
                                  to="/newrequest"
                                  state={{
                                    projectId: project.id,
                                    customerFname: project.customer?.first_name,
                                    customerLname: project.customer?.last_name,
                                    isProjectClosed:
                                      project?.status?.toLowerCase() ===
                                        "project on hold" ||
                                      project.status?.toLowerCase() ===
                                        "project closed",
                                  }}
                                >
                                  <button
                                    className="dropdown-item d-flex projects_dropdownlinks"
                                    type="button"
                                  >
                                    <QueritesIcon
                                      style={{ height: "16px", width: "16px" }}
                                    />

                                    <span className="ms-2 d-flex">
                                      New Request
                                    </span>
                                  </button>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <h6 className="m-0 token_recived_text response_text">
                        {project.status}
                      </h6>
                      <div className="media d-flex mt-3">
                        <LightTooltip title="Location" arrow placement="bottom">
                          <img
                            className="align-self-start me-2"
                            style={{ cursor: "pointer" }}
                            src={location_icon}
                            alt="no_img"
                            onClick={() => {
                              if (project.location !== "") {
                                window.open(project.location);
                              }
                            }}
                          />
                        </LightTooltip>
                        <div className="media-body">
                          <h6 className="customer_card_paragraph">
                            {project.address}
                          </h6>
                          <h6 className="customer_card_paragraph">
                            <b>City</b>: {project.city}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerDashboard;
