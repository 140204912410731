import AddIcon from "@mui/icons-material/Add";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Custombuttons from "../Customcomponents/Custombuttons";
import Custompagenation from "../Customcomponents/Custompagenation";
import Customsearch from "../Customcomponents/Customsearch";
import DocumentViewModal from "../Customcomponents/DocumentViewModal";
import {
  getProject,
  getSubCategories,
  getSubcontractor,
  getSubcontractor_estimate,
  postDrivem,
  postSubcontractor_estimate,
  postSupplier_estimate_pay_history,
} from "../api/service";
import { ReactComponent as Add } from "../assets/img/add.svg";
import close_icon from "../assets/img/close.svg";
import cancel, { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import { ReactComponent as PayIcon } from "../assets/img/pay.svg";
import { conDateToReadable, getIds } from "../components/utils";
import PriceEstimatationHistory from "./PriceEstimatationHistory";
import CustomFileSelector from "../Customcomponents/CustomFileSelector";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));
const SubcontractorEstimate = () => {
  const [tableData, setTableData] = useState(null);
  const currentUserId = localStorage.getItem("userid");
  const currentUserRole = localStorage.getItem("role");
  const showAddSubConEst = ["civil_head", "civil_team"].includes(
    currentUserRole
  );
  const isCivilHead = currentUserRole === "civil_head";

  /*
  isEditing->false add new estimate
  isEditing->true editing selected estimate
  isEditing->null update status of selected estimate
  */
  const [isEditing, setIsEditing] = useState(null);
  const [selSubConEst, setSelSubConEst] = useState(null);

  const [projects, setProjects] = useState(null);
  const [subContractors, setSubContractors] = useState(null);

  const [categories, setCategories] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setIsEditing(null);
    setSelSubConEst(null);
  };
  const handleShow = () => setShow(true);

  const [showview, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [update, setUpdate] = useState(false);
  const handleCloseUpdate = () => setUpdate(false);
  const handleShowUpdate = () => setUpdate(true);

  const [payment, setPayment] = useState(false);
  const handleClosePayment = () => {
    setPayment(false);
    setSelSubConEst(null);
  };
  const handleShowPayment = () => setPayment(true);

  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  const [searchValue, setSearchValue] = useState("");
  const [dropdownVisibleIndex, setDropdownVisibleIndex] = useState(-1);
  const dropdownRefs = useRef([]);
  const deleteButtonRef = useRef(null); // Reference to the delete button

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      deleteButtonRef.current.click(); // Trigger click event on the delete button
    }
  };

  useEffect(() => {
    if (deleteshow) {
      document.addEventListener("keydown", handleKeyPress);
      // Set focus on the delete button when the modal is shown
      deleteButtonRef.current.focus();
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [deleteshow]); // Run effect when deleteshow changes

  const handleToggleDropdown = (index) => {
    setDropdownVisibleIndex(index === dropdownVisibleIndex ? -1 : index);
  };

  const handleLinkClick = () => {
    setDropdownVisibleIndex(-1);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          if (index === dropdownVisibleIndex) {
            setDropdownVisibleIndex(-1);
          }
        }
      });
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisibleIndex]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchClick = () => {
    // Handle the search click action, e.g., navigate to search results
  };

  //api call
  function apiCall() {
    getSubcontractor_estimate({ active: "True", search: searchValue })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function getCategoryAndSubCategory() {
    getSubCategories({ category__name: "SubContractor", name: "Estimates" })
      .then((res) => {
        if (res?.results.length > 0) {
          setCategories(res.results[0]);
        } else {
          toast.error("Add SubContractor and Estimates in admin panel");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function getProjectsAndSubContractors() {
    let projectPrm = getProject({ pagination: false, active: "True" });
    let subConPrm = getSubcontractor({ pagination: false, active: "True" });
    Promise.all([projectPrm, subConPrm])
      .then(([projectRes, subConRes]) => {
        setProjects(projectRes);
        setSubContractors(subConRes);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  useEffect(() => {
    apiCall();
    getProjectsAndSubContractors();
    getCategoryAndSubCategory();
  }, []);

  useEffect(() => {
    apiCall();
  }, [searchValue]);
  // add subcontractor estimate modal
  function AddSubContractorEstimateModa() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [payload, setPayload] = useState({
      id: selSubConEst?.id,
      supplier: selSubConEst?.supplier?.id ?? "",
      project: selSubConEst?.project.id ?? "",
      estimated_amount: selSubConEst?.estimated_amount ?? "",
      description: selSubConEst?.description ?? "",
      created_by: selSubConEst?.created_by ?? currentUserId,
      updated_by: currentUserId,
    });

    //documents
    const [prevDocuments, setPrevDocuments] = useState([
      ...(selSubConEst?.docs ?? []),
    ]);
    const [documents, setDocuments] = useState([]);

    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (payload.project === "") {
        toast.error("Please select the project");
        return;
      } else if (payload.supplier === "") {
        toast.error("Please select sub-contractor");
        return;
      } else if (payload.estimated_amount === "") {
        toast.error("Please enter quoted amount");
        return;
      } else if (payload.description === "") {
        toast.error("Please add description");
        return;
      } else if (prevDocuments.length === 0 && documents.length === 0) {
        toast.error("Please select files");
        return;
      }
      if (!isFormSubmitted) {
        setIsFormSubmitted(true);
        let documentsPrms = [];
        for (let index = 0; index < documents?.length; index++) {
          let form = new FormData();
          form.append("file", documents[index]);
          form.append("categories", categories.category?.id);
          form.append("subcategories", categories?.id);
          documentsPrms.push(postDrivem(form));
        }
        Promise.all(documentsPrms).then((documentsRes) => {
          let docIds = documentsRes.map((documentRes) => {
            return documentRes.id;
          });
          let prevDocIds = getIds(prevDocuments);
          postSubcontractor_estimate({
            ...payload,
            docs: [...docIds, ...prevDocIds],
          })
            .then((res) => {
              toast.success(
                `Sub-contractor estimate is ${
                  isEditing == true ? "updated" : "added"
                }`
              );
              handleClose();
              setIsFormSubmitted(false);
              apiCall();
            })
            .catch((err) => {
              setIsFormSubmitted(false);
              toast.error(err.message);
            });
        });
      }
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size={isEditing === null ? "lg" : "md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 className="headingfour">
            {isEditing === null ? "Update" : isEditing ? "Edit" : "Add"}{" "}
            Estimates
          </h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row>
              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label>Project</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="project"
                  value={payload.project}
                  onChange={handleInputChange}
                  required
                >
                  <option hidden value="">
                    Select Project
                  </option>
                  {projects?.map((project) => {
                    return (
                      !(
                        project.status === "project on hold" ||
                        project.status === "project closed"
                      ) && (
                        <option
                          value={project.id}
                        >{`Proj ${project.id}_${project.customer?.first_name}_${project.customer?.last_name}`}</option>
                      )
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label>Sub-Contractor</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="supplier"
                  value={payload.supplier}
                  onChange={handleInputChange}
                  required
                >
                  <option hidden value="">
                    Select Subcontractor
                  </option>
                  {subContractors?.map((subContractor) => {
                    return (
                      <option value={subContractor.id}>
                        {subContractor.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label>Total Amount Quoted</Form.Label>
                <Form.Control
                  placeholder="Enter Amount"
                  type="number"
                  className="inputfield"
                  name="estimated_amount"
                  value={payload.estimated_amount}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} className="col-md-12">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  placeholder="Enter Description"
                  name="description"
                  value={payload.description}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group as={Col} className="col-md-12 pb-5">
                <Form.Label>Add Documents</Form.Label>
                <CustomFileSelector
                  selectedFiles={documents}
                  setSelectedFiles={setDocuments}
                  prevSelectedFiles={prevDocuments}
                  setPrevSelectedFiles={setPrevDocuments}
                />
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-start align-items-center gap-2 pt-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleClose}
              ></input>
              <input
                type="submit"
                value="Submit"
                className="signup_button"
              ></input>
            </div>

            <hr />
            {isEditing === null && (
              <Row className="mb-4">
                <Form.Group as={Col} className="col-md-12">
                  <div className="table_section">
                    <div className="table-responsive">
                      <table className="table table-bordered align-middle">
                        <thead className="table_head_bg">
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">% of Work done</th>
                            <th scope="col">Description</th>

                            <th scope="col">Image</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>22-12-2023</td>
                            <td>50%</td>
                            <td>Lorum ipsum lorem ipsum</td>

                            <td className="active-links">
                              <span className="footer_link" to="#/">
                                View
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Form.Group>
              </Row>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  //payment modal
  function AddPaymentModal() {
    const paymentTypes = [
      "netbanking",
      "cheque",
      "cash",
      "upi",
      "imps",
      "other",
    ];
    const [payload, setPayload] = useState({
      amt_paid: "",
      payment_type: "",
      work_done_pct: "",
      description: "",
      estimation: selSubConEst?.id,
    });

    const [prevTaskFiles, setPrevTaskFiles] = useState([]);
    const [taskFiles, setTaskFiles] = useState([]);

    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (payload.amt_paid === "") {
        toast.error("Please enter amount paid");
        return;
      } else if (payload.payment_type === "") {
        toast.error("Please select payment type");
        return;
      } else if (payload.work_done_pct === "") {
        toast.error("Please enter % work done");
        return;
      } else if (payload.description === "") {
        toast.error("Please add description");
        return;
      } else if (prevTaskFiles.length === 0 && taskFiles.length === 0) {
        toast.error("Please select files to upload");
        return;
      }
      let taskFilesPrms = [];
      for (let index = 0; index < taskFiles.length; index++) {
        let form = new FormData();
        form.append("file", taskFiles[index]);
        form.append("categories", categories.category?.id);
        form.append("subcategories", categories?.id);
        taskFilesPrms.push(postDrivem(form));
      }
      Promise.all(taskFilesPrms).then((taskFilesRes) => {
        let docIds = taskFilesRes.map((taskFileRes) => {
          return taskFileRes.id;
        });
        postSupplier_estimate_pay_history({
          ...payload,
          docs: docIds,
        })
          .then((res) => {
            handleClosePayment();
            apiCall();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      });
    }

    const hideForm = !(
      selSubConEst?.project?.status?.toLowerCase() === "project on hold" ||
      selSubConEst?.project?.status?.toLowerCase() === "project closed"
    );

    function TitleSubtitle({ title, subTitle }) {
      return (
        <Col>
          <h6 className="addpayment_subheading">
            {title} : <span>{subTitle}</span>
          </h6>
        </Col>
      );
    }

    return (
      <Modal
        show={payment}
        onHide={handleClosePayment}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 className="headingfour">
            {hideForm ? "Add Payment Details" : "Payment Details"}
          </h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClosePayment}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <TitleSubtitle
              title="Project"
              subTitle={`Proj ${selSubConEst?.project?.id}_${selSubConEst?.project?.customer?.first_name}_${selSubConEst?.project?.customer?.last_name}`}
            />
            <TitleSubtitle
              title="Sub Contractor"
              subTitle={selSubConEst?.supplier?.name}
            />
            <TitleSubtitle
              title="Total Work Done(%)"
              subTitle={selSubConEst?.percentage_of_work_done}
            />
          </Row>
          <Row>
            <TitleSubtitle
              title="Amount Quoted"
              subTitle={selSubConEst?.estimated_amount}
            />
            <TitleSubtitle
              title="Total Amount Paid"
              subTitle={selSubConEst?.amount_paid}
            />
            <TitleSubtitle
              title="Amount to be paid"
              subTitle={selSubConEst?.balance_amount}
            />
          </Row>
          <hr />
          {hideForm && (
            <>
              <Form onSubmit={handleSubmitForm}>
                <Row className="mb-2">
                  <Form.Group
                    className="col-lg-6 col-md-12  mb-2"
                    controlId="formGridEmail"
                  >
                    <Form.Label>Current Work Done (%)</Form.Label>
                    <Form.Control
                      placeholder="Enter the current work done in %"
                      type="number"
                      className="inputfield"
                      name="work_done_pct"
                      value={payload.work_done_pct}
                      min={1}
                      max={100 - selSubConEst?.percentage_of_work_done ?? 0}
                      onChange={handleInputChange}
                      required
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group
                    className="col-lg-6 col-md-12  mb-2"
                    controlId="formGridEmail"
                  >
                    <Form.Label>Payable Amount</Form.Label>
                    <Form.Control
                      placeholder="Enter payable amount"
                      type="number"
                      className="inputfield"
                      name="amt_paid"
                      min={1}
                      max={selSubConEst?.balance_amount}
                      value={payload.amt_paid}
                      onChange={handleInputChange}
                      required
                    ></Form.Control>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    className="col-lg-6 col-md-12  mb-2"
                    controlId="formGridPassword"
                  >
                    <Form.Label>Payment Type</Form.Label>
                    <Form.Select
                      className="inputfield"
                      name="payment_type"
                      value={payload.payment_type}
                      onChange={handleInputChange}
                      required
                    >
                      <option hidden value="">
                        Selet payment type
                      </option>
                      {paymentTypes.map((paymentType) => {
                        return <option>{paymentType}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group as={Col} className="col-md-12">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      className="inputfield"
                      placeholder="Enter description"
                      name="description"
                      value={payload.description}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group as={Col} className="col-md-12">
                    <Form.Label>Add File</Form.Label>
                    <CustomFileSelector
                      selectedFiles={taskFiles}
                      setSelectedFiles={setTaskFiles}
                      prevSelectedFiles={prevTaskFiles}
                      setPrevSelectedFiles={setPrevTaskFiles}
                    />
                  </Form.Group>
                </Row>
                <hr />
                <div className="d-flex justify-content-start align-items-start gap-2">
                  <input
                    type="button"
                    value="Cancel"
                    className="outline_btn"
                    onClick={handleClosePayment}
                  ></input>
                  <input
                    type="submit"
                    value="Submit"
                    className="signup_button"
                  ></input>
                </div>
              </Form>
              <hr />
            </>
          )}
          <Row className="mb-4">
            <div className="table_section">
              <div className="table-responsive">
                <PriceEstimatationHistory estimationId={selSubConEst?.id} />
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Sub-Contractor Estimates</h4>
          </div>
          {showAddSubConEst && (
            <div>
              <div>
                <Custombuttons
                  label={"Add New Estimates"}
                  buttonIcon={Add}
                  className="add_employee_btn"
                  onClick={() => {
                    setIsEditing(false);
                    handleShow();
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div className="d-flex justify-content-start align-items-center mb-3 gap-2">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
          <div className="table_section table-responsive">
            <Table bordered>
              <thead className="table_head_bg">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col" style={{ width: "150px" }}>
                    Project
                  </th>
                  <th scope="col">Sub-contractor</th>
                  <th scope="col" style={{ width: "150px" }}>
                    Description
                  </th>
                  <th scope="col">Total Quoted amount</th>
                  <th scope="col">Amount paid</th>
                  <th scope="col">Amount to be paid</th>
                  <th scope="col">Documents</th>
                  {isCivilHead && <th scope="col"></th>}
                </tr>
              </thead>
              <tbody>
                {tableData?.results.map((row, index) => {
                  return (
                    <tr>
                      <td>{conDateToReadable(row.created_at)}</td>
                      <td>
                        <LightTooltip
                          title={`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                          arrow
                          placement="bottom"
                        >
                          <div className="table_text_overflow">
                            {`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                          </div>
                        </LightTooltip>
                      </td>
                      <td>{row?.supplier.name}</td>
                      <td>
                        <LightTooltip
                          title={row.description}
                          arrow
                          placement="bottom"
                        >
                          <div className="table_text_overflow">
                            {row.description}
                          </div>
                        </LightTooltip>
                      </td>
                      <td>{row.estimated_amount}</td>
                      <td>{row.amount_paid}</td>
                      <td>{row.balance_amount}</td>
                      <td className="active-links">
                        <span
                          className="footer_link"
                          onClick={() => {
                            setSelSubConEst(row);
                            handleShowView();
                          }}
                        >
                          View
                        </span>
                      </td>
                      {isCivilHead && (
                        <td className="text-center">
                          <div
                            ref={(ref) => (dropdownRefs.current[index] = ref)}
                          >
                            <span
                              onClick={() => handleToggleDropdown(index)}
                              style={{ position: "relative" }}
                            >
                              <LightTooltip
                                title="Options"
                                arrow
                                placement="bottom"
                              >
                                <MenuIcon alt="no_img" className="edit_icon" />
                              </LightTooltip>
                            </span>
                            <ul
                              className={`dropdown-menu custom_header  ${
                                dropdownVisibleIndex === index ? "show" : ""
                              }`}
                            >
                              {!(
                                row.project.status === "project on hold" ||
                                row.project.status === "project closed"
                              ) && (
                                <li
                                  style={{ borderBottom: "1px solid #CBCBCB" }}
                                  className="pt-2 pb-2"
                                  onClick={handleLinkClick}
                                >
                                  <div
                                    onClick={() => {
                                      setSelSubConEst(row);
                                      setShow(true);
                                      setIsEditing(true);
                                    }}
                                  >
                                    <button
                                      className="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                                      type="button"
                                    >
                                      <EditIcon
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />
                                      <span className="ms-2 ">Edit</span>
                                    </button>
                                  </div>
                                </li>
                              )}
                              <li
                                style={{ borderBottom: "1px solid #CBCBCB" }}
                                className="pt-2 pb-2"
                                onClick={handleLinkClick}
                              >
                                <div
                                  onClick={() => {
                                    setSelSubConEst(row);
                                    handleShowPayment();
                                  }}
                                >
                                  <button
                                    className="dropdown-item d-flex projects_dropdownlinks"
                                    type="button"
                                  >
                                    <PayIcon
                                      style={{ height: "16px", width: "16px" }}
                                    />

                                    <span className="ms-2 d-flex">Pay</span>
                                  </button>
                                </div>
                              </li>
                              {!(
                                row.project.status === "project on hold" ||
                                row.project.status === "project closed"
                              ) && (
                                <li
                                  className="pt-2 pb-2"
                                  onClick={handleLinkClick}
                                >
                                  <button
                                    className="dropdown-item d-flex projects_dropdownlinks"
                                    type="button"
                                    onClick={() => {
                                      setSelSubConEst(row);
                                      handleDeleteShow();
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{ height: "16px", width: "16px" }}
                                    />
                                    <span className="ms-2 d-flex">Delete</span>
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
                {tableData?.count === 0 && (
                  <tr>
                    <td colSpan={10} style={{ textAlign: "center" }}>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <AddSubContractorEstimateModa />
          <AddPaymentModal />
          <DocumentViewModal
            show={showview}
            onHide={() => {
              setShowView(false);
              setSelSubConEst(null);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
            handleCloseView={() => {
              setShowView(false);
              setSelSubConEst(null);
            }}
            links={selSubConEst?.docs}
          />

          <Modal
            show={deleteshow}
            onHide={handleDeleteClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="deletebody">
              <div>
                <div className="">
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <div className="media d-flex">
                        <img
                          className="align-self-start mr-3"
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          src={cancel}
                          alt="no_img"
                        />
                        <div
                          className="media-body ms-3"
                          style={{
                            position: "relative",
                          }}
                        >
                          <h5 className="mt-0 alerts-heading text-start">
                            Are you sure?
                          </h5>
                          <p className="text-start">
                            Do you really want to delete these records ? This
                            process cannot be undone
                          </p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-end">
                      <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                        <input
                          type="button"
                          value="Cancel"
                          className="outline_btn"
                          onClick={handleDeleteClose}
                        ></input>
                        <input
                          type="button"
                          value="Delete"
                          className="delete_button"
                          ref={deleteButtonRef}
                          onClick={() => {
                            let payload = {
                              id: selSubConEst?.id,
                              supplier: selSubConEst?.supplier?.id ?? "",
                              project: selSubConEst?.project.id ?? "",
                              estimated_amount:
                                selSubConEst?.estimated_amount ?? "",
                              description: selSubConEst?.description ?? "",
                              created_by:
                                selSubConEst?.created_by ?? currentUserId,
                              updated_by: currentUserId,
                              active: false,
                              docs: getIds(selSubConEst.docs),
                            };
                            postSubcontractor_estimate(payload).then((res) => {
                              toast.success(
                                "Subcontractor estimate is deleted"
                              );
                              handleDeleteClose();
                              apiCall();
                            });
                          }}
                        ></input>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubcontractorEstimate;
