import React, { useState } from "react";
import DashCircleIcon from "../assets/img/dash-circle.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    lineHeight: "1.3",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

export default function CustomFileSelector({
  selectedFiles,
  setSelectedFiles,
  prevSelectedFiles,
  setPrevSelectedFiles,
  multiple = true,
  id = "file-upload",
}) {
  const removeFile = (fileToRemove) => {
    setSelectedFiles(selectedFiles?.filter((file) => file !== fileToRemove));
  };
  const removePrevFile = (fileToRemove) => {
    setPrevSelectedFiles(
      prevSelectedFiles?.filter((file) => file !== fileToRemove)
    );
  };

  return (
    <>
      <div>
        <input
          type="file"
          id={id}
          className="inputfile"
          multiple={multiple}
          onChange={(event) => {
            setSelectedFiles([...selectedFiles, ...event.target.files]);
          }}
          accept="image/*"
        />
        <label
          htmlFor={id}
          className="add_employee_btn d-flex flex-row"
          style={{
            cursor: "pointer",
            width: "35%",
            // minWidth: "100px",
            // maxWidth: "300px",
            height: "auto",
          }}
        >
          <span>
            {(selectedFiles?.length === 0 && prevSelectedFiles?.length === 0) ||
            (selectedFiles === null && prevSelectedFiles === null)
              ? multiple === true
                ? "Select files"
                : "Select file"
              : selectedFiles?.length + prevSelectedFiles?.length === 1
              ? "1 file is selected"
              : `${
                  selectedFiles?.length + prevSelectedFiles?.length
                } files are selected`}
          </span>
        </label>
      </div>
      <div className="selected_files_list">
        {prevSelectedFiles?.map((prevSelectedFile, index) => {
          let filename = prevSelectedFile.file?.split("/").at(-1);
          return (
            <div
              key={index}
              className="selected-file d-flex justify-content-between mt-2"
            >
              <LightTooltip title={filename} arrow>
                <span>{filename}</span>
              </LightTooltip>
              <img
                src={DashCircleIcon}
                alt="no_img"
                onClick={() => removePrevFile(prevSelectedFile)}
                className="close_list_icon"
              />
            </div>
          );
        })}
        {selectedFiles?.map((selectedFile, index) => (
          <div
            key={index}
            className="selected-file d-flex justify-content-between mt-2"
          >
            <div>
              <LightTooltip title={selectedFile.name} arrow>
                <span>{selectedFile.name}</span>
              </LightTooltip>
            </div>
            <div>
              <img
                src={DashCircleIcon}
                alt="no_img"
                onClick={() => removeFile(selectedFile)}
                className="close_list_icon"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
