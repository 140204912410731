import React, { useEffect, useState,useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Custompagenation from "../Customcomponents/Custompagenation";
import close_icon from "../assets/img/close.svg";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ListGroup from "react-bootstrap/ListGroup";
import { toast } from "react-toastify";
import Custombuttons from "../Customcomponents/Custombuttons";
import Customsearch from "../Customcomponents/Customsearch";
import DocumentViewModal from "../Customcomponents/DocumentViewModal";
import {
  getSubCategories,
  getSubcontractor,
  getSubcontractor_type,
  postDrivem,
  postSubcontractor,
} from "../api/service";
import { ReactComponent as Add } from "../assets/img/add.svg";
import cancel from "../assets/img/delete.svg";
import { ReactComponent as ViewIcon } from "../assets/img/view.svg";
import { getIds, isMobileNumValid } from "../components/utils";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Vendorlist = () => {
  const [tableData, setTableData] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [show, setShow] = useState(false);
  const [vendorTypes, setVendorTypes] = useState([]);
  const currentUserRole = localStorage.getItem("role");
  const showAddVendorList = currentUserRole === "purchase_head";

  const [contractorsCategory, setContractorsCategory] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSelectedVendor(null);
    setIsEditing(null);
  };
  const handleShow = () => setShow(true);
  const [searchValue, setSearchValue] = useState("");

  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deleteButtonRef = useRef(null); // Reference to the delete button

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      deleteButtonRef.current.click(); // Trigger click event on the delete button
    }
  };

  useEffect(() => {
    if (deleteshow) {
      document.addEventListener('keydown', handleKeyPress);
      // Set focus on the delete button when the modal is shown
      deleteButtonRef.current.focus();
    } else {
      document.removeEventListener('keydown', handleKeyPress);
    }
    
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [deleteshow]); // Run effect when deleteshow changes


  function apiCall() {
    getSubcontractor({
      active: "True",
      category__supplier_type: "vendor",
      search: searchValue,
    })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  function getVendorTypes() {
    getSubcontractor_type({ supplier_type: "vendor", pagination: false })
      .then((res) => {
        setVendorTypes(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function getCategoryAndSubCategory() {
    getSubCategories({ category__name: "SubContractor" })
      .then((res) => {
        if (res?.results.length > 0) {
          setContractorsCategory(res.results);
        } else {
          toast.error("Add Task and Task History in admin panel");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  useEffect(() => {
    getVendorTypes();
    getSubCategories();
    getCategoryAndSubCategory();
  }, []);
  useEffect(() => {
    apiCall();
  }, [searchValue]);

  //add,view,edit vendor modal
  function VendorModal() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [payload, setPayload] = useState({
      id: selectedVendor?.id,
      name: selectedVendor?.name ?? "",
      phone: selectedVendor?.phone ?? "",
      gst_number: selectedVendor?.gst_number ?? "",
      address: selectedVendor?.address ?? "",
      description: selectedVendor?.description ?? "",
      category: selectedVendor?.category?.id ?? "",
    });

    function getFileByType(type) {
      for (let index = 0; index < selectedVendor?.docs.length; index++) {
        if (selectedVendor.docs[index].sub_category === type) {
          return selectedVendor.docs[index];
        }
      }
    }

    const [aadharFile, setAadharFile] = useState(getFileByType("Aadhar"));
    const [panFile, setPanFile] = useState(getFileByType("Pan"));

    const [fileLinks, setFileLinks] = useState(null);
    const [showDoc, setShowDoc] = useState(false);

    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }

    async function uploadFile(currentFile, index) {
      let form = new FormData();
      form.append("file", currentFile);
      form.append("categories", contractorsCategory?.[index].category?.id);
      form.append("subcategories", contractorsCategory?.[index].id);
      return await postDrivem(form);
    }

    async function handleSubmitForm(event) {
      event.preventDefault();
      if (payload.name === "") {
        toast.error("Please enter name");
        return;
      } else if (!isMobileNumValid(payload.phone)) {
        toast.error("Please enter valid number");
        return;
      } else if (payload.category === "") {
        toast.error("Please select the vendor type");
        return;
      } else if (payload.gst_number === "") {
        toast.error("Please enter gst number");
        return;
      } else if (payload.address === "") {
        toast.error("Please add Address");
        return;
      } else if (payload.description === "") {
        toast.error("Please enter description");
        return;
      } else if (!aadharFile) {
        toast.error("Please add aadhar card");
        return;
      } else if (!panFile) {
        toast.error("Please add pan card");
        return;
      }
      if (!isFormSubmitted) {
        setIsFormSubmitted(true);
        let docIds = [];
        if (!aadharFile.file) {
          let aadharRes = await uploadFile(aadharFile, 0);
          docIds[0] = aadharRes.id;
        } else {
          docIds[0] = aadharFile.id;
        }
        if (!panFile.file) {
          let panRes = await uploadFile(panFile, 1);
          docIds[1] = panRes.id;
        } else {
          docIds[1] = panFile.id;
        }
        postSubcontractor({ ...payload, docs: docIds })
          .then((res) => {
            toast.success(
              `vendor is ${isEditing === true ? "updated" : "added"}`
            );
            handleClose();
            apiCall();
          })
          .catch((err) => {
            toast.error(err.message);
          })
          .finally(() => {
            setIsFormSubmitted(false);
          });
      }
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">
            {isEditing === null ? "View" : isEditing ? "Edit" : "Add"} Vendor
          </h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm} autoComplete="off">
            <Row>
              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label
                  className={isEditing === null ? "disabled-label" : ""}
                >
                  Name
                </Form.Label>
                <Form.Control
                  placeholder="Enter Name"
                  type="text"
                  className="inputfield"
                  name="name"
                  value={payload.name}
                  onChange={handleInputChange}
                  disabled={isEditing === null}
                  required
                />
              </Form.Group>
              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label
                  className={isEditing === null ? "disabled-label" : ""}
                >
                  Mobile Number
                </Form.Label>
                <Form.Control
                  placeholder="Enter Phone Number"
                  type="tel"
                  className="inputfield"
                  name="phone"
                  maxLength={10}
                  value={payload.phone}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    const allowedChars = /[0-9]/;
                    const allowedKeyCombinations = {
                      "Control+c": true,
                      "Control+v": true,
                      "Control+a": true,
                    };

                    // Allow Backspace and Tab keys without restrictions
                    if (
                      e.key === "Backspace" ||
                      e.key === "Tab" ||
                      e.key === "ArrowLeft" ||
                      e.key === "ArrowRight" ||
                      e.key === "Escape"
                    ) {
                      return;
                    }

                    if (
                      !(
                        allowedChars.test(e.key) ||
                        allowedKeyCombinations[
                          `${e.ctrlKey ? "Control+" : ""}${e.key.toLowerCase()}`
                        ]
                      )
                    ) {
                      e.preventDefault(); // Prevent input if key is not allowed
                    }
                  }}
                  onPaste={(e) => {
                    const pastedText = e.clipboardData
                      .getData("text/plain")
                      .replace(/\D/g, ""); // Remove non-numeric characters
                  }}
                  disabled={isEditing === null}
                  required
                  pattern="[0-9]{10}"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label
                  className={isEditing === null ? "disabled-label" : ""}
                >
                  Vendor Type
                </Form.Label>
                <Form.Select
                  className="inputfield"
                  name="category"
                  value={payload.category}
                  onChange={handleInputChange}
                  disabled={isEditing === null}
                  required
                >
                  <option hidden value="">
                    Select vendor type
                  </option>
                  {vendorTypes.map((vendorType) => {
                    return (
                      <option value={vendorType.id}>{vendorType.name}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-lg-6 col-md-6 mb-2">
                <Form.Label
                  className={isEditing === null ? "disabled-label" : ""}
                >
                  GST Number
                </Form.Label>
                <Form.Control
                  placeholder="Enter GST Number"
                  type="text"
                  className="inputfield"
                  name="gst_number"
                  value={payload.gst_number}
                  onChange={handleInputChange}
                  disabled={isEditing === null}
                  maxLength={15}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                className="col-lg-12 col-md-12 mb-2"
                controlId="formGridEmail"
              >
                <Form.Label
                  className={isEditing === null ? "disabled-label" : ""}
                >
                  Address
                </Form.Label>
                <Form.Control
                  placeholder="Enter Address"
                  type="text"
                  className="inputfield"
                  name="address"
                  value={payload.address}
                  onChange={handleInputChange}
                  disabled={isEditing === null}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} className="col-md-12">
                <Form.Label
                  className={isEditing === null ? "disabled-label" : ""}
                >
                  Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  placeholder="Enter Description"
                  name="description"
                  value={payload.description}
                  onChange={handleInputChange}
                  disabled={isEditing === null}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group className="col-md-12">
                <Form.Label>
                  {isEditing !== null && "Add "}Aadhar Card
                </Form.Label>
                {isEditing === null ? (
                  <div className="d-flex justify-content-start align-items-center gap-3">
                    <div className="active-links">
                      <a
                        class="footer_link"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setFileLinks([selectedVendor.docs?.[0]]);
                          setShowDoc(true);
                        }}
                      >
                        View file
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-start align-items-center gap-3">
                    <div>
                      <input
                        type="file"
                        id="aadhar_file"
                        class="inputfile"
                        onChange={(event) => {
                          setAadharFile(event.target.files[0]);
                        }}
                        accept="image/*"
                      />
                      <label
                        for="aadhar_file"
                        class="add_employee_btn d-flex flex-row"
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          {aadharFile?.file
                            ? aadharFile.file?.split("/").at(-1)
                            : `${aadharFile?.name ?? "Upload File"}`}
                        </span>
                      </label>
                    </div>
                  </div>
                )}
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group className="col-md-12 pb-5">
                <Form.Label>{isEditing !== null && "Add "} Pan Card</Form.Label>
                {isEditing === null ? (
                  <div className="d-flex justify-content-start align-items-center gap-3">
                    <div className="active-links">
                      <a
                        class="footer_link"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setFileLinks([selectedVendor.docs?.[1]]);
                          setShowDoc(true);
                        }}
                      >
                        View file
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-start align-items-center gap-3">
                    <div>
                      <input
                        type="file"
                        id="pan_file"
                        class="inputfile"
                        onChange={(event) => {
                          setPanFile(event.target.files[0]);
                        }}
                        accept="image/*"
                      />
                      <label
                        for="pan_file"
                        class="add_employee_btn d-flex flex-row"
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          {panFile?.file
                            ? panFile.file?.split("/").at(-1)
                            : `${panFile?.name ?? "Upload File"}`}
                        </span>
                      </label>
                    </div>
                  </div>
                )}
              </Form.Group>
            </Row>
            <hr />

            {isEditing !== null && (
              <div className="d-flex justify-content-start align-items-center gap-2 pt-2">
                <input
                  type="button"
                  value="Cancel"
                  className="outline_btn"
                  onClick={handleClose}
                ></input>
                <input
                  type="submit"
                  value="Submit"
                  className="signup_button"
                ></input>
              </div>
            )}
          </Form>
          <DocumentViewModal
            show={showDoc}
            onHide={() => {
              setShowDoc(false);
              setFileLinks(null);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
            handleCloseView={() => {
              setShowDoc(false);
              setFileLinks(null);
            }}
            links={fileLinks}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Vendors List</h4>
          </div>
          {showAddVendorList && (
            <div>
              <Custombuttons
                label={"Add New Vendor"}
                buttonIcon={Add}
                className="add_employee_btn"
                onClick={() => {
                  setIsEditing(false);
                  handleShow();
                }}
              />
            </div>
          )}
        </div>
        <hr />
        <div className="banner-section-dashboard mb-3">
          <div class="d-flex justify-content-between align-items-center mb-3 gap-2">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">GST Number</th>
                    <th scope="col" style={{ width: "150px" }}>
                      Address
                    </th>
                    <th scope="col" style={{width:'150px'}}>Description</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results.map((row) => {
                    return (
                      <tr>
                        <td>{row.name}</td>
                        <td>{row.phone}</td>
                        <td>{row.gst_number}</td>
                        <td>
                          <LightTooltip
                            title={row.address}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {row.address}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>  <LightTooltip title={row.description} arrow placement="bottom">
                        <div className="table_text_overflow">{row.description}</div>
                        </LightTooltip>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <LightTooltip title="View" arrow placement="bottom">
                              <ViewIcon
                                style={{ color: "#008080" }}
                                className="edit_icon"
                                onClick={() => {
                                  setSelectedVendor(row);
                                  setIsEditing(null);
                                  handleShow();
                                }}
                              />
                            </LightTooltip>
                            <LightTooltip title="Edit" arrow placement="bottom">
                              <EditIcon
                                style={{ color: "#008080" }}
                                className="edit_icon"
                                onClick={() => {
                                  setSelectedVendor(row);
                                  setIsEditing(true);
                                  handleShow();
                                }}
                              />
                            </LightTooltip>
                            <LightTooltip
                              title="Delete"
                              arrow
                              placement="bottom"
                            >
                              <DeleteIcon
                                onClick={() => {
                                  setSelectedVendor(row);
                                  handleDeleteShow();
                                }}
                                style={{ color: "#008080", fontSize: "20px" }}
                                className="edit_icon"
                              />
                            </LightTooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <VendorModal />

          <Modal
            show={deleteshow}
            onHide={handleDeleteClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="deletebody">
              <div>
                <div className="">
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <div className="media d-flex">
                        <img
                          className="align-self-start mr-3"
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          src={cancel}
                          alt="no_img"
                        />
                        <div
                          className="media-body ms-3"
                          style={{
                            position: "relative",
                          }}
                        >
                          <h5 className="mt-0 alerts-heading text-start">
                            Are you sure?
                          </h5>
                          <p className="text-start">
                            Do you really want to delete these records ? This
                            process cannot be undone
                          </p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-end">
                      <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                        <input
                          type="button"
                          value="Cancel"
                          className="outline_btn"
                          onClick={handleDeleteClose}
                        ></input>
                        <input
                          type="button"
                          value="Delete"
                          className="delete_button"
                          ref={deleteButtonRef}
                          onClick={() => {
                            postSubcontractor({
                              id: selectedVendor?.id,
                              name: selectedVendor?.name,
                              phone: selectedVendor?.phone,
                              address: selectedVendor?.address,
                              description: selectedVendor?.description,
                              category: selectedVendor?.category?.id,
                              docs: getIds(selectedVendor?.docs),
                              active: false,
                            })
                              .then((res) => {
                                toast.success("Vendor is deleted");
                                handleDeleteClose();
                                apiCall();
                              })
                              .catch((err) => {
                                toast.error(err.message);
                              });
                          }}
                        ></input>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Vendorlist;
