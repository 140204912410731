import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Outlinebutton from "../Customcomponents/Outlinebutton";
import { getProject_signoff } from "../api/service";
import Custompagenation from "../Customcomponents/Custompagenation";
import { conDateToReadable } from "../components/utils";
import { toast } from "react-toastify";
const Salessignoffdoc = () => {
  const location = useLocation();
  const receivedOn = location?.state?.received_on;
  const receivedAmount = location?.state?.received_amount;
  const [tableData, setTableData] = useState(null);
  const [projectId, setProjectId] = useState(null);

  const [customerFname, setCustomerFname] = useState("");
  const [customerLname, setCustomerLname] = useState("");
  const [isProjectClosed, setIsProjectClosed] = useState(false);

  const currentUserRole = localStorage.getItem("role");
  const isCustomer = currentUserRole === "customer";
  const redirectTo = isCustomer ? "/customerdashboard" : "/salesprojects";

  //list of links that are not clickable by customer
  const disabledLinks = ["site measurements", "sqft details from design"];

  function disableLink(category) {
    let lCategory = category?.toLowerCase();
    if (lCategory === "3d") {
      if (!isCustomer || (isCustomer && receivedAmount && receivedOn)) {
        return false;
      }
      return true;
    }
    return disabledLinks.includes(lCategory) && isCustomer;
  }

  useEffect(() => {
    if (location.state?.projectId) {
      setProjectId(location.state.projectId);
      setCustomerFname(location.state?.customerFname);
      setCustomerLname(location.state?.customerLname);
      setIsProjectClosed(location.state?.isProjectClosed);

      getProject_signoff({ project: location.state.projectId })
        .then((res) => {
          setTableData(res);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, []);
  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <ul>
              <li>
                <h6 className="breadcrumb_main_heading">
                  <Link to={redirectTo}>Projects</Link>
                </h6>
              </li>
              <li>
                <span className="breadcrumb_paragraph"> Sign-off</span>
              </li>
            </ul>
          </div>
          <div>
            <div className="d-flex justify-content-end list-group-item">
              <div className="d-flex justify-content-end pt-1 pb-1 gap-2 ">
                <div>
                  <Link to={redirectTo}>
                    <Outlinebutton name="Back" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="banner-section-dashboard mt-1 mb-3">
          <div>
            <label classname="breadcrumb_description">
              Project Id:{" "}
              <span style={{ fontWeight: "400" }}>{`Proj ${projectId}`}</span>
            </label>
          </div>
          {!isCustomer && (
            <div>
              <label
                classname="breadcrumb_description"
                style={{ display: "block", wordWrap: "break-word" }}
              >
                Customer Name:{" "}
                <span
                  style={{ fontWeight: "400" }}
                  className="breadcrumb_description_names"
                >{`${customerFname} ${customerLname}`}</span>
              </label>
            </div>
          )}

          <hr />
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">
                      <div>Name</div>
                    </th>
                    <th scope="col">
                      <div>Status</div>
                    </th>
                    <th scope="col">
                      <div>Activity Date</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results.map((row) => {
                    return (
                      <tr>
                        <td className="active-links">
                          {disableLink(row.subcategory) ? (
                            row.subcategory
                          ) : (
                            <Link
                              to="/salessignoffdocview"
                              class="footer_link"
                              state={{
                                signOffId: row.id,
                                projectId: projectId,
                                customerFname: customerFname,
                                customerLname: customerLname,
                                isProjectClosed: isProjectClosed,
                                categoryName: row.subcategory,
                                categoryId: row.category_id,
                                subcategoryId: row.subcategory_id,
                                roles: row.role,
                              }}
                            >
                              {row.subcategory}
                            </Link>
                          )}
                        </td>
                        <td>{row.status}</td>
                        <td>{conDateToReadable(row.activity_date)}</td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={3} className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Custompagenation data={tableData} setData={setTableData} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Salessignoffdoc;
