// Sidebar.js
import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import Scorlltotopbutton from "../components/Scrolltotopbutton";

import Customfooter from "../Customcomponents/Customfooter";
import Customsidebar from "../Customcomponents/Customsidebar";
import CustomHeader from "../Customcomponents/CustomHeader";
import CustomerHeader from "../Customcomponents/CustomerHeader";

const Sidebar = () => {
  const currentUserRole = localStorage.getItem("role");
  const [isToggled, setToggled] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggle = () => {
    setToggled(!isToggled);
  };

  return currentUserRole === "customer" ? (
    <div>
      <CustomerHeader />
      <Outlet />
      <Scorlltotopbutton />
      <Customfooter />
    </div>
  ) : (
    <div id="wrapper" className={isToggled ? "toggled" : ""}>
      <Customsidebar />
      <div id="page-content-wrapper mb-5">
        <div>
          <CustomHeader
            isToggled={isToggled}
            scrolling={scrolling}
            handleToggle={handleToggle}
          />
          <Outlet />
          <Scorlltotopbutton />
          <Customfooter />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
