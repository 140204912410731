import React, { useState, useRef } from "react";
import delete_icon from "../assets/img/delete.svg";
import edit_icon from "../assets/img/edit.svg";
import Custompagenation from "../Customcomponents/Custompagenation";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import close_icon from "../assets/img/close.svg";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
// import Button from "react-bootstrap/Button";
import search_icon from "../assets/img/search.svg";
// import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ReactComponent as CopyIcon } from "../assets/img/copy.svg";
import { ReactComponent as PrintIcon } from "../assets/img/print.svg";
import { ReactComponent as ExportIcon } from "../assets/img/print.svg";
import Custombuttons from "../Customcomponents/Custombuttons";
import Customsearch from "../Customcomponents/Customsearch";

const Purchaseintialprocurement = () => {
  const [show, setShow] = useState(false);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // Do something with the selected file, e.g., upload it
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showview, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center mt-3">
          <div>
            <h4 class="headingfour">
              Projects
              <span style={{ fontSize: "18px" }}>
                / Initial Procurement List
              </span>
            </h4>
          </div>

          <div class="d-grid gap-2 d-md-flex">
            <Link to="/purchaseheadprojects">
              <button
                className="outline_btn d-flex flex-row"
                style={{ width: "120px" }}
              >
                <span>Back</span>
              </button>
            </Link>
          </div>
        </div>
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <Customsearch />
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Activity Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="active-links">
                      <span class="footer_link" href="/purhaselistitem">
                        Hardware Procurement List
                      </span>
                    </td>
                    <td>Pending</td>
                    <td>26/12/2023</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Custompagenation />
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <h1 class="headingfour">Add Procurement List</h1>
              <img
                src={close_icon}
                alt="no_img"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group
                    as={Col}
                    controlId="formGridEmail"
                    className="col-md-12 mb-2"
                  >
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="inputfield"
                    >
                      <option>Select Status</option>
                      <option>one</option>
                      <option>two</option>
                      <option>three</option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group as={Col} className="col-md-3">
                    <Form.Label>Item Name</Form.Label>
                    <Form.Control type="text" className="inputfield" />
                  </Form.Group>
                  <Form.Group as={Col} className="col-md-3">
                    <Form.Label>Unit</Form.Label>
                    <Form.Control type="text" className="inputfield" />
                  </Form.Group>
                  <Form.Group as={Col} className="col-md-3">
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control type="text" className="inputfield" />
                  </Form.Group>
                  <Form.Group as={Col} className="col-md-3">
                    <Form.Label>Action</Form.Label>
                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <button
                        type="button"
                        class="outline_btn"
                        style={{ height: "20px", width: "20px" }}
                      >
                        <AddIcon style={{ fontSize: "20px" }} />
                      </button>

                      <button
                        type="button"
                        class="cross_btn"
                        style={{ height: "20px", width: "20px" }}
                      >
                        <CloseIcon style={{ fontSize: "20px" }} />
                      </button>
                    </div>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group
                    as={Col}
                    controlId="formGridPassword"
                    className="col-md-12"
                  >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter last name"
                      className="inputfield"
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group as={Col} className="col-md-12">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      className="inputfield"
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group as={Col} className="col-md-12 pb-5">
                    <Form.Label>Document</Form.Label>
                    <div className="d-flex justify-content-start align-items-center gap-3">
                      <input type="file" id="file-upload" class="inputfile" />
                      <label
                        for="file-upload"
                        class="add_employee_btn d-flex flex-row"
                        style={{ cursor: "pointer" }}
                      >
                        <span>Upload file</span>
                      </label>

                      <button
                        type="button"
                        class="outline_btn"
                        style={{ height: "20px", width: "20px" }}
                      >
                        <AddIcon style={{ fontSize: "20px" }} />
                      </button>
                    </div>
                  </Form.Group>
                </Row>
                <hr />

                <div className="d-flex justify-content-start align-items-start gap-2">
                  <button class="add_employee_btn" style={{ width: "120px" }}>
                    Submit
                  </button>
                  <button class="outline_btn" style={{ width: "120px" }}>
                    Reset
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Purchaseintialprocurement;
