import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Custompagenation from "../Customcomponents/Custompagenation";
import close_icon from "../assets/img/close.svg";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ReactComponent as ExportIcon } from "../assets/img/export.svg";
import { ReactComponent as FilterIcon } from "../assets/img/filter.svg";
import Custombuttons from "../Customcomponents/Custombuttons";
import Customdate from "../Customcomponents/Customdate";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  baseSupplier_estimates,
  getSubcontractor_estimate,
  getProject,
  getLabour_payment_report,
} from "../api/service";
import { toast } from "react-toastify";
import { conDateToReadable } from "../components/utils";
import ExportAsModal from "../Customcomponents/ExportAsModal";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Labourpaymentreport = () => {
  const [report, setReport] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [updatedFilters, setUpdatedFilters] = useState({
    supplier__category__supplier_type: "",
    project: "",
    payment_date: "",
    created_at: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [showExport, setShowExport] = useState(false);
  function handleCloseExport() {
    setShowExport(false);
  }

  const handleClose = () => setShowFilter(false);
  const handleShow = () => setShowFilter(true);

  function apiCall() {
    getSubcontractor_estimate({ active: "True", ...updatedFilters })
      .then((res) => {
        getLabour_payment_report().then((rep) => {
          setReport(rep);
        });
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  function getActiveProjects() {
    getProject({ pagination: false })
      .then((res) => {
        setProjects(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  useEffect(() => {
    getActiveProjects();
  }, []);
  useEffect(() => {
    apiCall();
  }, [updatedFilters]);

  function FilterModal() {
    const labourTypes = ["subcontractor", "vendor"];
    const [filters, setFilters] = useState(updatedFilters);
    function handleFilterChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setFilters({ ...filters, [name]: value });
    }
    return (
      <Modal
        show={showFilter}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Filter</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-4">
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Labour Type</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="supplier__category__supplier_type"
                  value={filters.supplier__category__supplier_type}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Type</option>
                  {labourTypes.map((labourType) => {
                    return <option>{labourType}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Project</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="project"
                  value={filters.project}
                  onChange={handleFilterChange}
                >
                  <option hidden>Select Project</option>
                  {projects.map((project) => {
                    return (
                      <option
                        value={project.id}
                      >{`Proj ${project.id}_${project.customer?.first_name}_${project.customer?.last_name}`}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Payment Date</Form.Label>
                <Customdate
                  name="payment_date"
                  value={filters.payment_date}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="col-md-12 mb-2">
                <Form.Label>Date</Form.Label>
                <Customdate
                  name="created_at"
                  value={filters.created_at}
                  onChange={handleFilterChange}
                />
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-center align-items-start gap-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleClose}
              ></input>
              <input
                type="button"
                value="Apply"
                className="signup_button"
                onClick={() => {
                  setUpdatedFilters(filters);
                  handleClose();
                }}
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div>
          <h4 className="headingfour">Labour Payment Report</h4>
          <hr />
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-4 mb-3 text-center">
            <div class="col">
              <div class="card h-100 cashback_card">
                <div class="card-body">
                  <h6 class="text-center card_text">
                    Total Quotation amount :{" "}
                    {report?.total_quotation_amount ?? 0}
                  </h6>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100 cashback_card">
                <div class="card-body">
                  <h6 class="text-center card_text">
                    Total Amount Paid : {report?.total_amount_paid ?? 0}
                  </h6>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100 cashback_card">
                <div class="card-body">
                  <h6 class="text-center card_text">
                    Total Amount Pending: {report?.total_amount_pending ?? 0}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="banner-section-dashboard mt-2 mb-3">
          <div class="d-flex justify-content-between align-items-center mb-3 gap-2">
            <div class="d-flex justify-content-start align-items-center mb-3 gap-2">
              <button
                className="add_employee_btn d-flex flex-row"
                onClick={handleShow}
              >
                <FilterIcon />
                <span>Add Filter</span>
              </button>
              {Object.keys(updatedFilters).map((key) => {
                if (key !== "active" && updatedFilters[key] !== "")
                  return (
                    <div class="chip ms-1 me-1">
                      <div class="chip-content">
                        {key === "project"
                          ? `Proj ${updatedFilters[key]}`
                          : updatedFilters[key]}
                      </div>
                      <div
                        class="chip-close"
                        onClick={() => {
                          setUpdatedFilters({ ...updatedFilters, [key]: "" });
                        }}
                      >
                        <svg
                          class="chip-svg"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                        </svg>
                      </div>
                    </div>
                  );
              })}
            </div>

            <div>
              <div class="d-flex gap-2 d-md-flex">
                <Custombuttons
                  label={"Export As"}
                  buttonIcon={ExportIcon}
                  onClick={() => {
                    setShowExport(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col" style={{ width: "150px" }}>
                      Project
                    </th>
                    <th scope="col">Labour Type</th>
                    <th scope="col">Labour Name</th>
                    <th scope="col" style={{ width: "150px" }}>
                      Description
                    </th>
                    <th scope="col">% of Work Done</th>
                    <th scope="col">Total Quoted Amount</th>
                    <th scope="col">Amount Paid</th>
                    <th scope="col">Amount to be paid</th>
                    <th scope="col">Payment Date</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results.map((row) => {
                    return (
                      <tr>
                        <td>{conDateToReadable(row.created_at)}</td>
                        <td>
                          <LightTooltip
                            title={`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {`Proj ${row.project.id}_${row.project.customer?.first_name}_${row.project.customer?.last_name}`}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>{row?.supplier?.category?.supplier_type}</td>
                        <td>{row?.supplier?.name}</td>
                        <td>
                          <LightTooltip
                            title={row.description}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {row.description}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>{row.percentage_of_work_done}</td>
                        <td>{row.estimated_amount}</td>
                        <td>{row.amount_paid}</td>
                        <td>{row.balance_amount}</td>
                        <td>{conDateToReadable(row.payment_date)}</td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={11} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <FilterModal />
          <ExportAsModal
            url={`${baseSupplier_estimates}?active=True&pagination=false`}
            showExport={showExport}
            fileName={"labour payment report"}
            handleCloseExport={handleCloseExport}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Labourpaymentreport;
