import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation } from "react-router-dom";
import close_icon from "../assets/img/close.svg";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ReactComponent as Upload } from "../assets/img/upload.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import Custompagenation from "../Customcomponents/Custompagenation";
import Customsearch from "../Customcomponents/Customsearch";
import DocumentViewModal from "../Customcomponents/DocumentViewModal";
import Outlinebutton from "../Customcomponents/Outlinebutton";
import Uploadbutton from "../Customcomponents/Uploadbutton";
import {
  getSignoff,
  postDrivem,
  postSignoff,
  postProject_signoff,
} from "../api/service";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import {
  conDateToReadable,
  getIds,
  signOffStatuses,
} from "../components/utils";
import CustomFileSelector from "../Customcomponents/CustomFileSelector";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Salessignoffdocview = () => {
  const currentUserId = localStorage.getItem("userid");
  const currentUserRole = localStorage.getItem("role");
  const isCustomer = currentUserRole === "customer";
  const redirectTo = isCustomer ? "/customerdashboard" : "/salesprojects";
  const location = useLocation();
  const signOffId = location.state?.signOffId;
  const projectId = location.state?.projectId;

  const customerFname = location.state?.customerFname;
  const customerLname = location.state?.customerLname;
  const isProjectClosed = location.state?.isProjectClosed;

  const categoryName = location.state?.categoryName;
  const categoryId = location.state?.categoryId;
  const subCategoryId = location.state?.subcategoryId;

  const rolesStr = location?.state?.roles;
  const rolesJSON = rolesStr?.replace(/'/g, '"') ?? null;
  const rolesArray = JSON.parse(rolesJSON);
  const isRoleInRolesArray = rolesArray?.includes(currentUserRole) ?? true;
  const hasUploadPerm =
    !isProjectClosed && currentUserRole !== "customer" && isRoleInRolesArray;

  const [tableData, setTableData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedSignoffDoc, setSelectedSignoffDoc] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSelectedSignoffDoc(null);
    setIsEditing(false);
  };
  const handleShow = () => setShow(true);

  const [showedit, setShowEdit] = useState(false);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [showview, setShowView] = useState(false);
  const handleCloseView = () => {
    setSelectedSignoffDoc(null);
    setShowView(false);
  };
  const handleShowView = () => setShowView(true);

  const [searchValue, setSearchValue] = useState("");

  //api call
  function apiCall() {
    getSignoff({
      project: projectId,
      docs__categories: categoryId,
      docs__subcategories: subCategoryId,
      search: searchValue,
    })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  useEffect(() => {
    apiCall();
  }, [searchValue]);

  //upload document modal
  function UploadDocumentModal() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [prevSelectedSignOffFiles, setPrevSelectedSignOffFiles] = useState([
      ...(selectedSignoffDoc?.docs ?? []),
    ]);
    const [signOffFiles, setSignOffFiles] = useState([]);
    const [payload, setPayload] = useState({
      id: selectedSignoffDoc?.id,
      title: selectedSignoffDoc?.title ?? "title",
      user: selectedSignoffDoc?.user?.id ?? currentUserId,
      project: selectedSignoffDoc?.project?.id ?? projectId,
      status: selectedSignoffDoc?.status ?? "pending",
      description: selectedSignoffDoc?.description ?? "",
      signoff_type: selectedSignoffDoc?.signoff_type ?? "",
    });
    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (payload.signoff_type === "") {
        toast.error("Plese select signoff type");
        return;
      } else if (payload.description === "") {
        toast.error("Please add description");
        return;
      } else if (
        prevSelectedSignOffFiles.length === 0 &&
        signOffFiles.length === 0
      ) {
        toast.error("Please select files");
        return;
      }
      if (!isSubmitted) {
        setIsSubmitted(true);
        let signOffFilesPrms = [];
        for (let index = 0; index < signOffFiles.length; index++) {
          let form = new FormData();
          form.append("file", signOffFiles[index]);
          form.append("categories", categoryId);
          form.append("subcategories", subCategoryId);
          signOffFilesPrms.push(postDrivem(form));
        }
        Promise.all(signOffFilesPrms)
          .then((signOffFilesRes) => {
            let docIds = signOffFilesRes.map((signOffFileRes) => {
              return signOffFileRes.id;
            });
            let prevDocIds = getIds(prevSelectedSignOffFiles);
            postSignoff({
              ...payload,
              docs: [...docIds, ...prevDocIds],
            })
              .then((res) => {
                postProject_signoff({
                  id: signOffId,
                  project: projectId,
                  status: "Uploaded",
                  activity_date: new Date(),
                  signoff_subcategory: subCategoryId,
                }).then(() => {
                  toast.success("Document is uploaded");
                  handleClose();
                  setIsSubmitted(false);
                  apiCall();
                });
              })
              .catch((err) => {
                setIsSubmitted(false);
                toast.error(err.message);
              });
          })
          .catch((err) => {
            setIsSubmitted(false);
            toast.error(err.message);
          });
      }
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">{isEditing ? "Edit" : "Upload"} Documents</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row className="mb-2">
              <Form.Group controlId="formGridEmail" className="col-md-12 mb-2">
                <Form.Label>Sign-off Type</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="signoff_type"
                  value={payload.signoff_type}
                  onChange={handleInputChange}
                  required
                >
                  <option hidden value="">
                    select sign-off type
                  </option>
                  <option value={"new document"}>New document</option>
                  <option value={"existed document"}>Revised document</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group className="col-md-12">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  name="description"
                  value={payload.description}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group className="col-md-12 pb-2">
                <Form.Label>Document</Form.Label>
                <CustomFileSelector
                  selectedFiles={signOffFiles}
                  setSelectedFiles={setSignOffFiles}
                  prevSelectedFiles={prevSelectedSignOffFiles}
                  setPrevSelectedFiles={setPrevSelectedSignOffFiles}
                />
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-start align-items-start gap-2 ">
              <input
                type="button"
                value="Cancel"
                class="outline_btn"
                onClick={handleClose}
              />
              <input
                type="submit"
                value="Submit"
                className="signup_button"
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  //customer action
  function CustomerActionModal() {
    const [prevSignOffFiles, setPrevSignOffFiles] = useState([
      ...(selectedSignoffDoc?.customer_resp_docs ?? []),
    ]);
    const [signOffFiles, setSignOffFiles] = useState([]);

    const [payload, setPayload] = useState({
      id: selectedSignoffDoc?.id,
      title: selectedSignoffDoc?.title ?? "test",
      signoff_type: selectedSignoffDoc?.signoff_type,
      user: selectedSignoffDoc?.user?.id,
      status: selectedSignoffDoc?.status ?? "pending",
      project: selectedSignoffDoc?.project?.id ?? projectId,
      customer_signoff_comment:
        selectedSignoffDoc?.customer_signoff_comment ?? "",
      customer_response_date:
        selectedSignoffDoc?.customer_response_date ??
        conDateToReadable(new Date(), false, "-", false),
      created_by: selectedSignoffDoc?.created_by ?? currentUserId,
      updated_by: currentUserId,
    });
    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (payload.signoff_type === "") {
        toast.error("Please select signoff type");
        return;
      } else if (payload.customer_signoff_comment === "") {
        toast.error("Please add description");
        return;
      } else if (prevSignOffFiles.length === 0 && signOffFiles.length === 0) {
        toast.error("Please select files");
        return;
      }
      let signOffFilesPrms = [];
      for (let index = 0; index < signOffFiles.length; index++) {
        let form = new FormData();
        form.append("file", signOffFiles[index]);
        form.append("categories", categoryId);
        form.append("subcategories", subCategoryId);
        signOffFilesPrms.push(postDrivem(form));
      }
      Promise.all(signOffFilesPrms).then((signOffFilesRes) => {
        let docIds = signOffFilesRes.map((signOffFileRes) => {
          return signOffFileRes.id;
        });
        let prevDocIds = getIds(prevSignOffFiles);
        postSignoff({
          ...payload,
          customer_resp_docs: [...docIds, ...prevDocIds],
        })
          .then((res) => {
            toast.success("Document is uploaded");
            handleClose();
            apiCall();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      });
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">Update Sign-off</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row className="mb-2">
              <Form.Group controlId="formGridEmail" className="col-md-12 mb-2">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  className="inputfield"
                  name="status"
                  value={payload.status}
                  onChange={handleInputChange}
                  required
                >
                  <option hidden value="">
                    Select status
                  </option>
                  {signOffStatuses.map((signOffStatus) => {
                    return (
                      <option value={signOffStatus}>{signOffStatus}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group className="col-md-12">
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  className="inputfield"
                  name="customer_signoff_comment"
                  value={payload.customer_signoff_comment}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group className="col-md-12 pb-5">
                <Form.Label>Documents</Form.Label>
                <CustomFileSelector
                  selectedFiles={signOffFiles}
                  setSelectedFiles={setSignOffFiles}
                  prevSelectedFiles={prevSignOffFiles}
                  setPrevSelectedFiles={setPrevSignOffFiles}
                />
              </Form.Group>
            </Row>
            <hr />

            <div className="d-flex justify-content-start align-items-start gap-2">
              <input
                type="button"
                value="Cancel"
                class="outline_btn"
                onClick={handleClose}
              />
              <input
                type="submit"
                value="Submit"
                className="signup_button"
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <ul>
              <li>
                <h6 className="breadcrumb_main_heading">
                  <Link to={redirectTo}>Projects</Link>
                </h6>
              </li>
              <li>
                <span className="breadcrumb_paragraph">
                  <Link
                    className="me-2"
                    to="/salessignoffdoc"
                    state={{
                      projectId: projectId,
                      customerFname: customerFname,
                      customerLname: customerLname,
                    }}
                  >
                    Sign-off
                  </Link>
                  / {categoryName}
                </span>
              </li>
            </ul>
          </div>
          <div>
            <div className="d-flex justify-content-end list-group-item">
              <div class="d-flex gap-2 d-md-flex">
                {hasUploadPerm && (
                  <div className="d-flex gap-2">
                    <button
                      className="add_employee_btn d-flex flex-row"
                      onClick={handleShow}
                    >
                      <Upload />
                      <span>Upload Document</span>
                    </button>
                  </div>
                )}
                <Link
                  to="/salessignoffdoc"
                  state={{
                    projectId: projectId,
                    customerFname: customerFname,
                    customerLname: customerLname,
                    isProjectClosed: isProjectClosed,
                  }}
                >
                  <Outlinebutton name="Back" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div>
            <label classname="breadcrumb_description">
              Project Id:{" "}
              <span style={{ fontWeight: "400" }}>{`Proj ${projectId}`}</span>
            </label>
          </div>
          {!isCustomer && (
            <div>
              <label
                classname="breadcrumb_description"
                style={{ display: "block", wordWrap: "break-word" }}
              >
                Customer Name:{" "}
                <span
                  style={{ fontWeight: "400" }}
                  className="breadcrumb_description_names"
                >{`${customerFname} ${customerLname}`}</span>
              </label>
            </div>
          )}
          <hr />
          <div class="d-flex justify-content-start align-items-center mb-3 gap-2">
            <div>
              <Customsearch
                setSearchValue={setSearchValue}
                searchValue={searchValue}
              />
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">
                      <div>Date</div>
                    </th>
                    <th scope="col">
                      <div>User</div>
                    </th>
                    <th scope="col">
                      <div>Role</div>
                    </th>

                    <th scope="col" style={{ width: "150px" }}>
                      <div> File Description</div>
                    </th>
                    <th scope="col">
                      <div>Signoff Type</div>
                    </th>
                    <th scope="col">
                      <div>Signoff Document</div>
                    </th>

                    <th scope="col">
                      <div>Status</div>
                    </th>
                    <th scope="col">
                      <div>Customer Signoff Comment</div>
                    </th>
                    <th scope="col">
                      <div>Customer Response Doc</div>
                    </th>
                    <th scope="col">
                      <div>Customer Response date</div>
                    </th>
                    <th scope="col">
                      <div></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results?.map((row) => {
                    return (
                      <tr>
                        <td>{conDateToReadable(row.created_at)}</td>
                        <td>{`${row.user.first_name} ${row.user.last_name}`}</td>
                        <td>{row?.user.role}</td>
                        <td>
                          <LightTooltip
                            title={row.description}
                            arrow
                            placement="bottom"
                          >
                            <div className="table_text_overflow">
                              {row.description}
                            </div>
                          </LightTooltip>
                        </td>
                        <td>{row.signoff_type}</td>
                        <td className="active-links">
                          <span
                            class="footer_link"
                            onClick={() => {
                              setSelectedSignoffDoc(row);
                              handleShowView();
                            }}
                          >
                            View
                          </span>
                        </td>
                        <td>{row.status}</td>
                        <td>{row.customer_signoff_comment}</td>
                        <td className="active-links">
                          <span
                            class="footer_link"
                            onClick={() => {
                              setSelectedSignoffDoc({
                                ...row,
                                docs: row.customer_resp_docs,
                              });
                              handleShowView();
                            }}
                          >
                            View
                          </span>
                        </td>
                        <td>{row.customer_response_date}</td>
                        {!(
                          row?.project?.status?.toLowerCase() ===
                            "project on hold" ||
                          row?.project?.status?.toLowerCase() ===
                            "project closed"
                        ) &&
                          row?.status?.toLowerCase() === "pending" && (
                            <td className="text-center">
                              <LightTooltip
                                title="Edit"
                                arrow
                                placement="bottom"
                              >
                                <EditIcon
                                  style={{ color: "#008080" }}
                                  className="edit_icon"
                                  onClick={() => {
                                    setSelectedSignoffDoc(row);
                                    setShow(true);
                                    setIsEditing(true);
                                  }}
                                />
                              </LightTooltip>
                            </td>
                          )}
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={11} className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Custompagenation data={tableData} setData={setTableData} />
          </div>
          <DocumentViewModal
            show={showview}
            onHide={handleCloseView}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-xl"
            handleCloseView={handleCloseView}
            links={selectedSignoffDoc?.docs}
          />
          {currentUserRole === "customer" ? (
            <CustomerActionModal />
          ) : (
            <UploadDocumentModal />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Salessignoffdocview;
