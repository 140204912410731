import { React, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  HashRouter as Router,
  Routes,
} from "react-router-dom";
import Dashboard from "../components/Dashboard";
import One from "../components/Home";
import Login from "../components/Login";
import Paymentdetials from "../customerportal/Paymentdetials";
import Customerlist from "../salesportal/Customerlist";
import Tasks from "../salesportal/Tasks";
import Teammembers from "../salesportal/Teammembers";
import Employee from "../users/Emplyoee";
import Customfooter from "../Customcomponents/Customfooter.jsx";
import CashBook from "../accounts/CashBook.jsx";
import Incomeandexpenses from "../accounts/Incomeandexpenses.jsx";
import Labourpaymentreport from "../accounts/Labourpaymentreport.jsx";
import Pettycash from "../accounts/Pettycash.jsx";
import Civilactualprocurement from "../civilheadportal/Civilactualprocurement";
import Civilintialprocurelist from "../civilheadportal/Civilintialprocurelist";
import Civilintialprourement from "../civilheadportal/Civilintialprourement";
import Civilqueries from "../civilheadportal/Civilqueries";
import PettyCashExpense from "../civilheadportal/PettyCashExpense.jsx";
import SubcontractorEstimate from "../civilheadportal/SubcontractorEstimate.jsx";
import Subcontractorslist from "../civilheadportal/Subcontractorslist.jsx";
import Subcontractortype from "../civilheadportal/Subcontractortype.jsx";
import Forgotpassword from "../components/Forgotpassword.jsx";
import Pagenotfound from "../components/Pagenotfound.jsx";
import Setpassword from "../components/Setpassword.jsx";
import TokenExpired from "../components/invalidtoken.jsx";
import Actualprocurement from "../customerportal/Actualprocurement";
import CustomerDashboard from "../customerportal/CustomerDashboard.jsx";
import NewRequest from "../customerportal/NewRequest.jsx";
import ProjectTracking from "../customerportal/ProjectTracking.jsx";
import Purchaseintialprocurement from "../purchaseheadportal/Purchaseintialprocurement.jsx";
import Purchaselistitem from "../purchaseheadportal/Purchaselistitem.jsx";
import VendorEstimates from "../purchaseheadportal/VendorEstimates.jsx";
import Vendorlist from "../purchaseheadportal/Vendorlist.jsx";
import Vendortypes from "../purchaseheadportal/Vendortypes.jsx";
import Salesaddprojects from "../salesportal/Salesaddprojects.jsx";
import Salespreexecutionchecklist from "../salesportal/Salespreexecutioncheclist.jsx";
import Salesprojects from "../salesportal/Salesprojects.jsx";
import Salessignoffdoc from "../salesportal/Salessignoffdoc.jsx";
import Salessignoffdocview from "../salesportal/Salessignoffdocview.jsx";
import useStorage from "./storageHook.jsx";

const Routers = () => {
  const currentUserRole = localStorage.getItem("role");
  const hasTokenValue = useStorage("token");
  const [hasToken, setHasToken] = useState(true);
  const routesList = [
    {
      path: "/",
      element: <Login />,
      children: [],
      requireToken: false,
    },
    {
      path: "/login",
      element: <Login handleChangeToken={handleChangeToken} />,
      children: [],
      requireToken: false,
    },
    {
      path: "/forgotpassword",
      element: <Forgotpassword />,
      children: [],
      requireToken: false,
    },
    {
      path: "/setpassword/:token",
      element: <Setpassword />,
      children: [],
      requireToken: false,
    },
    {
      path: "/invalidtoken",
      element: <TokenExpired />,
      children: [],
      requireToken: false,
    },
    {
      path: "/",
      element: <Dashboard />,
      children: [
        { path: "home", element: <One />, requireToken: true },
        { path: "customers", element: <Customerlist />, requireToken: true },
        {
          path: "salesprojects",
          element: <Salesprojects />,
          requireToken: true,
        },
        {
          path: "salesaddprojects",
          element: <Salesaddprojects />,
          requireToken: true,
        },
        {
          path: "salessignoffdoc",
          element: <Salessignoffdoc />,
          requireToken: true,
        },
        {
          path: "salessignoffdocview",
          element: <Salessignoffdocview />,
          requireToken: true,
        },
        {
          path: "salespreexecutionchecklist",
          element: <Salespreexecutionchecklist />,
          requireToken: true,
        },
        { path: "teammembers", element: <Teammembers />, requireToken: true },
        { path: "tasks", element: <Tasks />, requireToken: true },
        { path: "employees", element: <Employee />, requireToken: true },
        {
          path: "actualprocurementsales",
          element: <Actualprocurement />,
          requireToken: true,
        },
        {
          path: "civilintialprourement",
          element: <Civilintialprourement />,
          requireToken: true,
        },
        {
          path: "civilintialprocurelist",
          element: <Civilintialprocurelist />,
          requireToken: true,
        },
        {
          path: "civilactualprocurement",
          element: <Civilactualprocurement />,
          requireToken: true,
        },
        { path: "civilqueries", element: <Civilqueries />, requireToken: true },
        {
          path: "pettycashexpense",
          element: <PettyCashExpense />,
          requireToken: true,
        },
        {
          path: "subcontractorslist",
          element: <Subcontractorslist />,
          requireToken: true,
        },
        {
          path: "subcontractortype",
          element: <Subcontractortype />,
          requireToken: true,
        },
        {
          path: "subcontractorestimate",
          element: <SubcontractorEstimate />,
          requireToken: true,
        },
        {
          path: "purchaseintialprocurement",
          element: <Purchaseintialprocurement />,
          requireToken: true,
        },
        {
          path: "purhaselistitem",
          element: <Purchaselistitem />,
          requireToken: true,
        },
        { path: "vendorlist", element: <Vendorlist />, requireToken: true },
        { path: "vendortypes", element: <Vendortypes />, requireToken: true },
        {
          path: "vendorestimates",
          element: <VendorEstimates />,
          requireToken: true,
        },
        {
          path: "incomeandexpenses",
          element: <Incomeandexpenses />,
          requireToken: true,
        },
        { path: "pettycash", element: <Pettycash />, requireToken: true },
        { path: "customfooter", element: <Customfooter />, requireToken: true },
        { path: "cashbook", element: <CashBook />, requireToken: true },
        {
          path: "labourpaymentreport",
          element: <Labourpaymentreport />,
          requireToken: true,
        },

        //customer routers
        {
          path: "customerdashboard",
          element: <CustomerDashboard />,
          requireToken: true,
        },
        {
          path: "projecttracking",
          element: <ProjectTracking />,
          requireToken: true,
        },
        {
          path: "paymentdetails",
          element: <Paymentdetials />,
          requireToken: true,
        },
        {
          path: "actualprocurement",
          element: <Actualprocurement />,
          requireToken: true,
        },
        {
          path: "newrequest",
          element: <NewRequest />,
          requireToken: true,
        },
      ],
    },
    {
      path: "*",
      element: <Pagenotfound />,
      children: [],
      requireToken: false,
    },
  ];

  useEffect(() => {
    if (hasTokenValue && hasTokenValue !== "") {
      setHasToken(true);
    } else {
      setHasToken(false);
    }
  }, [hasTokenValue]);

  function handleChangeToken() {
    const token = localStorage.getItem("token");
    if (token !== null && token !== "") {
      setHasToken(true);
    } else {
      setHasToken(false);
    }
  }

  function handleRoute(route) {
    if (route.path === "/") {
      if (hasToken) {
        if (currentUserRole === "customer") {
          return <Navigate replace to={"/customerdashboard"}></Navigate>;
        }
        return <Navigate replace to={"/home"}></Navigate>;
      } else {
        return <Navigate replace to={"/login"}></Navigate>;
      }
    } else if (route.requireToken === false) {
      return route.element;
    } else if (route.requireToken === true) {
      if (hasToken) {
        return route.element;
      } else {
        return <Navigate replace to={"/login"}></Navigate>;
      }
    }
  }

  function getRoute(routesList) {
    return routesList.map((route, index) => {
      if (route["children"] && route["children"].length !== 0) {
        return (
          <Route path={route.path} element={route.element} key={route.path}>
            {getRoute(route["children"])}
          </Route>
        );
      }
      return (
        <Route
          path={route.path}
          element={handleRoute(route)}
          key={route.path}
        ></Route>
      );
    });
  }

  return (
    <Router>
      <Routes>{getRoute(routesList)}</Routes>
    </Router>
  );
};

export default Routers;
