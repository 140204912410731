import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Custombuttons from "../Customcomponents/Custombuttons";
import Custompagenation from "../Customcomponents/Custompagenation";
import Customsearch from "../Customcomponents/Customsearch";
import ExportAsModal from "../Customcomponents/ExportAsModal";
import Multiselect from "../Customcomponents/Multiselect";

import {
  baseProject,
  getProfile,
  getProject,
  postProject,
} from "../api/service";
import { ReactComponent as ActualIcon } from "../assets/img/actual.svg";
import { ReactComponent as Add } from "../assets/img/add.svg";
import { ReactComponent as AddassigneeIcon } from "../assets/img/addassignee.svg";
import close_icon from "../assets/img/close.svg";
import cancel, { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import { ReactComponent as ExportIcon } from "../assets/img/export.svg";
import { ReactComponent as InitialProcrutmentIcon } from "../assets/img/initialprocrutment.svg";
import location_icon from "../assets/img/location.svg";
import { ReactComponent as MenuIcon } from "../assets/img/menu.svg";
import { ReactComponent as PreexecutionIcon } from "../assets/img/preexecution.svg";
import { ReactComponent as SignoffIcon } from "../assets/img/signoff.svg";
import { ReactComponent as ViewIcon } from "../assets/img/view.svg";
import { getIds, headAndTeamMembers } from "../components/utils";
import { ReactComponent as OnHoldIcon } from "../assets/img/onhold.svg";
import { ReactComponent as CloseProjectIcon } from "../assets/img/closeproject.svg";
import { ReactComponent as ReOpenIcon } from "../assets/img/reopen.svg";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    lineHeight: "1.3",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Salesprojects = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  /*
  isDeleteProject = true -> project delete button is clicked
  isDeleteProject = false -> project close button is clicked
  */
  const [isDeleteProject, setIsDeleteProject] = useState(true);
  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const [searchValue, setSearchValue] = useState("");

  const [show, setShow] = useState(false);

  //add assignee vars
  const [assignees, setAssignees] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showexport, setShowExport] = useState(false);

  const handleCloseExport = () => setShowExport(false);
  const handleShowExport = () => setShowExport(true);

  const [tableData, setTableData] = useState(null);
  const [dropdownVisibleIndex, setDropdownVisibleIndex] = useState(-1);
  const dropdownRefs = useRef([]);

  const currentUserRole = localStorage.getItem("role");
  const currentUserId = localStorage.getItem("userid");
  const teamRole = headAndTeamMembers[currentUserRole];
  const deleteButtonRef = useRef(null); // Reference to the delete button

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      deleteButtonRef.current.click(); // Trigger click event on the delete button
    }
  };

  useEffect(() => {
    if (deleteshow) {
      document.addEventListener("keydown", handleKeyPress);
      // Set focus on the delete button when the modal is shown
      deleteButtonRef.current.focus();
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [deleteshow]); // Run effect when deleteshow changes

  let userActions = {
    admin: {
      addAssignee: false,
      edit: false,
      delete: false,
      view: false,
      signoff: false,
      preExecutionCheckList: false,
      procurementRequest: false,
      subContractorQuotations: false,
      hold: true,
      close: true,
    },
    sales_head: {
      addAssignee: true,
      edit: true,
      delete: true,
      view: true,
      signoff: true,
      preExecutionCheckList: true,
      procurementRequest: false,
      subContractorQuotations: false,
      hold: false,
      close: false,
    },
    sales_team: {
      addAssignee: false,
      edit: true,
      delete: false,
      view: true,
      signoff: true,
      preExecutionCheckList: true,
      procurementRequest: false,
      subContractorQuotations: false,
      hold: false,
      close: false,
    },
    civil_head: {
      addAssignee: true,
      edit: true,
      delete: false,
      view: true,
      signoff: true,
      preExecutionCheckList: true,
      procurementRequest: true,
      subContractorQuotations: true,
      hold: false,
      close: false,
    },
    civil_team: {
      addAssignee: false,
      edit: true,
      delete: false,
      view: true,
      signoff: true,
      preExecutionCheckList: true,
      procurementRequest: true,
      subContractorQuotations: true,
      hold: false,
      close: false,
    },
    purchase_head: {
      addAssignee: true,
      procurementRequest: true,
      hold: false,
      close: false,
    },
    purchase_team: {
      addAssignee: true,
      procurementRequest: true,
      hold: false,
      close: false,
    },
    design_head: {
      addAssignee: true,
      edit: true,
      view: true,
      signoff: true,
      preExecutionCheckList: true,
      procurementRequest: false,
      subContractorQuotations: false,
      hold: false,
      close: false,
    },
    design_team: {
      addAssignee: false,
      edit: true,
      view: true,
      signoff: true,
      preExecutionCheckList: true,
      procurementRequest: false,
      subContractorQuotations: false,
      hold: false,
      close: false,
    },
    accounts: {
      addAssignee: false,
      edit: true,
      delete: false,
      view: true,
      signoff: false,
      preExecutionCheckList: false,
      procurementRequest: false,
      subContractorQuotations: false,
      hold: false,
      close: false,
    },
  };

  const handleToggleDropdown = (index) => {
    setDropdownVisibleIndex(index === dropdownVisibleIndex ? -1 : index);
  };

  const handleLinkClick = () => {
    setDropdownVisibleIndex(-1);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          if (index === dropdownVisibleIndex) {
            setDropdownVisibleIndex(-1);
          }
        }
      });
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisibleIndex]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchClick = () => {
    // Handle the search click action, e.g., navigate to search results
  };

  function apiCall() {
    let payload = { active: "True", search: searchValue };
    if (!Object.keys(headAndTeamMembers).includes(currentUserRole)) {
      payload["users_assigned"] = currentUserId;
    }
    getProject(payload)
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  function getUsers() {
    getProfile({
      pagination: false,
      role: `${currentUserRole},${teamRole}`,
      active: "True",
    }).then((res) => {
      setAssignees(res);
    });
  }
  useEffect(() => {
    getUsers();
    apiCall();
  }, []);
  useEffect(() => {
    apiCall();
  }, [searchValue]);

  function getAssignees(users) {
    let usersstr = "";
    return users.map((user, index) => (
      <>
        {user.first_name} {user.last_name} {index !== users.length - 1 && ","}
      </>
    ));
  }

  // modal assigning users to project
  function AddAssigneeModal() {
    const [showDropDown, setShowDropDown] = useState(false);
    const [selectedAssignees, setSelectedAssignees] = useState(
      selectedProject?.users_assigned
    );
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="static"
        // keyboard={false}
      >
        <Modal.Header>
          <h1 class="headingfour">Add Assignee</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Multiselect
                assignees={assignees}
                setAssignees={setAssignees}
                selectedAssignees={selectedAssignees}
                setselectedAssignees={setSelectedAssignees}
                dropdownVisible={showDropDown}
                setDropdownVisible={setShowDropDown}
              />
            </Row>

            <hr />

            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleClose}
              ></input>
              <input
                type="button"
                value="Submit"
                className="signup_button"
                onClick={() => {
                  let selectedAssigneeIds = getIds(selectedAssignees);
                  postProject({
                    id: selectedProject.id,
                    title: selectedProject.title,
                    customer: selectedProject.customer.id,
                    users_assigned: selectedAssigneeIds,
                  })
                    .then((res) => {
                      toast.success("Project assignees are updated");
                      setSelectedProject(null);
                      handleClose();
                      apiCall();
                    })
                    .catch((err) => toast.error(err.message));
                }}
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  //delete modal
  function DeleteModal() {
    function handleDeleteProject() {
      let payload = {
        id: selectedProject.id,
        customer: selectedProject?.customer?.id,
      };
      if (isDeleteProject) {
        payload.active = false;
      } else {
        payload.status = "project closed";
      }
      postProject(payload)
        .then((res) => {
          toast.success(
            isDeleteProject ? "Project is deleted" : "Project is closed"
          );
          handleDeleteClose();
          apiCall();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    return (
      <Modal
        show={deleteshow}
        onHide={handleDeleteClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="deletebody">
          <div>
            <div className="">
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <div className="media d-flex">
                    <img
                      className="align-self-start mr-3"
                      style={{
                        height: "40px",
                        width: "40px",
                      }}
                      src={cancel}
                      alt="no_img"
                    />
                    <div
                      className="media-body ms-3"
                      style={{
                        position: "relative",
                      }}
                    >
                      <h5 className="mt-0 alerts-heading text-start">
                        Are you sure?
                      </h5>
                      <p className="text-start">
                        Do you really want to{" "}
                        {isDeleteProject
                          ? "delete these record"
                          : "close the project"}
                        ? This process cannot be undone
                      </p>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-end">
                  <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                    <input
                      type="button"
                      value="Cancel"
                      className="outline_btn"
                      onClick={handleDeleteClose}
                    ></input>
                    <input
                      type="button"
                      value={isDeleteProject ? "Delete" : "Close Project"}
                      className="delete_button"
                      ref={deleteButtonRef}
                      onClick={handleDeleteProject}
                    ></input>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function handelHoldProject(project) {
    let isProjectOnHold = project?.status?.toLowerCase() === "project on hold";
    let payload = {
      id: project.id,
      customer: project?.customer?.id,
      status: isProjectOnHold ? "token not received" : "project on hold",
    };
    postProject(payload)
      .then((res) => {
        toast.success(
          isProjectOnHold ? "Project is reopened" : "Project is on hold"
        );
        handleDeleteClose();
        apiCall();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Projects</h4>
          </div>
          <div>
            {(currentUserRole === "sales_head" ||
              currentUserRole === "sales_team") && (
              <Link to="/salesaddprojects" state={{ isEditing: false }}>
                <Custombuttons
                  label={"Add New Project"}
                  buttonIcon={Add}
                  className="add_employee_btn"
                />
              </Link>
            )}
          </div>
        </div>
        <hr />
        <div className="banner-section-dashboard mt-2 mb-3">
          <div className="d-flex justify-content-between align-items-center mb-3  gap-2">
            <div>
              <div>
                <Customsearch
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              </div>
            </div>
            <div className="d-flex gap-2 d-md-flex function_buttons">
              <Custombuttons
                label={"Export As"}
                buttonIcon={ExportIcon}
                onClick={handleShowExport}
              />
            </div>
          </div>
          <div className="table_section table-responsive">
            <Table bordered>
              <thead className="table_head_bg">
                <tr>
                  <th scope="col" style={{ width: "150px" }}>
                    Project Id
                  </th>
                  <th scope="col" style={{ width: "150px" }}>
                    Customer Name
                  </th>
                  <th scope="col" style={{ width: "150px" }}>
                    Assigned to
                  </th>
                  <th scope="col" style={{ width: "150px" }}>
                    Property Address
                  </th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Email</th>
                  <th scope="col">Status</th>
                  <th scope="col">City</th>
                  <th scope="col">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>Site Location</div>
                    </div>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {tableData?.results.map((row, index) => {
                  return (
                    <tr>
                      <td>
                        <LightTooltip
                          title={`Proj ${row.id}_${row.customer?.first_name}_${row.customer?.last_name}`}
                          arrow
                          placement="bottom"
                        >
                          <div className="table_text_overflow">
                            {`Proj ${row.id}_${row.customer?.first_name}_${row.customer?.last_name}`}
                          </div>
                        </LightTooltip>
                      </td>
                      <td>
                        <LightTooltip
                          title={`${row.customer?.first_name} ${row.customer?.last_name}`}
                          arrow
                          placement="bottom"
                        >
                          <div className="table_text_overflow">
                            {`${row.customer?.first_name} ${row.customer?.last_name}`}
                          </div>
                        </LightTooltip>
                      </td>
                      <td>
                        <LightTooltip
                          title={getAssignees(row.users_assigned)}
                          arrow
                          placement="bottom"
                        >
                          <div className="table_text_overflow">
                            {getAssignees(row.users_assigned)}
                          </div>
                        </LightTooltip>
                      </td>
                      <td>
                        <LightTooltip
                          title={row.address}
                          arrow
                          placement="bottom"
                        >
                          <div className="table_text_overflow">
                            {" "}
                            {row.address}
                          </div>
                        </LightTooltip>
                      </td>
                      <td>{row.customer.mobile_no ?? "--"}</td>
                      <td>{row.customer.email ?? "--"}</td>
                      <td>{row.status}</td>
                      <td>{row.city}</td>
                      <td className="text-center">
                        <LightTooltip
                          title="Site Location"
                          arrow
                          placement="bottom"
                        >
                          <img
                            src={location_icon}
                            alt="no_img"
                            className="edit_icon"
                            onClick={() => {
                              if (row.location !== "") {
                                window.open(row.location);
                              }
                            }}
                          />
                        </LightTooltip>
                      </td>
                      <td className="text-center">
                        {(currentUserRole !== "admin" ||
                          row?.status?.toLowerCase() !== "project closed") && (
                          <div
                            ref={(ref) => (dropdownRefs.current[index] = ref)}
                          >
                            <span
                              className="toggle_icon"
                              onClick={() => handleToggleDropdown(index)}
                              style={{ position: "relative" }}
                            >
                              <LightTooltip
                                title="Options"
                                arrow
                                placement="bottom"
                              >
                                <MenuIcon alt="no_img" className="edit_icon" />
                              </LightTooltip>
                            </span>
                            <ul
                              className={`dropdown-menu custom_header  ${
                                dropdownVisibleIndex === index ? "show" : ""
                              }`}
                            >
                              {row?.status?.toLowerCase() !==
                                "project closed" &&
                                row?.status?.toLowerCase() !==
                                  "project on hold" &&
                                (userActions[currentUserRole]
                                  ? userActions[currentUserRole]?.addAssignee
                                  : true) && (
                                  <li
                                    style={{
                                      borderBottom: "1px solid #CBCBCB",
                                    }}
                                    className="pt-2 pb-2"
                                    onClick={handleLinkClick}
                                  >
                                    <Link
                                      onClick={() => {
                                        setSelectedProject(row);
                                        handleShow();
                                      }}
                                    >
                                      <button
                                        class="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                                        type="button"
                                      >
                                        <AddassigneeIcon
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                          }}
                                        />
                                        <span className="ms-2 ">
                                          Add Assignee
                                        </span>
                                      </button>
                                    </Link>
                                  </li>
                                )}
                              {row?.status?.toLowerCase() !==
                                "project closed" &&
                                row?.status?.toLowerCase() !==
                                  "project on hold" &&
                                (userActions[currentUserRole]
                                  ? userActions[currentUserRole]?.edit
                                  : true) && (
                                  <li
                                    style={{
                                      borderBottom: "1px solid #CBCBCB",
                                    }}
                                    className="pt-2 pb-2"
                                  >
                                    <Link
                                      to="/salesaddprojects"
                                      state={{ isEditing: true, data: row }}
                                    >
                                      <button
                                        class="dropdown-item d-flex justify-content-center align-items-center projects_dropdownlinks"
                                        type="button"
                                      >
                                        <EditIcon
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                          }}
                                        />
                                        <span className="ms-2 ">Edit</span>
                                      </button>
                                    </Link>
                                  </li>
                                )}
                              {(userActions[currentUserRole]
                                ? userActions[currentUserRole]?.delete
                                : true) && (
                                <li
                                  style={{
                                    borderBottom: "1px solid #CBCBCB",
                                  }}
                                  className="pt-2 pb-2"
                                  onClick={handleLinkClick}
                                >
                                  <button
                                    class="dropdown-item d-flex projects_dropdownlinks"
                                    type="button"
                                    onClick={() => {
                                      setSelectedProject(row);
                                      setIsDeleteProject(true);
                                      handleDeleteShow();
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                      }}
                                    />
                                    <span className="ms-2 d-flex">Delete</span>
                                  </button>
                                </li>
                              )}
                              {(userActions[currentUserRole]
                                ? userActions[currentUserRole]?.view
                                : true) && (
                                <li
                                  style={{
                                    borderBottom: "1px solid #CBCBCB",
                                  }}
                                  className="pt-2 pb-2"
                                >
                                  <Link
                                    to="/salesaddprojects"
                                    state={{ isEditing: null, data: row }}
                                  >
                                    <button
                                      class="dropdown-item d-flex projects_dropdownlinks"
                                      type="button"
                                    >
                                      <ViewIcon
                                        style={{
                                          height: "18px",
                                          width: "18px",
                                        }}
                                      />
                                      <span className="ms-2 d-flex">View</span>
                                    </button>
                                  </Link>
                                </li>
                              )}
                              {(userActions[currentUserRole]
                                ? userActions[currentUserRole]?.signoff
                                : true) && (
                                <li
                                  style={{
                                    borderBottom: "1px solid #CBCBCB",
                                  }}
                                  className="pt-2 pb-2"
                                >
                                  <Link
                                    to="/salessignoffdoc"
                                    state={{
                                      projectId: row.id,
                                      customerFname: row.customer?.first_name,
                                      customerLname: row.customer?.last_name,
                                      isProjectClosed:
                                        row?.status?.toLowerCase() ===
                                          "project on hold" ||
                                        row.status?.toLowerCase() ===
                                          "project closed",
                                    }}
                                  >
                                    <button
                                      class="dropdown-item d-flex projects_dropdownlinks"
                                      type="button"
                                    >
                                      <SignoffIcon
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />

                                      <span className="ms-2 d-flex">
                                        Signoff
                                      </span>
                                    </button>
                                  </Link>
                                </li>
                              )}
                              {(userActions[currentUserRole]
                                ? userActions[currentUserRole]
                                    ?.preExecutionCheckList
                                : true) && (
                                <li
                                  className="pt-2 pb-2"
                                  style={{
                                    borderBottom: "1px solid #CBCBCB",
                                  }}
                                >
                                  <Link
                                    to="/salespreexecutionchecklist"
                                    state={{
                                      projectId: row.id,
                                      customerFname: row.customer?.first_name,
                                      customerLname: row.customer?.last_name,
                                      isProjectClosed:
                                        row?.status?.toLowerCase() ===
                                          "project on hold" ||
                                        row.status?.toLowerCase() ===
                                          "project closed",
                                    }}
                                  >
                                    <button
                                      class="dropdown-item d-flex projects_dropdownlinks"
                                      type="button"
                                    >
                                      <PreexecutionIcon
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />

                                      <span className="ms-2 d-flex">
                                        Pre Execution Checklist
                                      </span>
                                    </button>
                                  </Link>
                                </li>
                              )}

                              {(userActions[currentUserRole]
                                ? userActions[currentUserRole]
                                    ?.procurementRequest
                                : true) && (
                                <li
                                  style={{
                                    borderBottom: "1px solid #CBCBCB",
                                  }}
                                  className="pt-2 pb-2"
                                >
                                  <Link
                                    to="/civilintialprourement"
                                    state={{
                                      projectId: row.id,
                                      customerFname: row.customer?.first_name,
                                      customerLname: row.customer?.last_name,
                                      isProjectClosed:
                                        row?.status?.toLowerCase() ===
                                          "project on hold" ||
                                        row.status?.toLowerCase() ===
                                          "project closed",
                                    }}
                                  >
                                    <button
                                      class="dropdown-item d-flex projects_dropdownlinks"
                                      type="button"
                                    >
                                      <InitialProcrutmentIcon
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />
                                      <span className="ms-2 d-flex">
                                        Procurement Request
                                      </span>
                                    </button>
                                  </Link>
                                </li>
                              )}

                              {(userActions[currentUserRole]
                                ? userActions[currentUserRole]
                                    ?.subContractorQuotations
                                : true) && (
                                <li
                                  style={{
                                    borderBottom: "1px solid #CBCBCB",
                                  }}
                                  className="pt-2 pb-2"
                                >
                                  <Link
                                    to="/civilactualprocurement"
                                    state={{
                                      projectId: row.id,
                                      customerFname: row.customer?.first_name,
                                      customerLname: row.customer?.last_name,
                                      isProjectClosed:
                                        row?.status?.toLowerCase() ===
                                          "project on hold" ||
                                        row.status?.toLowerCase() ===
                                          "project closed",
                                    }}
                                  >
                                    <button
                                      class="dropdown-item d-flex projects_dropdownlinks"
                                      type="button"
                                    >
                                      <ActualIcon
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />
                                      <span className="ms-2 d-flex">
                                        Sub-contractor Quotations
                                      </span>
                                    </button>
                                  </Link>
                                </li>
                              )}

                              {(userActions[currentUserRole]
                                ? userActions[currentUserRole]?.hold
                                : true) && (
                                <li
                                  style={{
                                    borderBottom: "1px solid #CBCBCB",
                                  }}
                                  className="pt-2 pb-2"
                                  onClick={handleLinkClick}
                                >
                                  <button
                                    class="dropdown-item d-flex projects_dropdownlinks"
                                    type="button"
                                    onClick={() => {
                                      handelHoldProject(row);
                                    }}
                                  >
                                    {row?.status?.toLowerCase() ===
                                    "project on hold" ? (
                                      <>
                                        <ReOpenIcon
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                          }}
                                        />
                                        <span className="ms-2 d-flex">
                                          Reopen Project
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <OnHoldIcon
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                          }}
                                        />
                                        <span className="ms-2 d-flex">
                                          On Hold
                                        </span>
                                      </>
                                    )}
                                  </button>
                                </li>
                              )}

                              {(userActions[currentUserRole]
                                ? userActions[currentUserRole]?.close
                                : true) && (
                                <li
                                  className="pt-2 pb-2"
                                  onClick={handleLinkClick}
                                >
                                  <Link>
                                    <button
                                      class="dropdown-item d-flex projects_dropdownlinks"
                                      type="button"
                                      onClick={() => {
                                        setSelectedProject(row);
                                        setIsDeleteProject(false);
                                        handleDeleteShow();
                                      }}
                                    >
                                      <CloseProjectIcon
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                      />
                                      <span className="ms-2 d-flex">
                                        Close Project
                                      </span>
                                    </button>
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
                {tableData?.count === 0 && (
                  <tr>
                    <td colSpan={9} className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <Custompagenation data={tableData} setData={setTableData} />
          <AddAssigneeModal />
          <DeleteModal />
          <ExportAsModal
            url={`${baseProject}?active=True&pagination=false`}
            showExport={showexport}
            fileName={"Projects"}
            handleCloseExport={handleCloseExport}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Salesprojects;
