import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CustomPagination from "../Customcomponents/Custompagenation";
import close_icon from "../assets/img/close.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
// import Button from "react-bootstrap/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Customsearch from "../Customcomponents/Customsearch";
import { ReactComponent as Add } from "../assets/img/add.svg";
import cancel, { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import {
  getSubcontractor_type,
  postSubcontractor_type,
  postVendor_types,
} from "../api/service";
import { toast } from "react-toastify";
import Custombuttons from "../Customcomponents/Custombuttons";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Vendortypes = () => {
  const [tableData, setTableData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedVendorType, setSelectedVendorType] = useState(null);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const currentUserRole = localStorage.getItem("role");
  const showAddVendorType = currentUserRole === "purchase_head";

  const handleClose = () => {
    setShow(false);
    setIsEditing(false);
    setSelectedVendorType(null);
  };
  const handleShow = () => setShow(true);

  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  //inital api call
  function apiCall() {
    getSubcontractor_type({ supplier_type: "vendor", search: searchValue })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  useEffect(() => {
    apiCall();
  }, [searchValue]);

  // modal for add,edit vendor types
  function AddVendorTypeModal() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [payload, setPayload] = useState({
      id: selectedVendorType?.id,
      name: selectedVendorType?.name ?? "",
      status: selectedVendorType?.status ?? "",
      supplier_type: "vendor",
    });
    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (payload.name === "") {
        toast.error("Type can't be empty");
        return;
      }
      if (!isFormSubmitted) {
        setIsFormSubmitted(true);
        postSubcontractor_type(payload)
          .then((res) => {
            toast.success(`Vendor type is ${isEditing ? "updated" : "added"}`);
            handleClose();
            setIsFormSubmitted(false);
            apiCall();
          })
          .catch((err) => {
            toast.error(err.message);
            setIsFormSubmitted(false);
          });
      }
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">{isEditing ? "Edit" : "Add"} Vendor Type</h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm} autoComplete="off">
            <Row>
              <Form.Group
                className="col-lg-6 col-md-12 mb-2"
                controlId="formGridEmail"
              >
                <Form.Label>Type</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Type"
                  className="inputfield"
                  name="name"
                  value={payload.name}
                  onChange={handleInputChange}
                  required
                  maxLength={64}
                />
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-start-0 align-items-center gap-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleClose}
              ></input>
              <input
                type="submit"
                value="Submit"
                className="signup_button"
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Vendor Types</h4>
          </div>
          {showAddVendorType && (
            <div>
              <Custombuttons
                label={"Add Vendor Type"}
                buttonIcon={Add}
                className="add_employee_btn"
                onClick={handleShow}
              />
            </div>
          )}
        </div>
        <hr />
        <div className="banner-section-dashboard  mb-3">
          <div class="d-flex justify-content-between align-items-center mb-3 gap-2">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">Type</th>

                    <th
                      scope="col"
                      style={{ width: "150px", textAlign: "center" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results.map((row) => (
                    <tr key={row.id}>
                      <td>{row.name}</td>
                      <td>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          <LightTooltip title="Edit" arrow placement="bottom">
                            <EditIcon
                              style={{ color: "#008080" }}
                              className="edit_icon"
                              onClick={() => {
                                setSelectedVendorType(row);
                                setIsEditing(true);
                                handleShow();
                              }}
                            />
                          </LightTooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={2} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <CustomPagination data={tableData} setData={setTableData} />
          <AddVendorTypeModal />
          <Modal
            show={deleteshow}
            onHide={handleDeleteClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="deletebody">
              <div>
                <div className="">
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <div className="media d-flex">
                        <img
                          className="align-self-start mr-3"
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          src={cancel}
                          alt="no_img"
                        />
                        <div
                          className="media-body ms-3"
                          style={{
                            position: "relative",
                          }}
                        >
                          <h5 className="mt-0 alerts-heading text-start">
                            Are you sure?
                          </h5>
                          <p className="text-start">
                            Do you really want to delete these records ? This
                            process cannot be undone
                          </p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-end">
                      <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                        <input
                          type="button"
                          value="Cancel"
                          className="outline_btn"
                          onClick={handleDeleteClose}
                        ></input>
                        <input
                          type="button"
                          value="Delete"
                          className="delete_button"
                          onClick={handleDeleteClose}
                        ></input>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Vendortypes;
