import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Custompagenation from "../Customcomponents/Custompagenation";
import close_icon from "../assets/img/close.svg";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import { ReactComponent as EditIcon } from "../assets/img/edit.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ListGroup from "react-bootstrap/ListGroup";
import { toast } from "react-toastify";
import Custombuttons from "../Customcomponents/Custombuttons";
import Customsearch from "../Customcomponents/Customsearch";
import { getSubcontractor_type, postSubcontractor_type } from "../api/service";
import { ReactComponent as Add } from "../assets/img/add.svg";
import cancel from "../assets/img/delete.svg";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#008080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#008080",
  },
}));

const Subcontractortype = () => {
  const [tableData, setTableData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedSubContractorType, setSelectedSubContractorType] =
    useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setSelectedSubContractorType(null);
    setIsEditing(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  const [searchValue, setSearchValue] = useState("");
  const currentUserId = localStorage.getItem("userid");
  const currentUserRole = localStorage.getItem("role");
  const showAddSubConType = currentUserRole === "civil_head";

  //api call
  function apiCall() {
    getSubcontractor_type({ search: searchValue })
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  useEffect(() => {
    apiCall();
  }, [searchValue]);

  function AddSubContractorTypeModal() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [payload, setPayload] = useState({
      id: selectedSubContractorType?.id,
      name: selectedSubContractorType?.name ?? "",
      active: selectedSubContractorType?.active ?? true,
      created_by: selectedSubContractorType?.currentUserId ?? currentUserId,
      updated_by: currentUserId,
    });
    function handleInputChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      setPayload({ ...payload, [name]: value });
    }

    function handleSubmitForm(event) {
      event.preventDefault();
      if (payload.name === "") {
        toast.error("Type can't be empty");
        return;
      }
      if (!isSubmitted) {
        setIsSubmitted(true);
        postSubcontractor_type(payload)
          .then((res) => {
            toast.success(
              `Sub-contractor type is ${isEditing ? "updated" : "added"}`
            );
            handleClose();
            setIsSubmitted(false);
            apiCall();
          })
          .catch((err) => {
            setIsSubmitted(false);
            toast.error(err.message);
          });
      }
    }
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1 class="headingfour">
            {isEditing ? "Edit" : "Add"} Sub-Contractor Type
          </h1>
          <img
            src={close_icon}
            alt="no_img"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitForm}>
            <Row>
              <Form.Group className="col-lg-6 col-md-12 mb-2">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  placeholder="Enter type"
                  type="text"
                  className="inputfield"
                  name="name"
                  value={payload.name}
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                  maxLength={64}
                />
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-start align-items-center gap-2 pt-2">
              <input
                type="button"
                value="Cancel"
                className="outline_btn"
                onClick={handleClose}
              ></input>
              <input
                type="submit"
                value="Submit"
                className="signup_button"
              ></input>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <div className="banner-section">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="headingfour">Sub-Contractors Type</h4>
          </div>
          {showAddSubConType && (
            <div className="d-flex gap-2">
              <Custombuttons
                label={"Add Sub-Contractor Type"}
                buttonIcon={Add}
                className="add_employee_btn"
                onClick={handleShow}
              />
            </div>
          )}
        </div>
        <hr />

        <div className="banner-section-dashboard  mb-3">
          <div class="d-flex justify-content-start align-items-center mb-3 gap-2">
            <div>
              <Customsearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
          <div className="table_section">
            <div className="table-responsive">
              <table className="table table-bordered align-middle">
                <thead className="table_head_bg">
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col" style={{ width: "150px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.results.map((row) => {
                    return (
                      <tr>
                        <td>{row.name}</td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <LightTooltip title="Edit" arrow placement="bottom">
                              <EditIcon
                                style={{ color: "#008080" }}
                                className="edit_icon"
                                onClick={() => {
                                  setIsEditing(true);
                                  setSelectedSubContractorType(row);
                                  setShow(true);
                                }}
                              />
                            </LightTooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {tableData?.count === 0 && (
                    <tr>
                      <td colSpan={2} className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Custompagenation data={tableData} setData={setTableData} />
          <AddSubContractorTypeModal />

          <Modal
            show={deleteshow}
            onHide={handleDeleteClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="deletebody">
              <div>
                <div className="">
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <div className="media d-flex">
                        <img
                          className="align-self-start mr-3"
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                          src={cancel}
                          alt="no_img"
                        />
                        <div
                          className="media-body ms-3"
                          style={{
                            position: "relative",
                          }}
                        >
                          <h5 className="mt-0 alerts-heading text-start">
                            Are you sure?
                          </h5>
                          <p className="text-start">
                            Do you really want to delete these records ? This
                            process cannot be undone
                          </p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-end">
                      <div className="d-flex justify-content-end pt-1 pb-1 gap-2">
                        <input
                          type="button"
                          value="Cancel"
                          className="outline_btn"
                          onClick={handleDeleteClose}
                        ></input>
                        <input
                          type="button"
                          value="Delete"
                          className="delete_button"
                          onClick={() => {
                            let payload = {
                              id: selectedSubContractorType.id,
                              name: selectedSubContractorType.name,
                              active: false,
                              created_by: selectedSubContractorType.created_by,
                              updated_by: currentUserId,
                            };
                            postSubcontractor_type(payload)
                              .then((res) => {
                                handleDeleteClose();
                                apiCall();
                              })
                              .catch((err) => {
                                toast.error(err.message);
                              });
                          }}
                        ></input>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Subcontractortype;
