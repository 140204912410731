//heads and teams members
const headAndTeamMembers = {
  sales_head: "sales_team",
  civil_head: "civil_team",
  design_head: "design_team",
  purchase_head: "purchase_team",
  accounts: "accounts",
  admin: "admin",
};

//only head and team members
const headsAndTeamMemebersOnly = {
  sales_head: "sales_team",
  civil_head: "civil_team",
  design_head: "design_team",
  purchase_head: "purchase_team",
};

//payment methods
const paymentTypes = ["income", "expense"];

//cashbook types
const cashbookTypes = [
  "income",
  "expense",
  "petty cash expense",
  "petty cash credit",
];
const paymentMethods = ["cheque", "cash", "upi", "other"];
const paymentCategories = [
  "vendor payment",
  "token received",
  "project payment",
];

//task status
const taskStatuses = [
  "New",
  "On-Track",
  "Extended - No Impact",
  "Extended - Impact",
  "Closed",
];

//signoff status
const signOffStatuses = ["accepted", "rejected"];

function getIds(list) {
  return list.map((item) => item.id);
}
function conDateToReadable(
  str,
  enableTime = true,
  splitStr = "/",
  dmy = false
) {
  let formattedDateTime = "";
  try {
    if (!str) {
      return "--";
    }
    let date = new Date(str);
    const month = date.toLocaleString("en-US", { month: "2-digit" });
    const day = date.getDate();
    const year = date.getFullYear();
    const time = date.toLocaleTimeString("en-US", { hour12: true });
    if (dmy) {
      formattedDateTime = enableTime
        ? `${day}${splitStr}${month}${splitStr}${year}, ${time}`
        : `${day}${splitStr}${month}${splitStr}${year}`;
    } else {
      formattedDateTime = enableTime
        ? `${year}${splitStr}${month}${splitStr}${day}, ${time}`
        : `${year}${splitStr}${month}${splitStr}${day}`;
    }
    return formattedDateTime;
  } catch (err) {
    return "--";
  }
}
function isPasswordValid(str) {
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#'()+,\-./:;<=>[\]^_`{}|~])[A-Za-z\d@$!%*?&#'()+,\-./:;<=>[\]^_`{}|~ ]{8,32}$/.test(
    str
  );
}
function isEmailValid(str) {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(str);
}
function isMobileNumValid(str) {
  return /^\d{10}$/.test(str);
}
export {
  headAndTeamMembers,
  headsAndTeamMemebersOnly,
  paymentTypes,
  paymentMethods,
  cashbookTypes,
  paymentCategories,
  taskStatuses,
  signOffStatuses,
  getIds,
  conDateToReadable,
  isPasswordValid,
  isEmailValid,
  isMobileNumValid,
};
