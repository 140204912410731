import React from "react";
import { Link } from "react-router-dom";
import warning_icon from "../assets/img/warning.svg"

export default function TokenExpired() {
  return (
    <React.Fragment>
       <div className="login_section">
      <div className="error-container">
       
          <img src={warning_icon} alt="no_img"  className="invalidtoken_img"/>
        
        <div className="error-divider"></div>
        <p className="error-message" style={{ margin: "0px",fontSize:'24px' }}>
        Token expired or invalid token
        </p>
      </div>
     
    </div>

    </React.Fragment>
  );
}
