import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { postChange_password, updateProfile } from "../api/service";
import { ReactComponent as Changepassword } from "../assets/img/changepassword.svg";
import close_icon from "../assets/img/close.svg";
import default_dp from "../assets/img/default_dp.png";
import { ReactComponent as Editprofile } from "../assets/img/edit.svg";
import { ReactComponent as Logouticon } from "../assets/img/logout.svg";
import { ReactComponent as NotificationIcon } from "../assets/img/notification.svg";
import sidebarright from "../assets/img/sidebarright.svg";
import logo from "../assets/img/logo.svg";
import { postLogout } from "../api/service";
import ChangePasswordModal from "./ChangePasswordModal";

const CustomerHeader = ({ scrolling }) => {
  const [isToggled, setToggled] = useState(false);
  const searchIconColor = scrolling ? "#FFFFFF" : "#FFFFFF";
  const backgroundColor = scrolling ? "#3c4c57" : "#3c4c57";
  const profilePic = localStorage.getItem("profile_pic");
  const fileInputRef = useRef(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditProfileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e?.target?.files?.length) {
      if (!e?.target?.files[0]?.type?.startsWith("image")) {
        return;
      } else {
        let image = {
          preview: URL.createObjectURL(e?.target?.files[0]),
          raw: e?.target?.files[0],
        };
        let role = localStorage.getItem("role");
        let profileId = localStorage.getItem("profileid");
        let userid = localStorage.getItem("userid");
        const formData = new FormData();
        formData.append("id", profileId);
        formData.append("role", role);
        formData.append("user", userid);
        formData.append("active", true);
        if (image.raw) {
          formData.append("profile_pic", image.raw);
        }
        updateProfile(formData)
          .then((res) => {
            let profileUrl = res.profile_pic.split("media")[1];
            let updatedProfileUrl = `/media${profileUrl}`;
            localStorage.setItem("profile_pic", updatedProfileUrl);
            setCompleteProfilePicUrl(res.profile_pic);
            toast.success("Profile pic is updated");
          })
          .catch(() => {
            toast.error("Profile is not updated");
          });
      }
    }
  };
  const [completeProfilePicUrl, setCompleteProfilePicUrl] = useState(
    profilePic !== "null" && profilePic !== "" ? profilePic : default_dp
  );
  // removing the token from backend and clearing the stored user info from localstorage
  function handleUserLogout() {
    postLogout()
      .then((res) => {
        toast.success(res.detail);
        localStorage.clear();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  return (
    <nav
      className={`navbar navbar-expand-md sticky-top justify-content-between ${
        scrolling ? "scrolled" : ""
      }`}
      style={{
        background: backgroundColor,
      }}
    >
      <div class="navbar-brand d-flex align-items-center">
        <img
          src={logo}
          alt="no_img"
          className="ms-3 me-3"
          width="35"
          height="35"
        />
        <h6 className="sidebar_heading">Avant</h6>
      </div>
      <div className="form-inline d-flex gap-3 me-3">
        <div className="btn-group">
          <div
            className="profile-dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ color: "white" }}
          >
            <NotificationIcon
              style={{ cursor: "pointer" }}
              width="24"
              height="24"
              className="mt-2"
            />
          </div>
          <div className="dropdown-content dropdown-menu dropdown-menu-end custom_header">
            <div className="list-unstyled text-center">
              <h6>No Notifications</h6>
            </div>
          </div>
        </div>
        <div className="btn-group">
          <div
            className="profile-dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={completeProfilePicUrl}
              style={{ cursor: "pointer" }}
              alt="Avatar"
              className="avatar"
            ></img>
          </div>
          <div className="dropdown-content dropdown-menu dropdown-menu-end custom_header">
            <div className="list-unstyled text-center list-items">
              <div className="media d-flex">
                <div onClick={handleEditProfileClick}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <Editprofile
                    className="edit_icon_profile"
                    title="edit profile photo"
                    style={{ cursor: "pointer" }}
                  />
                </div>

                <img
                  className="mr-3"
                  src={completeProfilePicUrl}
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                  }}
                  alt="no_img"
                />
                <div
                  className="media-body ms-3 userprofile-dropdown"
                  style={{ textAlign: "left" }}
                >
                  <h5 className="mt-1 mb-1 profilename_heading">
                    {localStorage.getItem("username")}
                  </h5>
                  <p
                    className="profile-description"
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    Role:
                    <b>{localStorage.getItem("role")}</b>
                  </p>
                </div>
              </div>
              <hr className="profile_hr_tag" />
              <Link
                onClick={handleShow}
                className="label  d-flex justify-content-start-0 align-items-center"
              >
                <Changepassword className="me-2" />
                <span className="mt-0">Change Password</span>
              </Link>
              <div className="logout_link">
                <Link
                  to="/login"
                  className="label d-flex justify-content-start-0 align-items-center"
                  style={{ marginBottom: "-12px" }}
                  onClick={handleUserLogout}
                >
                  <Logouticon className="me-2" />
                  <span className="logout-text">Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordModal show={show} handleClose={handleClose} />
    </nav>
  );
};

export default CustomerHeader;
