import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { BrowserRouter as Router, useNavigate, Link } from "react-router-dom";
import { ReactComponent as Backicon } from "../assets/img/backbutton.svg";
import { ToastContainer, toast } from "react-toastify";
import { forgetPassword } from "../api/service";

const Forgotpassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleClick = () => {
    if (email === "") {
      toast.error("Please enter email");
      return;
    }
    let host = `${window.location.protocol}//${window.location.host}`;
    const payload = {
      email: email,
      host: host,
    };
    forgetPassword(payload)
      .then((res) => {
        toast.success(res.detail);
        navigate("/login");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <React.Fragment>
      <div class="login_section">
        <div class="content">
          <div id="form-main-container">
            <div id="form-area">
              <h4 class="headingfour mb-3 text-center text-white">
                Forgot Password
              </h4>

              <div id="form-body">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <span className="text-white login_label_text">Email</span>
                    <Form.Control
                      type="email"
                      className="login_formcontrol mt-1"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <div className="d-flex justify-content-center mt-5 mb-3">
                    <button
                      type="button"
                      className="signup_button"
                      onClick={handleClick}
                    >
                      Reset Password
                    </button>

                    {/*<Primarybutton name="Login" onClick={handleLogin} /> */}
                  </div>
                  <Form.Group
                    className="mb-4 text-center text-white"
                    controlId="formBasicCheckbox"
                  >
                    <Link
                      to="/login"
                      className="label_text forgot_password_text"
                    >
                      <Backicon className="me-2" />
                      Back to Login
                    </Link>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Forgotpassword;
